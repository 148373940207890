import React from 'react';

const AboutUsCard = ({ image, title, paragraph01, paragraph02, imageOrder, textOrder, className }) => {
  return (
    <div className="about-us-card">
      <div className='container'>
        <div className="flex flex-col lg:flex-row justify-center items-center gap-16">
          {/* image */}
          <div className={`flex-1 ${imageOrder}`}>
            <img src={image} alt="" />
          </div>

          {/* text */}
          <div className={`flex-1 md:mt-0 mt-11 ${textOrder}`}>
            <h3 className="label-xl md:mb-12 mb-8">{title}</h3>
            <p className={`body-md text-S07 ${paragraph02 && "md:mb-12 mb-8"}`}>{paragraph01}</p>
            {paragraph02 &&
              <p className="body-md text-S07">{paragraph02}</p>
            }
          </div>
        </div>
      </div>
    </div>
  )
};


export default AboutUsCard;
import React from 'react';

const PointerCards = ({ step, image, title, content, className }) => {
  return (
    <div className={`relative flex flex-col items-center ${className} pt-12 pb-7 px-4 bg-[#FAF3FF] rounded-2xl`}>
      {/* index */}
      <div className='absolute top-[-30px] w-[60px] h-[60px] bg-[#FAF3FF] rounded-full p-[10px]'>
        <div className='flex items-center justify-center label-xs text-M01 w-10 h-10 rounded-full border border-M01'>
          {step}
        </div>
      </div>
      <div className='flex items-center justify-center h-[220px]'>
        <img src={image} alt="" />
      </div>
      <div className='text-center'>
        <h3 className='label-xs text-T01 mb-3'>{title}</h3>
        <p className='body-xs font-normal text-T06'>{content}</p>
      </div>
    </div>
  )
}

export default PointerCards;
export const options = [
  {
    "id": 639,
    "name": "Nha Trang",
    "display_name": "Nha Trang (VNNHA), Vietnam, Asia",
    "latitude": "12.20371",
    "longitude": "109.1971",
    "country_code": "VN",
    "postal_code": null,
    "port_code": "VNNHA",
    "is_icd": false,
    "country_id": 129,
    "created_at": "2023-08-29T05:05:10.000000Z",
    "updated_at": "2023-08-29T05:05:10.000000Z"
  },
  {
    "id": 8099,
    "name": "Jawaharlal Nehru (Nhava Sheva) Jawaharlal Nehru (Nhava Sheva)",
    "display_name": "Jawaharlal Nehru (Nhava Sheva) (INNSA), Mumbai, India",
    "latitude": "18.952",
    "longitude": "72.948",
    "country_code": "IN",
    "postal_code": "400707",
    "port_code": "INNSA",
    "is_icd": false,
    "country_id": 231,
    "created_at": "2023-08-29T05:07:54.000000Z",
    "updated_at": "2023-08-29T05:07:54.000000Z"
  },
  {
    "id": 9264,
    "name": "Khanh Hoa Seaport",
    "display_name": "Khanh Hoa Seaport, Nha Trang, Vietnam",
    "latitude": "12.240486",
    "longitude": "109.195724",
    "country_code": "VN",
    "postal_code": "570000",
    "port_code": "VNCXR",
    "is_icd": false,
    "country_id": 129,
    "created_at": "2023-08-29T05:08:26.000000Z",
    "updated_at": "2023-08-29T05:08:26.000000Z"
  },
];

export const PackageTypeoptions = [
  {
    id: 1,
    name: "Boxes",
    is_active: 1,
  },
  {
    id: 2,
    name: "Bottles",
    is_active: 0,
  },
  {
    id: 3,
    name: "Bags",
    is_active: 0,
  },
  {
    id: 4,
    name: "Pouches",
    is_active: 0,
  },
  {
    id: 5,
    name: "Cans",
    is_active: 0,
  },
  {
    id: 6,
    name: "Jars",
    is_active: 0,
  },
  {
    id: 7,
    name: "Cartons",
    is_active: 0,
  },
  {
    id: 8,
    name: "Tubes",
    is_active: 0,
  },
  {
    id: 9,
    name: "Clamshells",
    is_active: 0,
  },
  {
    id: 10,
    name: "Wrappers",
    is_active: 0,
  },
  {
    id: 11,
    name: "Pallets",
    is_active: 1,
  },
  {
    id: 12,
    name: "Blister Packs",
    is_active: 0,
  },
  {
    id: 13,
    name: "Shrink Wrap",
    is_active: 0,
  },
  {
    id: 14,
    name: "Tins",
    is_active: 0,
  }
];

export const dimensionsUnitOptions = [{
  id: 1,
  name: "IN",
},
{
  id: 2,
  name: "CM",
}
];
export const weightUnitOptions = [{
  id: 1,
  name: "KG",
},
{
  id: 2,
  name: "LBS",
}
];

export const ContainerTypeoptions = [
  {
    id: 1,
    name: "20GP",
    is_active: 1,
  },
  {
    id: 2,
    name: "20HP",
    is_active: 1,
  },
  {
    id: 3,
    name: "40GP",
    is_active: 1,
  },
  {
    id: 4,
    name: "40HP",
    is_active: 1,
  },
]

export const CountryCodeoptions = [
  {
    id: 1,
    name: "+91",
    is_active: 1,
  },
  {
    id: 2,
    name: "+90",
    is_active: 1,
  },
  {
    id: 3,
    name: "+022",
    is_active: 1,
  },
  {
    id: 4,
    name: "+007",
    is_active: 1,
  },
]

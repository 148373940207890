import React, { useEffect, useState } from 'react'
import InputAutoComplete from '../../../../components/reusable-components/inputfields/InputAutoComplete'
import useGetPort from '../../../../network/hooks/useGetPort';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchQueryData, setSearchQueryData } from '../../../../redux/slices/searchQueryDataSlice';

const Origin = () => {
  const { request: getPortsList } = useGetPort();
  const dispatch = useDispatch();
  const [listOfPorts, setListOfPorts] = useState();
  const [trigger, setTrigger] = useState("");
  const searchQueryData = useSelector(getSearchQueryData);

  const handleOnChangeOrigin = (e) => {
    dispatch(setSearchQueryData({
      ...searchQueryData,
      origin_search: e.target.value,
    }))
    setTrigger("");
  }

  const handleGetPortsList = async () => {
    const searchQuery = {
      location: searchQueryData?.origin_search,
      portType: searchQueryData && searchQueryData?.type === "ocean" ? "seaport" : "airport",
    }
    const { data, error } = await getPortsList(searchQuery);

    if (data && data.success) {
      setListOfPorts(data?.data);
    }
    if (error) {
      console.log(error);
    }
  };

  const handleSelectedOrigin = (option) => {
    dispatch(setSearchQueryData({
      ...searchQueryData,
      origin: option,
      origin_search: option?.city_name ? `${option?.un_lo_code ? option?.un_lo_code : option?.iata_code}, ${option?.city_name}` : `${option?.un_lo_code ? option?.un_lo_code : option?.iata_code}`,
    }))
    setTrigger("FROMPORTLISTCLICK");
  };

  // const handleAutoComplete = () => {
  //   if (searchQueryData?.origin?.id !== null) {
  //     dispatch(setSearchQueryData({
  //       ...searchQueryData,
  //       origin_search: searchQueryData?.origin?.city_name ? `${searchQueryData?.origin?.un_lo_code}, ${searchQueryData?.origin?.city_name}` : `${searchQueryData?.origin?.un_lo_code}`,
  //     }))
  //     setTrigger("FROMPORTLISTCLICK");
  //   }
  // };

  useEffect(() => {
    if (trigger !== "FROMPORTLISTCLICK") {
      if (searchQueryData?.origin_search?.length >= 3) {
        handleGetPortsList();
      }
    }
    if (searchQueryData?.origin_search?.length < 1) {
      setListOfPorts(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQueryData?.origin_search, trigger])

  return (
    <div>
      <span className="body-xs text-T05 ">Origin</span>
      <InputAutoComplete
        options={listOfPorts}
        selected={searchQueryData?.origin}
        onClick={handleSelectedOrigin}
        onChange={handleOnChangeOrigin}
        value={searchQueryData?.origin_search}
        placeholder="Select Origin"
      // handleAutoComplete={handleAutoComplete}
      />
    </div>
  )
}

export default Origin
import React from 'react';
import Marquee from 'react-fast-marquee';
import KeyFeatureCard from './partials/KeyFeatureCard';
import InvoiceDisIcon from "../../../assets/images/invoice discounting icon.svg";
import SeaFFIcon from "../../../assets/images/sea FF icon.svg";
import AirFFIcon from "../../../assets/images/air FF icon.svg";

const featuresData = [
  {
    title: "Invoice Discounting",
    content: "Unlock the full potential of your business cashflows with CargoXB's invoice discounting solution.",
    image: InvoiceDisIcon,
  },
  {
    title: "Sea Freight Forwarding",
    content: "Navigate global waters effortlessly with CargoXB's efficient Sea Freight Forwarding solutions.",
    image: SeaFFIcon,
  },
  {
    title: "Air Freight Forwarding",
    content: "Soar above logistics challenges with CargoXB's swift and reliable Air Freight Forwarding services.",
    image: AirFFIcon,
  },
  {
    title: "Invoice Discounting",
    content: "Unlock the full potential of your business cashflows with CargoXB's invoice discounting solution.",
    image: InvoiceDisIcon,
  },
  {
    title: "Sea Freight Forwarding",
    content: "Navigate global waters effortlessly with CargoXB's efficient Sea Freight Forwarding solutions.",
    image: SeaFFIcon,
  },
  {
    title: "Air Freight Forwarding",
    content: "Soar above logistics challenges with CargoXB's swift and reliable Air Freight Forwarding services.",
    image: AirFFIcon,
  },
  {
    title: "Invoice Discounting",
    content: "Unlock the full potential of your business cashflows with CargoXB's invoice discounting solution.",
    image: InvoiceDisIcon,
  },
  {
    title: "Sea Freight Forwarding",
    content: "Navigate global waters effortlessly with CargoXB's efficient Sea Freight Forwarding solutions.",
    image: SeaFFIcon,
  },
  {
    title: "Air Freight Forwarding",
    content: "Soar above logistics challenges with CargoXB's swift and reliable Air Freight Forwarding services.",
    image: AirFFIcon,
  },
];

const KeyFeatures = () => {
  return (
    <div id='key-features' className="key-features-wrapper pb-14 md:pb-20 xl:pb-32">
      <div className="container">
        <span className='body-xxs text-T01 pb-3.5 block'>KEY FEATURES</span>
        <div className='md:flex block items-center gap-10'>
          <div className='md:w-2/3 w-full'>
            <h2 className='heading-h1 text-T01'>What makes CargoXB stand out?</h2>
          </div>
          <p className='body-md text-T03 md:w-1/3 md:pt-0 pt-4 sm:pt-6 w-full'>We deliver streamlined logistics through cutting-edge technology and a user friendly digital interface, ensuring enhanced transparency and automated processes.</p>
        </div>
      </div>

      {/* feature card silder */}
      <div className="mt-9 md:mt-24">
        <Marquee
          pauseOnHover
        >
          {
            featuresData?.map((item) => {
              return (
                <KeyFeatureCard
                  key={Math.random()}
                  image={item?.image}
                  title={item?.title}
                  content={item?.content}
                />
              )
            })
          }
        </Marquee>
      </div>
    </div>
  )
}

export default KeyFeatures;
import React, { useState } from 'react'
import useCreateContactMessage from '../network/hooks/useCreateContactMessage.js';
import InputBasic from '../components/reusable-components/inputfields/InputBasic.jsx';
import ButtonPrimary from '../components/reusable-components/buttons/ButtonPrimary.jsx';


const PartnerWithUs = () => {
  const { request: submitContactMessage, isLoading: submitContactMessageLoading } = useCreateContactMessage();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email_address: "",
    company_name: "",
    website: "",
    reason: {
      id: 1,
      name: "General Inquiry",
      is_active: 1,
    },
    message: "",
  });
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [companyError, setCompanyError] = useState(false);
  const [submitSuccess, setsubmitSuccess] = useState("")
  const [submitError, setSubmitError] = useState("")

  const handleSubmit = async () => {
    let isError = false
    if (formData?.first_name === "") {
      setFirstNameError(true);
      isError = true;
    }
    if (formData?.last_name === "") {
      setLastNameError(true);
      isError = true;
    }
    if (formData?.phone_number === "") {
      setPhoneError(true);
      isError = true;
    }
    if (formData?.email_address === "") {
      setEmailError(true);
      isError = true;
    }
    if (formData?.message === "") {
      setMessageError(true);
      isError = true;
    }
    if (formData?.company_name === "") {
      setCompanyError(true);
      isError = true;
    }

    if (isError) {
      setSubmitError("Please fill all required details")
      return;
    }

    const { data, error } = await submitContactMessage({
      first_name: formData?.first_name,
      last_name: formData?.last_name,
      phone: formData?.phone_number,
      email: formData?.email_address,
      reason: "partnership",
      company_name: formData?.company_name,
      website: formData?.website !== "" ? formData?.website : "null",
      message: formData?.message,
    })
    if (data && data.success) {
      setsubmitSuccess(data?.message);
      setSubmitError("");
    }
    if (error) {
      console.log(error)
      setSubmitError(error?.response?.data?.message);
      setsubmitSuccess("");
    }
  };

  return (
    <div className=''>
      {/* Banner */}
      <div className='bg-M02 py-10'>
        <div className="container flex flex-col justify-center items-center">
          <h2 className='heading-h3 mb-7 text-center'>Empower Logistics: Partner with Us</h2>
          <p className='body-sm text-center max-w-3xl w-full mx-auto'>Quick Form, Endless Possibilities.</p>
        </div>
      </div>

      <div className="container">
        <div
          className='grid sm:grid-cols-2 grid-cols-1 gap-x-9 gap-y-6 max-w-[500px] w-full mx-auto my-10'
        >
          {/* first name */}
          <div>
            <label htmlFor='first_name' className='label-xxs block mb-2'>First Name</label>
            <InputBasic
              id='first_name'
              name='first_name'
              required={true}
              value={formData?.first_name}
              onChange={(e) => {
                e.preventDefault();
                setFormData({
                  ...formData,
                  first_name: e.target.value,
                })
                setFirstNameError(false);
              }}
              error={firstNameError}
            />
          </div>

          {/* last name */}
          <div>
            <label htmlFor='last_name' className='label-xxs block mb-2'>Last Name</label>
            <InputBasic
              id="last_name"
              name="last_name"
              required={true}
              value={formData?.last_name}
              onChange={(e) => {
                e.preventDefault();
                setFormData({
                  ...formData,
                  last_name: e.target.value,
                })
                setLastNameError(false);
              }}
              error={lastNameError}
            />
          </div>

          {/* phone number */}
          <div>
            <label htmlFor='phone_number' className='label-xxs block mb-2'>Phone Number</label>
            <InputBasic
              id="phone_number"
              name="phone_number"
              required={true}
              maxLength={10}
              value={formData?.phone_number}
              onChange={(e) => {
                // e.preventDefault();
                const reg = new RegExp("^[0-9]+$");
                if (!e.target.value || reg.test(e.target.value)) {
                  setFormData({
                    ...formData,
                    phone_number: e.target.value,
                  })
                }
                setPhoneError(false);
              }}
              error={phoneError}
            />
          </div>

          {/* email address */}
          <div>
            <label htmlFor='email_address' className='label-xxs block mb-2'>Email Address</label>
            <InputBasic
              id="email_address"
              name="email_address"
              required={true}
              value={formData?.email_address}
              onChange={(e) => {
                e.preventDefault();
                setFormData({
                  ...formData,
                  email_address: e.target.value,
                })
                setEmailError(false);
              }}
              error={emailError}
            />
          </div>

          {/* Company Name */}
          <div>
            <label htmlFor='company_name' className='label-xxs block mb-2'>Company Name</label>
            <InputBasic
              id="company_name"
              name="company_name"
              required={true}
              value={formData?.company_name}
              onChange={(e) => {
                e.preventDefault();
                setFormData({
                  ...formData,
                  company_name: e.target.value,
                })
                setCompanyError(false);
              }}
              error={companyError}
            />
          </div>

          {/* Website  */}
          <div>
            <label htmlFor='website' className='label-xxs block mb-2'>Website (Optional)</label>
            <InputBasic
              id="website"
              name="website"
              required={true}
              value={formData?.website}
              onChange={(e) => {
                e.preventDefault();
                setFormData({
                  ...formData,
                  website: e.target.value,
                })
              }}
            />
          </div>

          {/* message box */}
          <div className='sm:col-span-2'>
            <label htmlFor='message' className='label-xxs block mb-2'>Message</label>
            <textarea
              id='message'
              name='message'
              className={`border ${messageError ? "border-red-500" : "border-S01 focus:border-M01"}  transition-all duration-500 outline-none rounded-3xl resize-none w-full h-48 px-5 py-4`}
              placeholder='Type out your message here'
              value={formData?.message}
              onChange={(e) => {
                e.preventDefault();
                setFormData({
                  ...formData,
                  message: e.target.value,
                })
                setMessageError(false);
              }}
            />
          </div>

          {/* dummy div */}
          <div>
            {submitSuccess && <div className='body-xs text-green-400 font-medium'>{submitSuccess}</div>}
            {submitError && <div className='body-xs text-red-500 font-medium'>{submitError}</div>}
          </div>

          {/* submit button */}
          <ButtonPrimary
            text="Submit"
            // type="submit"
            additionalClasses="ml-auto"
            loading={submitContactMessageLoading}
            onClick={() => handleSubmit()}
          />
        </div>
      </div>
    </div>
  )
}

export default PartnerWithUs
import React, { useState, useRef, useEffect } from 'react';
import Transition from '../../../utils/Transition';

function InputAutoComplete({ options, selected, value, onClick, onChange, placeholder, handleAutoComplete }) {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
      handleAutoComplete && handleAutoComplete();
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
      handleAutoComplete && handleAutoComplete();
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative">
      <input
        ref={trigger}
        className="px-5 py-3 w-full rounded-3xl bg-M04 border border-S01 focus:border-M01 transition-all duration-500 outline-none label-xxs text-black"
        onFocus={() => setDropdownOpen(true)}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e)}
      />
      {/* {options?.length > 0 && */}
      <Transition
        show={dropdownOpen}
        tag="div"
        className={`z-10 absolute top-full left-0 w-full sm:w-[205%] bg-white rounded-3xl px-1.5 ${options?.length > 0 && "border border-S01 shadow-lg"} overflow-x-hidden no-scrollbar overflow-y-scroll mt-1 max-h-[11.375rem]`}
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => setDropdownOpen(false)}
        >
          {
            options?.map(option => {
              return (
                <button
                  key={option?.id}
                  tabIndex="0"
                  className={`w-full text-left border-b border-b-S01 last:border-b-0 hover:bg-slate-50 py-2 px-3 cursor-pointer ${option?.id === selected?.id && 'text-indigo-500'}`}
                  onClick={() => {
                    onClick(option);
                    setDropdownOpen(false);
                  }}
                >
                  <p className='label-xxs ellipsis'>{option?.site_name} {`(${option?.un_lo_code ? option?.un_lo_code : option?.iata_code})`}</p>
                  <p className={`body-xxs font-normal ellipsis ${option?.id === selected?.id ? 'text-indigo-500' : "text-[#808080]"}`}>{option?.city_name && `${option?.city_name},`} {option?.country_name}</p>
                </button>
              )
            })
          }
        </div>
      </Transition>
      {/* } */}
    </div>
  );
}

export default InputAutoComplete;

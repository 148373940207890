import React, { useEffect, useRef, useState } from 'react'
import BlogCard from '../partials/blogs-page/BlogCard';
import useGetBlogs from '../network/hooks/useGetBlogs';
import Pagination from "../components/reusable-components/pagination/Pagination"

const BlogsPage = () => {
  const { request: getBlogs, isLoading: getBlogsLoading } = useGetBlogs();
  const [blogsData, setblogsData] = useState();
  const latestBlogSectionRef = useRef();
  // console.log("blogsData", blogsData);

  // for pagination
  const handleGoToPageNumber = (page_number) => {
    handleGetBlogs(page_number);
    latestBlogSectionRef?.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleGetBlogs = async (page_number) => {
    const { data, error } = await getBlogs(page_number);
    if (data && data.success) {
      setblogsData(data?.data);
    }
    if (error) {
      window.alert(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    handleGetBlogs(1);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, [100]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="blogs-page-wrapper">
      {/* Banner */}
      <div className="md:pt-80 pt-52  md:pb-32 pb-20 bg-blogs-banner bg-center bg-cover bg-no-repeat">
        <div className="container">
          <h1 className="heading-h4 text-M04 max-w-[47rem]">Exploring Functions of Supply Chain Management and the Role of Inventory</h1>
        </div>
      </div>

      {/* Latest Blogs */}
      <div ref={latestBlogSectionRef} className="container mt-10 mb-20">
        <h2 className="heading-h2 text-T01 mb-8">Lastest Blog</h2>

        {/* Blogs Cards */}
        {getBlogsLoading ?
          // loading animation
          <>
            <div className="flex items-center justify-center">
              <div className="relative">
                <div className="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
                <div className="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-M01 animate-spin">
                </div>
              </div>
            </div>
          </>
          :
          <>
            <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-9 gap-y-12">
              {blogsData?.data?.map((item) => {
                return (
                  <BlogCard
                    blogsContentData={item}
                    key={Math.random()}
                    image={item?.featured_image}
                    published_at={item?.published_at}
                    title={item?.title}
                    summary={item?.summary}
                    author_name={item?.author_name ? item?.author_name : "CargoXB"}
                  />
                )
              })}
            </div>
            <div className='mt-16'>
              <Pagination
                currentPage={blogsData?.current_page}
                lastPage={blogsData?.last_page}
                totalRecords={blogsData?.total}
                handleGoTo={handleGoToPageNumber}
              />
            </div>
          </>
        }
      </div>
    </div >
  )
}

export default BlogsPage;
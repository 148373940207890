import React from 'react';
// import AboutUsBannerDesktop from "../../assets/images/about-us-banner-desktop.png"
// import AboutUsBannerMobile from "../../assets/images/about-us-banner-mobile.png"


const AboutUsBanner = () => {
  return (
    <div className='bg-M02 md:bg-transparent'>
      <div className="bg-M02 heading-h3 md:py-12 py-5 text-center">About us</div>
      {/* <div className='2xl:container'>
        <div className="md:mt-14 mt-10 pb-8 md:pb-14 flex flex-col lg:flex-row justify-between 2xl:justify-center items-center gap-7 md:gap-12 lg:gap-14 px-5 lg:px-0">
          <div className="flex-1 lg:max-w-[594px] max-w-[768px] lg:pl-20 2xl:pl-0 order-2 lg:order-1">
            <h3 className="label-xl mb-6 md:mb-8 xl:mb-12">Unlock Global Trade Success:<br />Shatter Shipping Barriers With CargoXB</h3>
            <p className="body-md text-S07">With India's export industry growing rapidly, reaching record highs, exporters have excellent growth opportunities. After 200 in-person and telecon consultations with different types of exporters, we identified the core problem areas. As an Indian exporter trying to compete in the digital world, are you facing challenges. </p>
          </div>
          <div className="flex-1 flex items-center justify-end order-1 lg:order-2">
            <img className='hidden lg:block' src={AboutUsBannerDesktop} alt="" />
            <img className='block lg:hidden' src={AboutUsBannerMobile} alt="" />
          </div>
        </div>
      </div> */}
    </div>
  )
}

export default AboutUsBanner;
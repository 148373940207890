import React from 'react';

const Checkbox = ({ name, label, value, checked, onChange }) => {
  return (
    <div onClick={() => onChange()} className="flex items-center cursor-pointer">
      <input
        id={`${name}`}
        type="checkbox"
        value={`${value}`}
        name={`${name}`}
        checked={checked}
        onChange={() => onChange()}
        className="w-4 h-4 checked:accent-M01 rounded-5xl cursor-pointer"
      />
      <label htmlFor="default-radio-1" className="ml-1 body-xs text-T07 cursor-pointer">{label}</label>
    </div>
  )
}

export default Checkbox;
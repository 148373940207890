import React from 'react'

const ReviewCard = ({ reviewContent, profileImg, name, designation }) => {
  return (
    <div className='border-2 border-[#A1A1A1] bg-M04 rounded-3xl lg:mx-4'>
      <div className='py-5 sm:py-8 px-5 sm:px-9'>
        <p className='body-md lg:min-h-[66px]'>{reviewContent}</p>
        <div className='mt-5 sm:mt-12 bg-M02 flex items-center rounded-2xl'>
          <div className='px-3 sm:px-5 py-2 sm:py-4'>
            <img alt="" className='w-11 sm:w-20 rounded-full' src={profileImg} />
          </div>
          <div className='flex flex-col'>
            <span className='text-body_medium'>{name}</span>
            <span className='body-xxs text-T06'>{designation}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReviewCard
import React from 'react';

const Note = ({ customClasses }) => {
  return (
    <div className={`${customClasses}`}>
      <h3 className="body-lg text-T01 mb-3">Note</h3>
      <div className="py-8 sm:px-10 px-4.5 bg-M04 rounded-2xl">
        <p className="body-xxs text-T05 mb-3">Due to global market fluctuations, shipping space and equipment availability may be limited, and rate validity could vary based on the estimated time of departure (ETD).</p>
        <p className="body-xxs text-T05 mb-3">Be aware that the current tariffs shown do not include potential peak season General Rate Increases (GRI), Equipment Imbalance Surcharges (EIS/CIC), or other unforeseen surcharges that carriers may impose post-ETD.</p>
        <p className="body-xxs text-T05 mb-3">Additionally, for heavy 20'ST containers (&gt;18t), carriers may apply an overweight surcharge, which is not included in standard rates and will be confirmed upon booking.</p>
      </div>
    </div>
  )
}

export default Note;
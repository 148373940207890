import React, { useState } from 'react'
import ButtonPrimary from '../components/reusable-components/buttons/ButtonPrimary';
import ButtonSecondary from '../components/reusable-components/buttons/ButtonSecondary';
import ButtonLogin from '../components/reusable-components/buttons/ButtonLogin';
// import DropdownClassic from '../components/reusable_components/drop_downs/DropdownClassic';
import DropdownAutoComplete from '../components/reusable-components/inputfields/InputAutoComplete';
import InputBasic from '../components/reusable-components/inputfields/InputBasic';
import InputWithDropdown from '../components/reusable-components/inputfields/InputWithDropdown';
import DropdownClassic from '../components/reusable-components/drop-downs/DropdownClassic';
import InputForDimension from '../components/reusable-components/inputfields/InputForDimension';

const options = [
  {
    "id": 639,
    "name": "Nha Trang",
    "display_name": "Nha Trang (VNNHA), Vietnam, Asia",
    "latitude": "12.20371",
    "longitude": "109.1971",
    "country_code": "VN",
    "postal_code": null,
    "port_code": "VNNHA",
    "is_icd": false,
    "country_id": 129,
    "created_at": "2023-08-29T05:05:10.000000Z",
    "updated_at": "2023-08-29T05:05:10.000000Z"
  },
  {
    "id": 8099,
    "name": "Jawaharlal Nehru (Nhava Sheva) Jawaharlal Nehru (Nhava Sheva)",
    "display_name": "Jawaharlal Nehru (Nhava Sheva) (INNSA), Mumbai, India",
    "latitude": "18.952",
    "longitude": "72.948",
    "country_code": "IN",
    "postal_code": "400707",
    "port_code": "INNSA",
    "is_icd": false,
    "country_id": 231,
    "created_at": "2023-08-29T05:07:54.000000Z",
    "updated_at": "2023-08-29T05:07:54.000000Z"
  },
  {
    "id": 9264,
    "name": "Khanh Hoa Seaport",
    "display_name": "Khanh Hoa Seaport, Nha Trang, Vietnam",
    "latitude": "12.240486",
    "longitude": "109.195724",
    "country_code": "VN",
    "postal_code": "570000",
    "port_code": "VNCXR",
    "is_icd": false,
    "country_id": 129,
    "created_at": "2023-08-29T05:08:26.000000Z",
    "updated_at": "2023-08-29T05:08:26.000000Z"
  },
]

const weightOptions = [
  {
    id: 1,
    name: "KG",
  },
  {
    id: 2,
    name: "LBS",
  }
]

const ComponentsPage = () => {
  const [selected, setSelected] = useState({
    "id": 8099,
    "name": "Jawaharlal Nehru (Nhava Sheva)",
    "display_name": "Jawaharlal Nehru (Nhava Sheva) (INNSA), Mumbai",
    "latitude": "18.952",
    "longitude": "72.948",
    "country_code": "IN",
    "postal_code": "400707",
    "port_code": "INNSA",
    "is_icd": false,
    "country_id": 231,
    "created_at": "2023-08-29T05:07:54.000000Z",
    "updated_at": "2023-08-29T05:07:54.000000Z"
  })

  const handleSelected = (option) => {
    setSelected(option);
    setOriginSearch(option?.port_code);
  };

  const [originSearch, setOriginSearch] = useState("")
  const handleOnChange = (e) => {
    setOriginSearch(e.target.value);
  }

  const [weight, setWeight] = useState({
    id: 1,
    name: "KG",
  })
  const handleWeightSelected = (option) => {
    setWeight(option);
  };

  const [dimensions, setDimensions] = useState({
    length: "",
    width: "",
    height: "",
  })

  const onChangeDimensions = (e) => {
    setDimensions({
      ...dimensions,
      [e.target.name]: e.target.value,
    })
  };

  return (
    <div className="container">
      <div className=''>
        <ButtonPrimary
          text="Search Prices"
        // disabled={true}
        />

        <ButtonPrimary
          text="Search Prices"
          size="medium"
        // disabled={true}
        />

        <ButtonSecondary
          text="Search Prices"
        // disabled={true}
        />

        <ButtonLogin
          text="Register / Login"
        // disabled={true}
        />

        <DropdownClassic
          // options={options}
          selected={selected}
          onClick={handleSelected}
          options={options}
          icon="briefcase"
        />

        <DropdownAutoComplete
          options={options}
          selected={selected}
          onClick={handleSelected}
          onChange={handleOnChange}
          value={originSearch}
          placeholder="Select Origin"
        />

        <InputBasic
          value={originSearch}
          onChange={handleOnChange}
          placeholder="Placeholder"
        />

        <div className='w-1/4'>
          <InputWithDropdown
            options={weightOptions}
            onChange={handleOnChange}
            onClick={handleWeightSelected}
            value={originSearch}
            selected={weight}
            placeholder="Placeholder"
          />
        </div>

        {/* <div className='w-1/4'> */}
        <InputForDimension
          options={weightOptions}
          onChange={onChangeDimensions}
          onClick={handleWeightSelected}
          length={dimensions?.length}
          width={dimensions?.width}
          height={dimensions?.height}
          selected={weight}
        />
        {/* </div> */}
      </div>
    </div>
  )
}

export default ComponentsPage;
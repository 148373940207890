import React, { useContext } from 'react';
// import ModalBasic from '../reusable-components/modal/ModalBasic';
import ButtonPrimary from '../reusable-components/buttons/ButtonPrimary';
import Modal from '../reusable-components/modal/Modal';
import AirplaneIcon from "../../assets/icons/Airplane.svg";
import { ModalContext } from '../../App';
import { useSelector } from 'react-redux';
import { getUserData } from '../../redux/slices/userDataSlice';
import useInitiateCall from '../../network/hooks/useInitiateCall';

const BookNowPopup = ({ open, setOpen, callback }) => {
  const { request: initiateCall, isLoading: initiateCallLoading } = useInitiateCall();

  const getModalData = useContext(ModalContext);
  const userData = useSelector(getUserData);

  const handleInitiateCall = async () => {
    const { data, error } = await initiateCall(userData);

    if (data && data.success) {
      getModalData.setModalOpen(false);
      getModalData.setModalType("CALLSUCCESS");
      getModalData.setModalOpen(true);
    }

    if (error) {
      window.alert(error?.response?.data?.message);
    }
  };

  return (
    // <ModalBasic id="basic-modal" open={open} setOpen={setOpen} title="Basic Modal" maxWidth="max-w-[800px]">
    <Modal open={open} onClose={() => setOpen(false)} maxWidth="max-w-[465px]">
      <div className='bg-white rounded-3xl p-10'>
        <div className='mb-6'>
          <img className='max-w-[56px] w-full' src={AirplaneIcon} alt="" />
        </div>

        <h5 className='label-sm mb-2'>Booking Feature Coming Soon!</h5>

        <p className='body-xs mb-6'>We're excited to announce that we're working on a new booking feature that will make it even easier for you to ship with us. We're still working hard to perfect the new booking feature, but we expect to have it ready in the near future. In the meantime, you can still book your shipments by clicking on “Call to Book”</p>

        <ButtonPrimary
          text={"Call to Book"}
          additionalClasses={"w-full mb-2"}
          onClick={() => handleInitiateCall()}
          loading={initiateCallLoading}
        />

        <div className='max-w-max mx-auto'>
          <button onClick={(e) => { e.stopPropagation(); setOpen(false) }} className='body-xxs'>Cancel</button>
        </div>
      </div>
    </Modal>
    // </ModalBasic>
  )
}

export default BookNowPopup;
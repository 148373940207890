import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import BookingSummary from '../partials/details-page/BookingSummary';
import Note from '../partials/details-page/Note';
import PriceBreakdown from '../partials/details-page/PriceBreakdown';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const DetailsPage = () => {
  const navigate = useNavigate();
  const query = useQuery();
  let URLdata = query.get("data");
  if (URLdata) URLdata = JSON.parse(atob(URLdata));
  let data = URLdata?.data;
  let loadDetailsData = URLdata?.loadDetailsData;
  let searchQueryData = URLdata?.searchQueryData;
  // console.log(" data", data);
  // console.log("search data", searchQueryData);
  // console.log("load data", loadDetailsData);

  return (
    <div className="details-page-wrapper bg-M03 lg:pt-12 pt-6 pb-7 min-h-[calc(100vh-94px)]">
      <div className="container">
        {/* go back to listings page */}
        <div className="lg:mb-12 mb-6 cursor-pointer">
          <div onClick={() => navigate(-1)} className="flex items-center p-1 space-x-5">
            <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12" fill="none">
              <path d="M6.25 0.9375L1.1875 6L6.25 11.0625" stroke="#334155" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <span className="body-xs text-T01 block">Go back</span>
          </div>
        </div>

        <div className="flex flex-col lg:flex-row gap-9">
          <div className="grow">
            {/* Booking Summary */}
            <BookingSummary data={data} loadDetailsData={loadDetailsData} searchQueryData={searchQueryData} />

            {/* Note */}
            <Note customClasses="hidden lg:block" />
          </div>

          {/* Price Breakdown */}
          <PriceBreakdown data={data} />

          {/* Note */}
          <Note customClasses="block lg:hidden" />
        </div>
      </div>
    </div>
  )
}

export default DetailsPage;
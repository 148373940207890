import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  payloadData: null,
};

const payloadDataSlice = createSlice({
  name: "payloadData",
  initialState,
  reducers: {
    setPayloadData: (state, action) => {
      state.payloadData = action.payload; //TODO
    },
  },
});

export const { setPayloadData } = payloadDataSlice.actions;
export const getPayloadData = (state) => state.payload.payloadData;

export default payloadDataSlice.reducer;

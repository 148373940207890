import React from 'react';

const KeyFeatureCard = ({ title, content, image }) => {
  return (
    <div className='flex items-center border border-black rounded-3xl px-5 sm:px-8 py-6 sm:py-11 mx-5'>
      {/* icon */}
      <div className='mr-7 sm:mr-12'>
        <img alt="" className='w-16' src={image} />
      </div>
      <div className='w-80'>
        {/* title */}
        <h3 className='label-md text-T01'>{title}</h3>

        {/* content */}
        <p className='body-sm text-T03'>{content}</p>
      </div>
    </div>
  )
}

export default KeyFeatureCard;
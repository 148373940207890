import React, { useEffect, useRef, useState } from 'react'
import Transition from '../../../../utils/Transition';
import RadioGroup from '../../../../components/reusable-components/radio/RadioGroup';
import SeaLCLLoadDetails from './partials/SeaLCLLoadDetails';
import SeaFCLLoadDetails from './partials/SeaFCLLoadDetails';
import AirLoadDetails from './partials/AirLoadDetails';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchQueryData, setSearchQueryData } from '../../../../redux/slices/searchQueryDataSlice';
import { getLoadDetailsData, setLoadDetailsData } from '../../../../redux/slices/loadDetailsDataSlice';

const LoadDetails = ({ placeholder, configData, loadDetailsValid, setLoadDetailsValid }) => {
  const dispatch = useDispatch();
  const searchQueryData = useSelector(getSearchQueryData);
  const loadDetailsData = useSelector(getLoadDetailsData);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [counter, setCounter] = useState(0);

  const trigger1 = useRef(null);
  const trigger2 = useRef(null);
  const dropdown = useRef(null);

  const handleLoadDetailsValid = () => {
    // check for ocean & lcl
    if ((searchQueryData?.type === "ocean" && searchQueryData?.container_type === "lcl") || searchQueryData?.type === "air") {
      loadDetailsData?.forEach((item) => {
        if (item?.dimensions?.length === "") {
          setLoadDetailsValid(false);
          return;
        } else setLoadDetailsValid(true);

        if (item?.dimensions?.width === "") {
          setLoadDetailsValid(false);
          return;
        } else setLoadDetailsValid(true);

        if (item?.dimensions?.height === "") {
          setLoadDetailsValid(false);
          return;
        } else setLoadDetailsValid(true);

        if (item?.weight_per_unit === "") {
          setLoadDetailsValid(false);
          return;
        } else setLoadDetailsValid(true);

        if (item?.total_units === "") {
          setLoadDetailsValid(false);
          return;
        } else setLoadDetailsValid(true);
      })
    }

    // check for ocean & fcl
    if (searchQueryData?.type === "ocean" && searchQueryData?.container_type === "fcl") {
      if (loadDetailsData?.total_units === "") {
        setLoadDetailsValid(false);
        return;
      } else setLoadDetailsValid(true);
    }

  };

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger1.current.contains(target) || trigger2.current.contains(target)) return;
      setDropdownOpen(false);
      handleLoadDetailsValid();
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
      handleLoadDetailsValid();
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative">
      <div
        className={`flex border ${dropdownOpen ? "border-M01" : "border-S01"}  focus-within:border-M01 focus:border-M01 ${loadDetailsValid ? "" : "border-red-600"} rounded-5xl transition-all duration-500`}
      >
        <div
          className="px-5 w-full max-h-[44px] bg-M04 outline-none border-none rounded-s-5xl text-left label-xxs text-black cursor-pointer"
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
            dispatch(setSearchQueryData({
              ...searchQueryData,
              counter: 1,
            }))
          }}
          ref={trigger1}
        >
          {searchQueryData?.counter < 1 ?
            <div className='my-3'>{placeholder}</div>
            :
            <div className='flex flex-wrap items-center gap-1 my-2 max-h-[28px] w-full overflow-auto no-scrollbar'>
              {/* container type */}
              <div className='text-sm font-medium py-1 px-3 bg-M07 rounded-5xl max-w-max w-full'>{searchQueryData?.container_type.toUpperCase()}</div>

              {loadDetailsData?.length > 1 ?
                // for multiple loads
                <>
                  {[...Array(loadDetailsData?.length)].map((item, index) => {
                    return (
                      <div key={Math.random()} className='text-sm font-medium py-1 px-3 bg-M07 rounded-5xl max-w-max w-full'>Load {index + 1}</div>
                    )
                  })
                  }
                </>
                :
                // for single load detailed UI
                <>
                  {searchQueryData?.container_type === "lcl" &&
                    <div className='text-sm font-medium py-1 px-3 bg-M07 rounded-5xl max-w-max w-full'>{loadDetailsData[0].package_type?.name}</div>
                  }
                  {(searchQueryData?.container_type === "lcl" &&
                    loadDetailsData[0]?.dimensions?.length !== "" &&
                    loadDetailsData[0]?.dimensions?.width !== "" &&
                    loadDetailsData[0]?.dimensions?.height !== "") &&
                    <div className='text-sm font-medium py-1 px-3 bg-M07 rounded-5xl max-w-max w-full'>
                      {loadDetailsData[0]?.dimensions?.length} x {loadDetailsData[0]?.dimensions?.width} x {loadDetailsData[0]?.dimensions?.height} {loadDetailsData[0]?.dimensions?.unit?.name}
                    </div>
                  }
                  {(searchQueryData?.container_type === "lcl" &&
                    loadDetailsData[0]?.weight_per_unit !== "") &&
                    <div className='text-sm font-medium py-1 px-3 bg-M07 rounded-5xl max-w-max w-full'>
                      {loadDetailsData[0]?.weight_per_unit} {loadDetailsData[0]?.weight_unit?.name}
                    </div>
                  }
                  {(searchQueryData?.container_type === "lcl" &&
                    loadDetailsData[0]?.total_units !== "") &&
                    <div className='text-sm font-medium py-1 px-3 bg-M07 rounded-5xl max-w-max w-full'>
                      {loadDetailsData[0]?.total_units} Units
                    </div>
                  }
                </>
              }

              {/* for FCL */}
              {/* container type */}
              {(searchQueryData?.container_type === "fcl") &&
                <div className='text-sm font-medium py-1 px-3 bg-M07 rounded-5xl max-w-max w-full'>{loadDetailsData?.container?.name}</div>
              }
              {/* no of units */}
              {(searchQueryData?.container_type === "fcl" && loadDetailsData?.total_units !== "") &&
                <div className='text-sm font-medium py-1 px-3 bg-M07 rounded-5xl max-w-max w-full'>{loadDetailsData?.total_units} Units</div>
              }
            </div>
          }
        </div>
        <button
          className="flex items-center justify-center gap-1 rounded-e-5xl bg-M02 px-4.5"
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
            dispatch(setSearchQueryData({
              ...searchQueryData,
              counter: 1,
            }))
            //  setCounter(1)
          }}
          ref={trigger2}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
            <path d="M1 0.5L6 5.5L11 0.5" stroke="#7D11C8" strokeWidth="1.2" />
          </svg>
        </button>
      </div >
      <Transition
        show={dropdownOpen}
        tag="div"
        className="z-10 absolute top-full left-0 w-full bg-white border border-S01 rounded-3xl py-4.5 shadow-lg overflow-x-visible no-scrollbar mt-1"
        enter="transition ease-out duration-100 transform"
        enterStart="opacity-0 -translate-y-2"
        enterEnd="opacity-100 translate-y-0"
        leave="transition ease-out duration-100"
        leaveStart="opacity-100"
        leaveEnd="opacity-0"
      >
        <div
          ref={dropdown}
          onFocus={() => setDropdownOpen(true)}
        // onBlur={() => setDropdownOpen(false)}
        >
          {searchQueryData?.type === "ocean" ?
            <div className="">
              <div className="flex gap-7 mb-4.5 px-5">
                <span className="body-xs text-black">Select Your Load Type</span>
                <RadioGroup
                  label1="LCL"
                  label2="FCL"
                  value1="lcl"
                  value2="fcl"
                  checked1={searchQueryData?.container_type === "lcl"}
                  checked2={searchQueryData?.container_type === "fcl"}
                  onChange1={() => {
                    dispatch(setSearchQueryData({
                      ...searchQueryData,
                      container_type: "lcl",
                    }));
                    dispatch(setLoadDetailsData([
                      {
                        id: Math.floor(Math.floor(Math.random() * 9000000000) + 1),
                        package_type: {
                          id: 1,
                          name: "Boxes",
                          is_active: 1,
                        },
                        dimensions: {
                          length: "",
                          width: "",
                          height: "",
                          unit: {
                            id: 1,
                            name: "IN",
                          },
                        },
                        weight_per_unit: "",
                        weight_unit: {
                          id: 1,
                          name: "KG",
                        },
                        total_units: "",
                      }
                    ]));
                  }}
                  onChange2={() => {
                    dispatch(setSearchQueryData({
                      ...searchQueryData,
                      container_type: "fcl",
                    }));
                    dispatch(setLoadDetailsData({
                      container: {
                        id: 1,
                        name: "20GP",
                        is_active: 1,
                      },
                      total_units: "",
                    }))
                  }}
                />
              </div>

              {searchQueryData?.container_type === "lcl" ?
                // lcl
                <SeaLCLLoadDetails
                  configData={configData}
                  loadDetailsValid={loadDetailsValid}
                  setLoadDetailsValid={setLoadDetailsValid}
                  dropdownOpen={dropdownOpen}
                  setDropdownOpen={setDropdownOpen}
                />
                :
                // fcl
                <SeaFCLLoadDetails
                  configData={configData}
                  loadDetailsValid={loadDetailsValid}
                  setLoadDetailsValid={setLoadDetailsValid}
                  dropdownOpen={dropdownOpen}
                  setDropdownOpen={setDropdownOpen}
                />
              }
            </div>
            :
            // air
            <AirLoadDetails
              configData={configData}
              loadDetailsValid={loadDetailsValid}
              setLoadDetailsValid={setLoadDetailsValid}
              dropdownOpen={dropdownOpen}
              setDropdownOpen={setDropdownOpen}
            />
          }
        </div>
      </Transition>
    </div >
  )
}

export default LoadDetails;
import React, { useEffect, useRef, useState } from 'react';
import Transition from '../../../utils/Transition';


const DropdownClassic = ({ options, selected, value, onClick, placeholder, icon, dropdownPosition }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger1 = useRef(null);
  const trigger2 = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger1.current.contains(target) || trigger2.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative">
      <div
        className="flex border border-S01 focus-within:border-M01 focus:border-M01 rounded-5xl transition-all duration-500"
      >
        <button
          className="px-5 py-3 w-full bg-M04 outline-none border-none rounded-5xl text-left label-xxs text-black"
          onClick={(e) => {
            e.preventDefault();
            setDropdownOpen(!dropdownOpen);
          }}
          ref={trigger1}
        >
          {selected?.name}
        </button>
        <button
          className="flex items-center justify-center gap-1 body-xxs font-medium text-T01 rounded-e-5xl bg-M07 px-2"
          onClick={(e) => {
            e.preventDefault();
            setDropdownOpen(!dropdownOpen);
          }}
          ref={trigger2}
        >
          {icon === "briefcase" ?
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15" fill="none">
              <path d="M10.8 14V2.44444C10.8 2.06135 10.6525 1.69395 10.3899 1.42307C10.1274 1.15218 9.7713 1 9.4 1H6.6C6.2287 1 5.8726 1.15218 5.61005 1.42307C5.3475 1.69395 5.2 2.06135 5.2 2.44444V14M2.4 3.88889H13.6C14.3732 3.88889 15 4.53559 15 5.33333V12.5556C15 13.3533 14.3732 14 13.6 14H2.4C1.6268 14 1 13.3533 1 12.5556V5.33333C1 4.53559 1.6268 3.88889 2.4 3.88889Z" stroke="#1C2433" strokeWidth="1.4458" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
              <path d="M9.91667 0H1.75C1.28587 0 0.840752 0.184375 0.512563 0.512563C0.184375 0.840752 0 1.28587 0 1.75V9.91667C0 10.3808 0.184375 10.8259 0.512563 11.1541C0.840752 11.4823 1.28587 11.6667 1.75 11.6667H9.91667C10.3808 11.6667 10.8259 11.4823 11.1541 11.1541C11.4823 10.8259 11.6667 10.3808 11.6667 9.91667V1.75C11.6667 1.28587 11.4823 0.840752 11.1541 0.512563C10.8259 0.184375 10.3808 0 9.91667 0ZM4.66667 1.16667H7V2.9925L6.15417 2.40917C6.05892 2.34644 5.94738 2.31301 5.83333 2.31301C5.71929 2.31301 5.60775 2.34644 5.5125 2.40917L4.66667 2.9925V1.16667ZM10.5 9.91667C10.5 10.0714 10.4385 10.2198 10.3291 10.3291C10.2198 10.4385 10.0714 10.5 9.91667 10.5H1.75C1.59529 10.5 1.44692 10.4385 1.33752 10.3291C1.22812 10.2198 1.16667 10.0714 1.16667 9.91667V1.75C1.16667 1.59529 1.22812 1.44692 1.33752 1.33752C1.44692 1.22812 1.59529 1.16667 1.75 1.16667H3.5V4.08333C3.50028 4.18886 3.52918 4.29234 3.58363 4.38273C3.63807 4.47313 3.71602 4.54707 3.80917 4.59667C3.90038 4.6435 4.00234 4.66547 4.10475 4.66035C4.20716 4.65523 4.30641 4.6232 4.3925 4.5675L5.83333 3.61667L7.2625 4.5675C7.35023 4.62528 7.45194 4.65829 7.55688 4.66306C7.66182 4.66782 7.7661 4.64416 7.85871 4.59457C7.95132 4.54498 8.02882 4.4713 8.08302 4.38132C8.13723 4.29133 8.16613 4.18838 8.16667 4.08333V1.16667H9.91667C10.0714 1.16667 10.2198 1.22812 10.3291 1.33752C10.4385 1.44692 10.5 1.59529 10.5 1.75V9.91667Z" fill="#2D1C33" />
            </svg>}
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
            <path d="M1 0.5L6 5.5L11 0.5" stroke="#202020" strokeWidth="1.2" />
          </svg>
        </button>
      </div>
      {options?.length !== 0 &&
        <Transition
          show={dropdownOpen}
          tag="div"
          className={`z-[11] absolute ${dropdownPosition === "top" ? "bottom-[calc(100%+2px)]" : "top-full mt-1"} left-0 w-full bg-white border border-S01 rounded-3xl p-1 shadow-lg overflow-x-hidden no-scrollbar overflow-y-scroll max-h-[150px]`}
          enter="transition ease-out duration-100 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-100"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          <div
            ref={dropdown}
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setDropdownOpen(false)}
          >
            {
              options?.map(option => {
                if (option?.is_active === 1) {
                  return (
                    <button
                      key={option?.id}
                      tabIndex="0"
                      className={`flex items-center w-full truncate text-left hover:bg-slate-50 py-2 px-3 rounded-3xl cursor-pointer ${option?.id === selected?.id && 'bg-M08'}`}
                      onClick={(e) => {
                        e.preventDefault();
                        onClick(option);
                        setDropdownOpen(false);
                      }}
                    >
                      <svg className={`mr-2 ${option?.id !== selected?.id && 'invisible'}`} xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                        <path d="M11.3332 1L3.99984 8.33333L0.666504 5" stroke="#334155" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      <p className={`${option?.id === selected?.id ? 'body-xs font-medium' : 'body-xs'}`}>{option?.name}</p>
                    </button>
                  )
                }
                else return "";
              })
            }
          </div>
        </Transition>}
    </div>
  );
}

export default DropdownClassic;
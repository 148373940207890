import React, { useCallback, useEffect, useState } from 'react';
import { dimensionsUnitOptions, weightUnitOptions } from '../../../../../mockdata/options';
import DropdownClassic from '../../../../../components/reusable-components/drop-downs/DropdownClassic';
import InputForDimension from '../../../../../components/reusable-components/inputfields/InputForDimension';
import InputWithDropdown from '../../../../../components/reusable-components/inputfields/InputWithDropdown';
import InputBasic from '../../../../../components/reusable-components/inputfields/InputBasic';
import ButtonPrimary from "../../../../../components/reusable-components/buttons/ButtonPrimary"
import ButtonSecondary from '../../../../../components/reusable-components/buttons/ButtonSecondary';
import PlusIcon from "../../../../../assets/icons/plus-icon.svg";
import BinIcon from "../../../../../assets/icons/Bin.svg";
import { useDispatch, useSelector } from 'react-redux';
import { getLoadDetailsData, setLoadDetailsData } from '../../../../../redux/slices/loadDetailsDataSlice';
import { getSearchQueryData } from '../../../../../redux/slices/searchQueryDataSlice';

const SeaLCLLoadDetails = ({ configData, loadDetailsValid, setLoadDetailsValid, dropdownOpen, setDropdownOpen }) => {
  const dispatch = useDispatch();
  const loadDetailsData = useSelector(getLoadDetailsData);
  const searchQueryData = useSelector(getSearchQueryData);
  const [activeID, setactiveID] = useState(0);
  const [lengthError, setLengthError] = useState("");
  const [widthError, setWidthError] = useState("");
  const [heightError, setHeightError] = useState("");
  const [WeightPerUnitError, setWeightPerUnitError] = useState("");
  const [noOfUnitsError, setNoOfUnitsError] = useState("");

  const handleLoadDetailsValid = () => {
    // check for ocean & lcl
    if ((searchQueryData?.type === "ocean" && searchQueryData?.container_type === "lcl") || searchQueryData?.type === "air") {
      loadDetailsData?.forEach((item) => {
        if (item?.dimensions?.length === "") {
          setLoadDetailsValid(false);
          return;
        } else setLoadDetailsValid(true);

        if (item?.dimensions?.width === "") {
          setLoadDetailsValid(false);
          return;
        } else setLoadDetailsValid(true);

        if (item?.dimensions?.height === "") {
          setLoadDetailsValid(false);
          return;
        } else setLoadDetailsValid(true);

        if (item?.weight_per_unit === "") {
          setLoadDetailsValid(false);
          return;
        } else setLoadDetailsValid(true);

        if (item?.total_units === "") {
          setLoadDetailsValid(false);
          return;
        } else setLoadDetailsValid(true);
      })
    }

    // check for ocean & fcl
    if (searchQueryData?.type === "ocean" && searchQueryData?.container_type === "fcl") {
      if (loadDetailsData?.total_units === "") {
        setLoadDetailsValid(false);
        return;
      } else setLoadDetailsValid(true);
    }

  };

  const onChangeDimensions = (e) => {
    const reg = new RegExp("^[-+]?([0-9]+(.[0-9]+)?|.[0-9]*[.]+)$");

    if (!e.target.value || reg.test(e.target.value)) {
      let newLoadDetails = loadDetailsData?.map((object) => {
        if (object?.id === activeID) {
          return {
            ...object,
            dimensions: {
              ...object?.dimensions,
              [e.target.name]: e.target.value,
            }
          }
        }
        return object;
      });
      dispatch(setLoadDetailsData(newLoadDetails));
    }
    if (e.target.name === "length") {
      setLengthError("");
    }
    if (e.target.name === "width") {
      setWidthError("");
    }
    if (e.target.name === "height") {
      setHeightError("");
    }
  };

  const handleAddAnotherLoad = () => {
    const LoadToBeChecked = loadDetailsData?.filter((item) => item?.id === activeID)[0];
    // console.log("LoadToBeChecked", LoadToBeChecked);
    let isError = false;

    if (LoadToBeChecked?.dimensions?.length === "") {
      setLengthError("Required");
      isError = true;
    }
    if (LoadToBeChecked?.dimensions?.width === "") {
      setWidthError("Required");
      isError = true;
    }
    if (LoadToBeChecked?.dimensions?.height === "") {
      setHeightError("Required");
      isError = true;
    }
    if (LoadToBeChecked?.weight_per_unit === "") {
      setWeightPerUnitError("Required");
      isError = true;
    }
    if (LoadToBeChecked?.total_units === "") {
      setNoOfUnitsError("Required");
      isError = true;
    }

    if (isError === true) {
      return;
    }

    dispatch(setLoadDetailsData([
      ...loadDetailsData,
      {
        id: Math.floor(Math.floor(Math.random() * 9000000000) + 1),
        package_type: {
          id: 1,
          name: "Boxes",
          is_active: 1,
        },
        dimensions: {
          length: "",
          width: "",
          height: "",
          unit: {
            id: 1,
            name: "IN",
          },
        },
        weight_per_unit: "",
        weight_unit: {
          id: 1,
          name: "KG",
        },
        total_units: "",
      }
    ]))
  };

  const handleOpenLoad = (id) => {
    const LoadToBeChecked = loadDetailsData?.filter((item) => item?.id === activeID)[0];
    let isError = false;

    if (LoadToBeChecked?.dimensions?.length === "") {
      setLengthError("Required");
      isError = true;
    }
    if (LoadToBeChecked?.dimensions?.width === "") {
      setWidthError("Required");
      isError = true;
    }
    if (LoadToBeChecked?.dimensions?.height === "") {
      setHeightError("Required");
      isError = true;
    }
    if (LoadToBeChecked?.weight_per_unit === "") {
      setWeightPerUnitError("Required");
      isError = true;
    }
    if (LoadToBeChecked?.total_units === "") {
      setNoOfUnitsError("Required");
      isError = true;
    }

    if (isError === true) {
      return;
    }

    setactiveID(id);
  }

  const handleRemoveLoad = (id) => {
    setLengthError("");
    setWidthError("");
    setHeightError("");
    setWeightPerUnitError("");
    setNoOfUnitsError("");

    const newLoadDetails = [...loadDetailsData];
    let loadToBeRemoved = loadDetailsData.findIndex((item) => item?.id === id);
    if (loadToBeRemoved > -1) {
      newLoadDetails.splice(loadToBeRemoved, 1);
    }
    dispatch(setLoadDetailsData(newLoadDetails))
  };

  const handleDisableConfirm = useCallback(
    () => {
      let isError = false;

      loadDetailsData?.forEach((item) => {
        if (item?.dimensions?.length === "") {
          isError = true;
          return isError;
        }
        if (item?.dimensions?.width === "") {
          isError = true;
          return isError;
        }
        if (item?.dimensions?.height === "") {
          isError = true;
          return isError;
        }
        if (item?.weight_per_unit === "") {
          isError = true;
          return isError;
        }
        if (item?.total_units === "") {
          isError = true;
          return isError;
        }
        return "";
      })

      return isError;
    }
    , [loadDetailsData]
  )

  // to set most recent load as active
  useEffect(() => {
    setactiveID(loadDetailsData[loadDetailsData?.length - 1]?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadDetailsData?.length])

  return (
    <div>
      <div className="max-h-[340px] overflow-y-scroll overflow-x-hidden space-y-3 pb-4.5 no-scrollbar px-5">
        {loadDetailsData?.map((item, index) => {
          return (
            <div key={item?.id} >
              {
                item?.id === activeID ?
                  <div className={`${loadDetailsData?.length > 1 && "border border-M01 p-4 rounded-3xl"} `}>
                    {loadDetailsData?.length > 1 &&
                      <div className="flex items-center justify-between">
                        <div className="body-xs text-T07">
                          Load {index + 1}
                        </div>
                        {loadDetailsData?.length > 1 &&
                          <button
                            className="text-red-500 font-bold p-2 rounded-e-5xl"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              handleRemoveLoad(item?.id)
                            }}
                          >
                            <img src={BinIcon} width={15} height={15} alt="Delete Load" />
                          </button>}
                      </div>
                    }
                    <div className="">
                      <span className="body-xs text-T07">Package Type</span>
                      <DropdownClassic
                        options={configData?.package_types}
                        onClick={(option) => {
                          let newLoadDetails = loadDetailsData?.map((object) => {
                            if (object?.id === activeID) {
                              return {
                                ...object,
                                package_type: option
                              }
                            }
                            return object;
                          });
                          dispatch(setLoadDetailsData(newLoadDetails))
                        }}
                        selected={item?.package_type}
                      />
                    </div>

                    <div className="mt-2 relative">
                      <span className="body-xs text-T07">Dimensions per Unit</span>
                      <InputForDimension
                        options={dimensionsUnitOptions}
                        onClick={(option) => {
                          let newLoadDetails = loadDetailsData?.map((object) => {
                            if (object?.id === activeID) {
                              return {
                                ...object,
                                dimensions: {
                                  ...object?.dimensions,
                                  unit: option,
                                }
                              }
                            }
                            return object;
                          });
                          dispatch(setLoadDetailsData(newLoadDetails));
                        }}
                        onChange={onChangeDimensions}
                        selected={item?.dimensions?.unit}
                        length={item?.dimensions?.length}
                        width={item?.dimensions?.width}
                        height={item?.dimensions?.height}
                        lengthError={lengthError}
                        widthError={widthError}
                        heightError={heightError}
                      />
                    </div>

                    <div className="flex items-center gap-4 mt-2">
                      <div className="relative">
                        <span className="body-xs text-T07">Weight per unit</span>
                        <InputWithDropdown
                          options={weightUnitOptions}
                          selected={item?.weight_unit}
                          value={item?.weight_per_unit}
                          onClick={(option) => {
                            let newLoadDetails = loadDetailsData?.map((object) => {
                              if (object?.id === activeID) {
                                return {
                                  ...object,
                                  weight_unit: option,
                                }
                              }
                              return object;
                            });
                            dispatch(setLoadDetailsData(newLoadDetails));
                          }}
                          onChange={(e) => {
                            const reg = new RegExp("^[-+]?([0-9]+(.[0-9]+)?|.[0-9]*[.]+)$");

                            if (!e.target.value || reg.test(e.target.value)) {
                              let newLoadDetails = loadDetailsData?.map((object) => {
                                if (object?.id === activeID) {
                                  return {
                                    ...object,
                                    weight_per_unit: e.target.value,
                                  }
                                }
                                return object;
                              });
                              dispatch(setLoadDetailsData(newLoadDetails));
                              setWeightPerUnitError("");
                            }
                          }}
                          dropdownPosition="top"
                        />
                        <span className="text-red-400 body-xxs absolute left-0 top-[100%]">{WeightPerUnitError}</span>
                      </div>

                      <div className="relative">
                        <span className="body-xs text-T07">No. of Units</span>
                        <InputBasic
                          value={item?.total_units}
                          onChange={(e) => {
                            const reg = new RegExp("^[0-9]+$");
                            if (!e.target.value || reg.test(e.target.value)) {

                              let newLoadDetails = loadDetailsData?.map((object) => {
                                if (object?.id === activeID) {
                                  return {
                                    ...object,
                                    total_units: e.target.value,
                                  }
                                }
                                return object;
                              });
                              dispatch(setLoadDetailsData(newLoadDetails));
                              setNoOfUnitsError("");
                            }
                          }}
                        />
                        <span className="text-red-400 body-xxs absolute left-0 top-[100%]">{noOfUnitsError}</span>
                      </div>
                    </div>
                  </div>
                  :
                  <div
                    className="flex items-center justify-between border border-S01 rounded-3xl cursor-pointer"
                  >
                    <span
                      className="body-xs text-T07 grow px-4 py-2"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleOpenLoad(item?.id);
                      }}
                    >
                      Load {index + 1}
                    </span>
                    <button
                      className="text-red-500 font-bold px-4 py-2.5 rounded-e-5xl"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleRemoveLoad(item?.id)
                      }}
                    >
                      <img src={BinIcon} width={15} height={15} alt="Delete Load" />
                    </button>
                  </div>
              }
            </div>
          );
        })}
      </div>
      <div className="pt-3 flex items-center gap-4 border-t border-S02 px-5">
        <ButtonSecondary
          text="Add Another Load"
          aditionalClasses="grow"
          borderColor="border-M01"
          icon={PlusIcon}
          onClick={handleAddAnotherLoad}
        />
        <ButtonPrimary
          text="Confirm"
          size="small"
          disabled={handleDisableConfirm()}
          onClick={() => {
            setDropdownOpen(false);
            handleLoadDetailsValid();
          }}
        />
      </div>
    </div>
  )
}

export default SeaLCLLoadDetails;
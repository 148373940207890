import React from 'react';
import SearchQuery from '../partials/home-page/search-query/SearchQuery';
import KeyFeatures from '../partials/home-page/key-features/KeyFeatures';
import CompaniesWeWorkWith from '../partials/home-page/CompaniesWeWorkWith';
import HowItWorks from '../partials/home-page/how-it-works/HowItWorks';
import Reviews from '../partials/home-page/review/Reviews';
import FAQ from '../partials/home-page/faq/FAQSection';
// import { useNavigate } from 'react-router-dom';
// import { ModalContext } from '../App';


const HomePage = () => {
  // const navigate = useNavigate();
  // const getModalData = useContext(ModalContext);

  return (
    <>
      {/* search query section */}
      <SearchQuery />

      {/* key feature section */}
      <KeyFeatures />

      {/* companies we work with section */}
      <CompaniesWeWorkWith />

      {/* how it works section */}
      <HowItWorks />

      {/* reviews about our services section */}
      <Reviews />

      {/* FAQ section */}
      <FAQ />
    </>
  )
};

export default HomePage;
import React from 'react';
import ReviewCard from './partials/ReviewCard';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { useRef } from 'react';
import PreviousIcon from "../../../assets/icons/Previous Icon.svg";
import RohitTandonImg from "../../../assets/images/Rohit Tandon.jpg";
import MayankSardanaImg from "../../../assets/images/Mayank Sardana.jpg";
import NextIcon from "../../../assets/icons/Next Icon.svg"

const reviewsList = [
  {
    name: "Mayank Sardana",
    desgination: "Owner, Wideair Cargo and Shipping Solutions",
    profileImg: MayankSardanaImg,
    reviewContent: "“Transparency is a big deal, and CargoXB gets that. The real-time updates have been a game-changer in terms of building credibility with our clients.”",
  },
  {
    name: "Rohit Tondon",
    desgination: "Operations head, Sciens Logistics India Private limited",
    profileImg: RohitTandonImg,
    reviewContent: "“Being a part of CargoXB's platform has really increased our exposure to exporters. It's been a fantastic opportunity for our business!”",
  },
  {
    name: "Mayank Sardana",
    desgination: "Owner, Wideair Cargo and Shipping Solutions",
    profileImg: MayankSardanaImg,
    reviewContent: "“Transparency is a big deal, and CargoXB gets that. The real-time updates have been a game-changer in terms of building credibility with our clients.”",
  },
  {
    name: "Rohit Tondon",
    desgination: "Operations head, Sciens Logistics India Private limited",
    profileImg: RohitTandonImg,
    reviewContent: "“Being a part of CargoXB's platform has really increased our exposure to exporters. It's been a fantastic opportunity for our business!”",
  },
];

const handleReviews = () => {
  let reviewsArray = [];

  reviewsList?.forEach((item) => {
    reviewsArray.push(
      <ReviewCard
        key={Math.random()}
        name={item?.name}
        designation={item?.desgination}
        profileImg={item?.profileImg}
        reviewContent={item?.reviewContent}
      />
    )
  })
  return reviewsArray;
};

const responsive = {
  0: {
    items: 1,
  },
  1024: {
    items: 2,
  }
}

const Reviews = () => {
  const carousel = useRef(null);

  return (
    <div id="reviews" className='bg-M03'>
      <div className='container'>
        <div className='py-16 sm:py-24 xl:py-28'>
          <div className='flex justify-between items-center pb-9 sm:pb-20'>
            <div>
              <h2 className='heading-h1 text-T01'>Hear it from<br />Our Partners</h2>
            </div>
            <div className='flex gap-3'>
              <button onClick={(e) => carousel?.current?.slidePrev(e)}>
                <img className=' w-full max-w-[35px] md:max-w-[64px]' src={PreviousIcon} alt="" />
              </button>
              <button onClick={(e) => carousel?.current?.slideNext(e)}>
                <img className=' w-full max-w-[35px] md:max-w-[64px]' src={NextIcon} alt="" />
              </button>
            </div>
          </div>

          <AliceCarousel
            items={handleReviews()}
            disableDotsControls
            responsive={responsive}
            ref={carousel}
            disableButtonsControls
            infinite
          // renderPrevButton={({ isDisabled }) => {
          //   return <>{isDisabled ?
          //     <svg xmlns="http://www.w3.org/2000/svg" width="64" height="66" viewBox="0 0 64 66" fill="none">
          //       <rect x="0.5" y="1" width="63" height="64" rx="31.5" fill="white" />
          //       <path d="M36 25.5L27.5 33.5L36 41.5" stroke="#656565" stroke-width="2" />
          //       <rect x="0.5" y="1" width="63" height="64" rx="31.5" stroke="#A1A1A1" />
          //     </svg>
          //     : <svg xmlns="http://www.w3.org/2000/svg" width="64" height="66" viewBox="0 0 64 66" fill="none">
          //       <rect x="0.5" y="1" width="63" height="64" rx="31.5" fill="white" />
          //       <path d="M36 25.5L27.5 33.5L36 41.5" stroke="#202020" stroke-width="2" />
          //       <rect x="0.5" y="1" width="63" height="64" rx="31.5" stroke="#7D11C8" />
          //     </svg>
          //   }
          //   </>
          // }}
          // renderNextButton={({ isDisabled }) => {
          //   return <>{isDisabled ?
          //     <svg xmlns="http://www.w3.org/2000/svg" width="64" height="66" viewBox="0 0 64 66" fill="none">
          //       <rect x="0.5" y="1" width="63" height="64" rx="31.5" fill="white" />
          //       <path d="M29.5 42.5L38 34.5L29.5 26.5" stroke="#656565" stroke-width="2" />
          //       <rect x="0.5" y="1" width="63" height="64" rx="31.5" stroke="#A1A1A1" />
          //     </svg>
          //     : <svg xmlns="http://www.w3.org/2000/svg" width="64" height="66" viewBox="0 0 64 66" fill="none">
          //       <rect x="0.5" y="1" width="63" height="64" rx="31.5" fill="white" />
          //       <path d="M29.5 42.5L38 34.5L29.5 26.5" stroke="#202020" stroke-width="2" />
          //       <rect x="0.5" y="1" width="63" height="64" rx="31.5" stroke="#7D11C8" />
          //     </svg>
          //   }
          //   </>
          // }}
          />
        </div>
      </div>
    </div>
  )
}

export default Reviews;
import React from 'react'

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-wrappper pt-35">
      <div className='pt-20 pb-12 bg-M02'>
        <h1 className="heading-h3 mb-30 text-center text-T01">Privacy Policy</h1>
      </div>

      <div className="container py-16">
        <div>
          <div className='flex flex-col text-black'>
            <div className=' mb-7'>
              <p className="mb-0 body-md text-justify">We thank you for your interest in our Platform. This Platform an internet-based platform, owned and operated by Celerity Consulting Private Limited, a company incorporated under the provisions of the Companies Act, 2013 and having its registered address as Unit No.1404, Gopal Heights, Netaji Subhash Place Pitampura, Delhi – 110034 (“We”, “us”, “our”, “CargoXB”). CargoXB, with a view to provide an enriching and holistic internet experience through its website (which includes mobile web browser extensions, Application Programming Interface (API) and other similar mediums operated by Celerity Consulting Private Limited) (“Platform”) provides an online marketplace for exporters, importers and freight forwarders and offers logistics, cargo and related services etc. (“Services”).</p>

              <p className="mb-0 body-md text-justify">This Privacy Policy (“Policy”) outlines the manner in which we regulate the Platform and collect, store, and use the Personal Information that you, the reader of this Policy ("User", "you", "your") provide to us.</p>

              <p className="mb-0 body-md text-justify">We deeply value the privacy of our Users and are committed to safeguard it, in accordance with the applicable law. </p>
            </div>

            <div className='mb-7'>
              <p className="mb-0 body-md text-justify">We also request you to read our Terms of Use (“Terms”) available at <a href='https://www.CargoXB.com'>www.CargoXB.com</a>. The Terms and this Policy form part of a single understanding and must be read together. Any term not defined herein shall be understood to have the meaning ascribed to it in the Terms.</p>
              <p className="mb-0 body-md text-justify">By accessing our Platform, you agree that you have read and understood this Policy in its entirety and consent to the privacy practices (and any uses and disclosures of information) that are mentioned in this Policy. Your continued use of this Platform shall confirm that you have provided your unconditional consent and conform to the terms of this Policy as regards collecting, maintaining, using, processing, and disclosing your personal and other information in accordance with this Policy. If you disagree with the contents of this this Policy or any part of it (including any referenced policies or guidelines), or if you reside in a jurisdiction, the laws of which prohibit you from using our Services, you are requested to terminate the use of the Platform immediately.</p>
            </div>

            <p className="body-md mb-7 text-justify">This Policy is published in accordance with Section 43A of the Information Technology Act, 2000, Rule 3(1) of the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021 and Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011 which requires publishing of a Privacy Policy by an intermediary for the collection, use, storage and transfer of sensitive personal data or information.</p>

            <div className='flex mb-3 body-lg text-T01 font-semibold'><div className='pr-1'> 1.</div> About this policy.</div>
            <p className='flex mb-3 body-md text-T03 text-justify'> This Policy is designed to safeguard the privacy of all our Users. The Platform utilizes the Personal Information (defined below) to enable you to access and enjoy the full scope of our Services. CargoXB is not involved in selling or renting your Personal Information or the results of the Services chosen by you, except (i) with your express consent or (ii) as otherwise provided in this Policy.
              Under this Policy, a User, inter alia has the following rights:
            </p>
            <div className=' mb-7'>
              <ul className='mb-0 body-md list-disc list-inside'>
                <li className='text-T03 text-justify'>Right to access Personal Information (as detailed in point number 9)</li>
                <li className='text-T03 text-justify'>Right to rectification of Personal Information (as detailed in point number 9)</li>
                <li className='text-T03 text-justify'>Right to erasure of Personal Information (as detailed in point number 12)</li>
                <li className='text-T03 text-justify'>Right to restrict processing of Personal Information (as detailed in point number10)</li>
                <li className='text-T03 text-justify'>Right to object to processing of Personal Information (as detailed in point number 10)</li>
                <li className='text-T03 text-justify'>Right to withdraw consent for processing of Personal Information (as detailed in point number 10)</li>
                <li className='text-T03 text-justify'>Right to data portability (as detailed in point number 9)</li>
                <li className='text-T03 text-justify'>Right against discrimination in data processing (as detailed in point number 2 )</li>
              </ul>
            </div>

            <div className='flex mb-3 body-lg text-T01 font-semibold'><div className='pr-1'> 2.</div> Objective of this Policy.</div>
            <div className='mb-7'>
              <p className="mb-0 body-md text-T03 text-justify">This Policy represents the manner in which we collect, store, share and use all or any information provided by a User while availing of our Services. </p>
              <p className="mb-0 body-md text-T03 text-justify">The objectives which this policy aims to achieve are:</p>
              <ul className='mb-3 body-md text-T03 text-justify list-inside list-disc'>
                <li>To help the User understand the exact nature of the information collected by us; and</li>
                <li>To explain and inform the User the reason for collecting such information shared , the manner in which it is stored and utilised, and the User’s rights in relation to such collection, storage, and usage.</li>
              </ul>
              <p className='mb-0 body-md text-T03 text-justify'> We at CargoXB believe strongly in the principles of equality and non-discrimination and keeping in line with these principles, we in no manner discriminate in collection, storage, use or transfer of Personal Information collected by us. Should you have any apprehensions about the same, even after reading this Policy, please write to us at <a className='text-text_primary' href='mailto:ojasvita@CargoXB.com'>ojasvita@CargoXB.com</a> with your query or concern.</p>
            </div>


            <div className='flex body-lg text-T01 font-semibold mb-3'><div className='pr-1'> 3.</div> Information collected by us and manner of collection </div>
            <p className="mb-7 body-md text-T03 text-justify">For effective and optimal utilisation of our Services, we shall collect the following information which shall, for the purpose of this Policy be treated and be collectively referred to as “Personal Information”.</p>

            <div className='flex body-lg text-T01 font-semibold mb-3'><div className='pr-1'> 4.</div> General Information</div>
            <p className="mb-7 body-md text-T03 text-justify">Given the nature of our Services, you shall be required to provide certain information about yourself at the time of registration as a User on the Platform or at the time of accessing our Services. This information shall include but not limited to your name, phone number, and email address.</p>

            <div className='flex body-lg text-T01 font-semibold mb-3'><div className='pr-1'> 5.</div> Information related to payments.</div>
            <p className="mb-7 body-md text-T03 text-justify">Access to our Services shall involve payment of Subscription Fees, Customization Fees, Support Fees, and any other applicable fees by a User. The fee required to be paid for a particular Service shall be such as prompted on the Platform. To make such payment, you shall be required to share certain details such as your UPI id/net banking details/credit/debit card number etc. We accept electronic channels such as UPI, NEFT, RTGS, IMPS for money transfers. To help us with processing of the money received from a User and the money to be paid to a User, we shall take assistance of certain authorised third-party payment service providers and shall be required to share your payment information with the third-party service provider.
              Please note- We do not store your payment information, except in the manner as allowed under the law applicable to us.
            </p>

            <div className='flex body-lg text-T01 font-semibold mb-3'><div className='pr-1'> 6.</div> Information Sharing</div>
            <p className="mb-3 body-md text-T03 text-justify">Given the nature of our Services, we have and shall also in the future, partner with certain entities including but not limited to service providers or sub-processors who perform certain services or process data on Our behalf e.g. payment processing, infrastructure management, insurance and cloud service (“Integration Partners”). While doing so, we only provide them with the information that they need to perform their specific function. </p>
            <p className="mb-7 body-md text-T03 text-justify">CargoXB will never sell or rent your data to any third-party without your express consent.</p>

            <div className='flex body-lg text-T01 font-semibold mb-3'><div className='pr-1'> 7.</div> Location Information</div>
            <p className="mb-7 body-md text-T03 text-justify">
              Information as to your location may be collected in connection with our Services. This may include obtaining data from your phone or other device directly or via our Integration Partners. You may opt out of the collection of such information except to the extent that collection of such information is integral to the operation of such Services.
            </p>

            <div className='flex body-lg text-T01 font-semibold mb-3'><div className='pr-1'> 8.</div>Automatic Information.</div>
            <p className="mb-3 body-md text-T03 text-justify">
              We automatically receive and store certain types of information whenever you visit, access or navigate our Platform or Services. This information is collected to update and maintain the  security  of the Platform and for analytics and reporting purposes. It does not include any personally identifiable information that may be used to specifically identify a User and is limited to collection of information related to the device being used (for instance IP address, browser used, queries, Operating System, device information, version, device identifier, network information, User’s language preferences and other related information etc.)
            </p>
            <p className="mb-3 body-md text-T03 text-justify">
              CargoXB uses this traffic data to help diagnose problems with its servers, to analyze trends, and to administer the website. CargoXB may collect and display various aggregate counts of the times that pages have been viewed. We may provide this information to third parties including Your name and e-mail address. These third parties are responsible for the privacy of information they receive under their own privacy policies.
            </p>
            <p className="mb-3 body-md text-T03 text-justify">
              We do not use cookies for the collection of information on our Platform.
            </p>

            <div className='flex body-lg text-T01 font-semibold mb-3'><div className='pr-1'> 9.</div>Use the Personal Information collected.</div>
            <p className="mb-3 body-md text-T03 text-justify">
              We shall use the Personal Information for, but not limited to, the following purposes and by accessing our Platform you expressly grant us your unqualified consent for such use.
            </p>
            <ul className='body-md text-T03 text-justify list-inside list-disc mb-7'>
              <li>To provide a user-friendly browsing experience;</li>
              <li>To provide access to our Services.</li>
              <li>To solve issues raised by our Users.</li>
              <li>To ensure our compliance with all our legal obligations.</li>
              <li>For research and development  purposes in order to enhance our marketing and promotional efforts;</li>
              <li>To conduct analysis of data and improve the quality of our Services;</li>
              <li>To add features on the Platform that are suited to the needs of its Users;</li>
              <li>To collect Non-Personal Identifiable Information (“NPII”) about our Users with an aim to improve the features and functionality of the Platform. We may analyse trends through aggregated demographic and psychographic offers to users. We may also share this NPII with our Integrated Partners to allow them to assist us in delivering tailored advertisements, promotions, discounts, and other offers to you when you use our Services; and</li>
              <li>For sharing it with a person or entity who purchases our business (“Buyer”): (a) In the event of sale of our entire business to the Buyer or (b) In the event of the sale of a part of our business, provided that such Personal Information forms part of the business asset being sold to the buyer.</li>
              <li>For sharing it with our commercial partners, Integration Partners or their affiliates, after receiving your consent.</li>
            </ul>

            <div className='flex body-lg text-T01 font-semibold mb-3'><div className='pr-1'> 10.</div>User Data</div>
            <div className=' mb-7'>
              <p className="mb-3 body-md text-T03 text-justify">
                The Platform allows Users to connect to Third-Party applications like our Integration Partners, to periodically or on-demand pull data (“User Data”) from those applications into our servers, and pass data from the Services onward to other Third-Party applications. These transfers are done only under the direction of, and under the sole control of the Users. By using the Service, You, subject to this Policy and the Terms, agree and accept that we have your permission to perform any such data transfers on your behalf, notwithstanding that these may contain personal data, and may cross international borders. We have no control over the privacy policies of Third-Party applications to or from which you might transfer data, and you represent and warrant that by doing so, you agree to the privacy policies adhered to by those applications.
              </p>
              <p className="mb-0 body-md text-T03 text-justify">
                User Data shall be stored in our systems as part of processing and retained till User’s account exists on our Platform to support service features such as history logging and error recovery or any other  feature required to provide services to the Users.
                We protect User Data using commercially feasible methods from interception or alteration. We will not examine or distribute any User Data except as required by law. We may however use automated tools to examine User Data in aggregate, in order to improve the Platform.
              </p>
            </div>

            <div className='flex body-lg text-T01 font-semibold mb-3'><div className='pr-1'> 11.</div>Email & Phone Communication:</div>
            <p className="mb-7 body-md text-T03 text-justify">
              When a User registers or provides his/her email address and/or phone number on our Platform, he/she agrees to receive email and/or SMS communications from CargoXB and its authorised affiliates for providing our Services to the User. We may use communication for promotional activities and marketing. User shall have the right to opt out of such marketing and promotional communications sent by Us by explicitly writing to us at <a className='text-text_primary' href='mailto:ojasvita@CargoXB.com'>ojasvita@CargoXB.com</a>.
            </p>

            <div className='flex body-lg text-T01 font-semibold mb-3'><div className='pr-1'> 12.</div>Securities measures employed by CargoXB.</div>
            <p className="mb-3 body-md text-T03 text-justify">
              CargoXB values the security of your Personal Information. In order to prevent unauthorised access to or alteration, disclosure, or destruction of your Personal Information, we have taken all reasonable security precautions, including but not limited to conducting internal reviews of data collection, storage, and security policies and putting the reasonable encryption and administrative, technical, legal, and physical security measures into place. While we make every effort to protect your Personal  Information and keep it secure, we acknowledge that no security measure, no matter how advanced, is impenetrable. As a result, we do not guarantee the security of the Personal Information we collect and store or the likelihood of non-interception of data while it is being sent to us by a User over the Internet.
            </p>
            <p className="mb-7 body-md text-T03 text-justify">
              When you register your account with us, you shall be required to provide a username, password, email address, phone number ("Login Information") to safeguard it. This Login Information shall only be known to you; and it shall be your sole responsibility not to share it with anyone. Any unauthorised use of the Login Information will be considered a violation of this Policy and shall result in the suspension of the relevant User account. Furthermore, you must notify us immediately of any suspected unauthorised use of your Login Information.
            </p>

            <div className='flex body-lg text-T01 font-semibold mb-3'><div className='pr-1'> 13.</div>Links to third-party platforms.</div>
            <p className="mb-3 body-md text-T03 text-justify">
              By accessing our Platform, you agree and acknowledge that the Platform contains links to other platforms/websites/portals that are regulated by their own privacy policies, over which we have no control, and any User visiting such platforms/websites/portals through such third-party links shall be subject to such platform’s/portal’s/website’s privacy rules, once they leave our servers. We disclaim all liability with respect to  the contents of such platform/portal/website, including its privacy practises.
            </p>
            <div className=' mb-3'>
              <p className="mb-3 body-md text-T03 text-justify">
                Access to and correction of Personal Information , denial of access in certain situations and Data Portability.
                A User shall have the right to access his/her Personal Information, collected by CargoXB including but not limited to the history of the User’s transactions, chats, etc., at any time. However, such access may be denied by CargoXB in certain circumstances set out in this Policy, including where:
              </p>
              <p className="flex mb-0 body-md text-T03 text-justify">
                <div className='mr-2'>1.</div>providing such access would be unlawful; or
              </p>
              <p className="flex mb-3 body-md text-T03 text-justify">
                <div className='mr-2'>2.</div>CargoXB is required or authorised to do so by or under any applicable law or a court/ tribunal order; and/or where giving access would be likely to prejudice enforcement related activities by or on behalf of an enforcement body.
              </p>
              <p className="mb-3 body-md text-T03 text-justify">
                A User shall also have the opportunity to have such Personal Information corrected and updated at any time if the User believes that such Personal Information is inaccurate, incomplete, or not up-to-date and we may periodically contact you to seek an update on any material change in such information. To get your information corrected write to us at <a className='text-text_primary' href='mailto:ojasvita@CargoXB.com'>ojasvita@CargoXB.com</a>.
                The User shall also have a right to request that CargoXB transfer the Personal Information collected by Us to another organization, or directly to the User, in structured, commonly used and machine-readable formats. We shall endeavour to process such request within 15 (fifteen) business days, however given the complexity of a request, more time may be required which shall in no case be more than 90 (ninety) days from receiving such a request.
              </p>
              <p className="flex mb-3 body-md text-T03 text-justify">
                <div className='mr-2'>3.</div>Withdrawal of consent and Restriction on or Objection to processing
              </p>
              <p className="mb-0 body-md text-T03 text-justify">
                You are entitled to withdraw your consent for use of your Personal Information, object to us processing your Personal Information in a particular manner and to restrict us form temporarily or permanently processing all or a part of your Personal Information by sending your request at <a className='text-text_primary' href='mailto:ojasvita@CargoXB.com'>ojasvita@CargoXB.com</a>. We would appreciate it if your request is accompanied by the reason for such request. However, such withdrawal shall not disentitle us from processing your Personal Information that was collected before a request for withdrawal was placed or from processing Personal Information, which we, under applicable law, are allowed to process.
              </p>
              <p className="mb-0 body-md text-T03 text-justify">
                (Please note – (i) the exercise of any such right shall prevent you from taking full advantage of the entire scope of features and Services offered through the Platform we reserve the right not to provide you with our Services.
              </p>
              <p className="mb-3 body-md text-T03 text-justify">
                (ii) If you have entered in any transaction, and you place a request for withdrawal of your consent or object to processing of your Personal Information or wish to restrict us from processing such Personal Information, while such transaction is ongoing, such request shall not be processed for the Personal Information that is required to facilitate that particular transaction until the said transaction completes.
              </p>
              <p className="mb-0 body-md text-T01 font-semibold">
                A. Compliance with laws and law enforcement
              </p>
              <p className="mb-3 body-md text-T03 text-justify">
                We will cooperate with government and law enforcement officials and private parties to enforce and comply with the law. We reserve the right to track IP addresses for the purposes of fraud prevention and to release IP addresses to legal authorities. We will disclose information about you to government or law enforcement officials or private parties if it is reasonably necessary to comply with the law, to protect the property and rights of CargoXB or a third party, to protect the safety of the public or any person, or to prevent or stop activity which we may consider to be, or to pose a risk of being, any illegal, unethical or legally actionable activity.
              </p>
              <p className="mb-0 body-md text-T01 font-semibold">
                B. Storage, Retention and Deletion of Personal Information
              </p>
              <p className="mb-0 body-md text-T03 text-justify">
                Storage: We securely store your Personal Information in our databases located in India or outside India as per applicable laws and employ reasonable security measures to protect such Information as mentioned above.
              </p>
              <p className="mb-7 body-md text-T03 text-justify">
                Retention: The information provided to us by the User shall be retained by us to give effect to the objective for which it was collected, to effectively provide the Services to our Users and subject to the purposes mentioned in this Policy and our Terms for which such retention is necessary. Further, we may, even after the termination or deletion of the account of a User, continue to retain such information if it is reasonably necessary to comply with legal obligations, meet regulatory requirements, resolve disputes between Users, prevent fraud, or for any other use.
              </p>
            </div>

            <div>
              <p className="mb-7 body-md text-T03 text-justify">
                Deletion: Unless legally allowed or mandated to store such Personal Information, upon receiving a request at <a className='text-text_primary' href='mailto:ojasvita@CargoXB.com'>ojasvita@CargoXB.com</a>. We will delete all your Personal Information or anonymize it so that such data is no longer identifiable to you as an individual.
              </p>
            </div>

            <div>
              <p className="mb-7 body-md text-T03 text-justify">
                We reserve an unqualified right to delete the Personal Information of any User who is found to have indulged in any fraudulent activity concerning our Platform or our Services and of such Users who are prohibited by law applicable to them, to access our Platform or use our Services.
              </p>
            </div>

            <div>
              <div className='flex body-md mb-3 text-T01 font-semibold'><div className='pr-1'> 1.</div>Transfer of Data</div>
              <p className="mb-3 body-md text-T03 text-justify">
                Your information, including Personal Information, may be moved to and preserved on computers situated outside of your state, province, country or other governmental jurisdiction where the data protection laws may vary than those from your jurisdiction.
              </p>
              <p className="mb-7 body-md text-T03 text-justify">
                A prudent degree of safeguard mechanism is in place to ensure the safety of data while it is being transferred. We ensure that compliance with applicable data protection laws is followed. Your consent to this Policy followed by your submission of Personal Information represents your agreement to that transfer.
              </p>
            </div>

            <div>
              <div className='flex body-md text-T01 font-semibold mb-3 '><div className='pr-1'> 2.</div>Changes to the Policy</div>
              <p className="mb-7 body-md text-T03 text-justify">
                This Policy may get updated from time to time pursuant to changes in applicable law, the scope of our Services, or otherwise. The date mentioned at the top of this Policy shall reflect the date on which the Policy was last revised. We may or may not inform the Users of any material amendments to this Policy by posting the updated Policy on our Platform. We encourage you to review this Policy regularly for any such changes. Your continued use of the Services will be subject to the then current Policy.
              </p>
            </div>

            <div>
              <div className='flex body-md text-T01 font-semibold mb-3'><div className='pr-1'> 3.</div>Protection of Children</div>
              <p className="mb-3 body-md text-T03 text-justify">
                TWe do not intentionally collect information from minors or market to them and by using the Services, you certify that you are not a minor. If we become aware that we have obtained Personal Information from a minor, we will promptly deactivate the relevant account and take reasonable steps to ensure that such Personal Information is removed from our system within reasonable time and withdrawable balance, if any, in such minor’s account, who has created an account on the Platform, misrepresenting himself to be an adult,  shall be refunded to such minor’s  guardian’s bank account within 15 (fifteen) days of us receiving the relevant payment details.
              </p>
            </div>
            <p className="mb-7 body-md text-T03 text-justify">
              Please note- A person shall be considered to be a minor if the law applicable to such person describes or designates such person as a minor or incapable to contract, owing to such person’s age. For India, any person below the age of 18 (eighteen) shall be considered to be a minor.
            </p>

            <div>
              <div className='flex body-md text-T01 font-semibold mb-3'><div className='pr-1'> 4.</div>Do Not Track Feature</div>
              <p className="mb-7 body-md text-T03 text-justify">
                The Do-Not-Track ("DNT") function or setting can be turned on in web browsers, some mobile operating systems, and mobile applications to indicate that a User prefers not to have information about his/her online browsing activity tracked and collected. There is currently no established common technical standard for identifying and utilising DNT signals. As a result, we presently do not react to DNT browser signals or any other system that automatically transmits your preference not to be tracked online. In the event that an online tracking standard is later adopted that we are required to abide by, we will update this Policy to include information about that practise subject to customer demand.
              </p>
            </div>

            <div className='mb-7'>
              <div className='flex body-md text-T01 font-semibold mb-3'><div className='pr-1'> 5.</div>Severability And Exclusion</div>
              <p className="mb-3 body-md text-T03 text-justify">
                We have taken every effort to ensure that this Policy adheres to the applicable laws. The invalidity or unenforceability of any part of this Policy shall not prejudice or affect the validity or enforceability of the remainder of this Policy. This Policy does not apply to any information other than the Personal Information collected by us through the Platform. This Privacy Policy shall be inapplicable to any unsolicited information you provide us through the Platform or through any other means or through public channels. This includes, but is not limited to, information posted in any public areas of the Platform. All unsolicited information shall be deemed to be non-confidential and We shall be free to use and/ or disclose such unsolicited information without any limitations.
              </p>
              <p className="ms-2 mb-0 body-md text-T03 text-justify">
                1. Applicable Law and Dispute Resolution:
              </p>
              <p className="mb-0 body-md text-T03 text-justify">
                This Policy shall be governed by and construed in accordance with Indian laws. All disputes under this Policy shall be referred exclusively to courts at New Delhi India. Any dispute or claim arising out of or relating to this Policy including the determination of the scope or applicability of this Policy, or any information provided thereof, shall be resolved through arbitration conducted by a sole arbitrator appointed by mutual consent of the disputing parties. The arbitration shall be governed by the Arbitration and Conciliation Act, 1996, and the rules made thereunder. The venue of the arbitration shall be New Delhi and the language of such arbitral proceedings and award rendered shall be English. The award delivered pursuant to such arbitral proceedings shall be final and binding on the parties to the dispute.
              </p>
            </div>

            <p className="mb-7 body-md text-T03 text-justify">
              Notwithstanding anything contained herein above, every party to a dispute shall have the right to seek interim relief from a court of competent jurisdiction in New Delhi, India to safeguard the rights of such party pending any arbitration proceeding initiated.
            </p>

            <div className='flex body-md font-medium mb-3'><div className='pr-1'> 14.</div>Contact Information</div>
            <p className="mb-3 body-md text-T03 text-justify">
              In case of any complaints or grievances, please contact us by sending your complaint containing the following information to our Grievance Officer:
            </p>

            <ul className='mb-7 body-md list-inside list-disc text-T03 text-justify'><li>Your name, address, contact number, and email address</li>
              <li>description of the complaint with a clear identification of the Users’ Content in relation to which such complaint is being made</li>
              <li>an acknowledgement by you that all information contained in the complaint is true and accurate.</li>
            </ul>

            <div>
              <p className="mb-0 body-md text-T03 text-justify">
                On receiving the complaint, we reserve the right to investigate and/or take such actions as we may deem appropriate. we may also reach out to you to seek further clarification or assistance with the investigation, or verify the statements made in your complaint and you acknowledge that your timely assistance with the investigation would facilitate the swift redressal of the same.
              </p>
              <p className="mb-0 body-md text-T03 text-justify">
                1. Grievance Officer
              </p>
              <p className="mb-7 body-md text-T03 text-justify">
                In accordance with Information Technology Act 2000 and rules made there under the Consumer Protection (E-Commerce) Rules, 2020, the name and contact details of the Grievance Officer are provided below:
              </p>
            </div>

            <div className='mb-7 text-T03 text-justify'>
              <p className="mb-0 body-md">
                Name: Ojasvita Sharma
              </p>
              <p className="mb-0 body-md">
                E-mail ID: <a className='text-text_primary' href='mailto:ojasvita@CargoXB.com'>ojasvita@CargoXB.com</a>
              </p>
              <p className="mb-0 body-md">
                Phone number: +91 9867039218
              </p>
              <p className="mb-0 body-md">
                Address: Gopal Heights, Unit No.1404, Pitampura, Delhi, India.
              </p>
            </div>

            <p className="mb-3 body-md text-T03">
              1. EU Users
            </p>
            <p className="mb-3 body-md text-T03 text-justify">
              This Policy is GDPR compliant and complies with Article 12,13 and 14 of the GDPR. You also have the right to file a complaint with your local data protection supervisory authority if you reside in the EEA or the UK and feel we are processing your personal information unlawfully.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy

import React, { useState } from 'react';
import SearchIcon from "../assets/icons/Search-icon.svg";
import AccordionSmall from '../components/reusable-components/accordion/AccordionSmall';

const FAQs = () => {
  const [accountRelatedFAQs, setAccountRelatedFAQs] = useState([
    {
      key: 1,
      title: 'How do I sign up for CargoXB and what information is required during the registration process?',
      data: `To sign up for CargoXB, users can register with your phone number and OTP. The 
      registration process typically requires basic information such as contact details and user 
      verification through a one-time password.`,
      isOpen: false,
    },
    {
      key: 2,
      title: "Can I use CargoXB if I'm new to exporting or only have a small business?",
      data: `Yes, CargoXB is designed to cater to both seasoned exporters and those new to the 
      exporting process.`,
      isOpen: false,
    },
    {
      key: 3,
      title: "What type of support is available for users on CargoXB and how can I reach out for assistance?",
      data: `CargoXB provides reliable 24/7 support for users navigating the platform. Users can 
      reach out for assistance through the platform's support channels.`,
      isOpen: false,
    },
    {
      key: 4,
      title: "What security measures does CargoXB have in place to protect user data and transactions?",
      data: `While specific security measures may vary, digital platforms typically implement 
      encryption protocols, secure login procedures, and data protection measures to ensure 
      the security of user data and transactions. Users are encouraged to review CargoXB's 
      security policies for detailed information.`,
      isOpen: false,
    },
  ]);

  const [bookingRelatedFAQs, setBookingRelatedFAQs] = useState([
    {
      key: 1,
      title: 'How does CargoXB help me find the right freight forwarder for my cargo shipments?',
      data: `CargoXB connects users with freight forwarders directly, providing instant prices. The 
      platform offers a choice of freight forwarders, enabling users to compare and choose 
      based on their specific needs.`,
      isOpen: false,
    },
    {
      key: 2,
      title: 'What details do I need to input to check prices on CargoXB and how does the pricing process work?',
      data: `To check prices on CargoXB, users need to enter cargo details such as origin, 
      destination, and weight. The platform then provides instant price listings from different 
      carriers and freight forwarders for both air and ocean shipments.`,
      isOpen: false,
    },
    {
      key: 3,
      title: 'Tell me more about the Click-to-Call feature on CargoXB. How does it enhance communication?',
      data: `Click-to-Call is a feature that enables exporters to initiate voice calls directly from the 
      CargoXB platform. It facilitates more detailed discussions and enhances real-time 
      communication between exporters and freight forwarders.`,
      isOpen: false,
    },
    {
      key: 4,
      title: 'Can you provide insights into the upcoming features of CargoXB such as insurance options and Ship Now Pay Later service?',
      data: `The upcoming features of CargoXB include insurance options, a "Ship Now, Pay Later" 
      (BNPL) service, and on-platform booking. These features aim to enhance the user 
      experience and offer additional services to exporters and other stakeholders.`,
      isOpen: false,
    },
    {
      key: 5,
      title: 'How does CargoXB ensure transparency in the shipping process and are there any hidden fees?',
      data: `CargoXB prides itself on being a transparent platform without hidden fees. Users know 
      exactly what they must pay for cargo shipping once negotiations with the freight 
      forwarder are complete.`,
      isOpen: false,
    },
    {
      key: 6,
      title: 'How can CargoXB connect me with top freight forwarders and what criteria are considered for their selection?',
      data: `CargoXB connects users with experienced freight forwarders who have been in the 
      business for years . The platform considers expertise, experience, and delivery 
      capabilities in selecting its partners.`,
      isOpen: false,
    },
    {
      key: 7,
      title: 'Can I use CargoXB to ship both full container loads (FCL) and less than container loads (LCL)?',
      data: `Yes, CargoXB serves as a one-stop shop for all export and import needs, allowing 
      users to ship both full container loads (FCL) and less than container loads (LCL).`,
      isOpen: false,
    },
    {
      key: 8,
      title: 'How can importers and shipping lines benefit from using CargoXB and what features cater to their needs?',
      data: `Importers and shipping lines can potentially use CargoXB to connect with  freight 
      forwarders, facilitating smoother communication and collaboration throughout the 
      process. The platform provides a comprehensive solution for various stakeholders in the 
      shipping industry.`,
      isOpen: false,
    },
    {
      key: 9,
      title: "Tell me more about the CargoXB global partners and how they contribute to the platform's success.",
      data: `CargoXB's global partners, including OLC Shipping Line Private Limited, Jet Sea 
      Logistics, and others, contribute to the platform's success by providing a wide network 
      and expertise in freight forwarding. These partnerships enhance the platform's reach and
      service capabilities.`,
      isOpen: false,
    },
  ]);

  const toggleAccountAccordions = (accordionkey) => {
    const updatedAccordions = accountRelatedFAQs.map((item) => {
      if (item.key === accordionkey) {
        return { ...item, isOpen: !item?.isOpen };
      } else {
        return { ...item, isOpen: false };
      }
    });

    setAccountRelatedFAQs(updatedAccordions);
  };

  const toggleBookingAccordions = (accordionkey) => {
    const updatedAccordions = bookingRelatedFAQs.map((item) => {
      if (item.key === accordionkey) {
        return { ...item, isOpen: !item?.isOpen };
      } else {
        return { ...item, isOpen: false };
      }
    });

    setBookingRelatedFAQs(updatedAccordions);
  };

  return (
    <div className='FAQs-wrapper min-h-[calc(100vh-650px)]'>
      {/* banner */}
      <div className='bg-M02 pt-24 pb-16'>
        <div className='container'>
          <div className='max-w-3xl mx-auto flex flex-col items-center'>
            <h2 className='heading-h3 mb-7 text-center'>Frequently Asked Questions</h2>
            <div className='w-full flex items-center bg-M04 py-3 md:py-6 px-4.5 md:px-9 rounded-4xl shadow-input'>
              <img src={SearchIcon} alt="" />
              <input className='label-xs pl-3 w-full bg-transparent focus:outline-none' type="text" placeholder='What can we help you find?' />
            </div>
          </div>
        </div>
      </div>

      {/* Questionnaires */}
      <div className='container py-16 divide-y divide-S01'>

        {/* account related FAQs */}
        <div className='relative flex flex-col md:flex-row gap-8 md:gap-20 py-10 first:pt-0 last:pb-0'>
          <h4 className='absolute top-0 left-5 label-md min-w-max'>Account Related</h4>
          <div className='container'>
            <div className='2xl:mt-0 lg:mt-20 mt-14 space-y-7 md:space-y-9 max-w-3xl w-full mx-auto'>
              {accountRelatedFAQs?.map((accordion) => (
                <AccordionSmall
                  key={accordion.key}
                  title={accordion.title}
                  data={accordion.data}
                  isOpen={accordion.isOpen}
                  toggleAccordion={() => toggleAccountAccordions(accordion.key)}
                />
              ))}
            </div>
          </div>
        </div>

        {/* Bookings related FAQs */}
        <div className='relative flex flex-col md:flex-row gap-8 md:gap-20 py-10 first:pt-0 last:pb-0'>
          <h4 className='absolute top-10 left-5 label-md min-w-max'>Booking Related</h4>
          <div className='container'>
            <div className='2xl:mt-0 lg:mt-20 mt-14 space-y-7 md:space-y-9 max-w-3xl w-full mx-auto'>
              {bookingRelatedFAQs?.map((accordion) => (
                <AccordionSmall
                  key={accordion.key}
                  title={accordion.title}
                  data={accordion.data}
                  isOpen={accordion.isOpen}
                  toggleAccordion={() => toggleBookingAccordions(accordion.key)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQs;
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from 'redux-persist'

// import slices
import userReducer from "../slices/userDataSlice";
import searchQueryReducer from "../slices/searchQueryDataSlice";
import loadDetialsReduce from "../slices/loadDetailsDataSlice";
import payloadReducer from "../slices/payLoadDataSlice";
import thunk from "redux-thunk";

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ["searchQuery", "loadDetails", "payload"],
};

const rootReducer = combineReducers({
  user: userReducer,
  searchQuery: searchQueryReducer,
  loadDetails: loadDetialsReduce,
  payload: payloadReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);

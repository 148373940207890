import React, { useEffect, useRef, useState } from 'react';
import Transition from '../../../utils/Transition';


const InputForDimension = ({ options, length, width, height, selected, onClick, onChange, required, lengthError, widthError, heightError }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative">
      <div className="flex border border-S01 focus-within:border-M01 rounded-5xl transition-all duration-500" >
        <div className="flex items-center grow">
          <div className="relative">
            <input
              className="px-5 py-3 w-full bg-M04 outline-none border-none rounded-5xl label-xxs text-black"
              placeholder="length"
              value={length}
              name="length"
              onChange={(e) => onChange(e)}
              required={required}
              autoComplete='off'
            />
            <span className="text-red-400 body-xxs absolute top-[100%] left-0">{lengthError}</span>
          </div>

          <div className="h-4.5 border-l border border-M06"></div>

          <div className="relative">
            <input
              className="px-5 py-3 w-full bg-M04 outline-none border-none rounded-5xl label-xxs text-black"
              placeholder="width"
              value={width}
              name="width"
              onChange={(e) => onChange(e)}
              required={required}
              autoComplete='off'
            />
            <span className="text-red-400 body-xxs absolute top-[100%] left-0">{widthError}</span>
          </div>

          <div className="h-4.5 border-l border border-M06"></div>

          <div className="relative">
            <input
              className="px-5 py-3 w-full bg-M04 outline-none border-none rounded-5xl label-xxs text-black"
              placeholder="height"
              value={height}
              name="height"
              onChange={(e) => onChange(e)}
              required={required}
              autoComplete='off'
            />
            <span className="text-red-400 body-xxs absolute top-[100%] left-0">{heightError}</span>
          </div>
        </div>
        <button
          ref={trigger}
          className="flex items-center justify-center gap-1 body-xxs font-medium text-T01 rounded-e-5xl bg-M07 px-2"
          onClick={(e) => {
            e.preventDefault();
            setDropdownOpen(!dropdownOpen);
          }}
        >
          {selected?.name}
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
            <path d="M1 0.5L6 5.5L11 0.5" stroke="#202020" strokeWidth="1.2" />
          </svg>
        </button>
      </div>
      {options?.length !== 0 &&
        <Transition
          show={dropdownOpen}
          tag="div"
          className="z-10 absolute top-full left-0 w-full bg-white border border-S01 rounded-3xl p-1 shadow-lg overflow-x-hidden no-scrollbar overflow-y-scroll mt-1 max-h-[11.375rem] "
          enter="transition ease-out duration-100 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-100"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          <div
            ref={dropdown}
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setDropdownOpen(false)}
          >
            {
              options?.map(option => {
                return (
                  <button
                    key={option?.id}
                    tabIndex="0"
                    className={`flex items-center w-full truncate text-left hover:bg-slate-50 py-2 px-3 rounded-3xl cursor-pointer ${option?.id === selected?.id && 'bg-M08'}`}
                    onClick={(e) => {
                      e.preventDefault();
                      onClick(option);
                      setDropdownOpen(false);
                    }}
                  >
                    <svg className={`mr-2 ${option?.id !== selected?.id && 'invisible'}`} xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                      <path d="M11.3332 1L3.99984 8.33333L0.666504 5" stroke="#334155" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <p className={`${option?.id === selected?.id ? 'body-xxs font-medium' : 'body-xxs'}`}>{option?.name}</p>
                  </button>
                )
              })
            }
          </div>
        </Transition>}
    </div>
  );
}

export default InputForDimension;
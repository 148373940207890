import React, { useContext } from 'react';
import Logo from "../../assets/images/Logo.svg"
import { Link, useNavigate } from 'react-router-dom';
import ButtonLogin from '../reusable-components/buttons/ButtonLogin';
import { ModalContext, SidebarModalContext } from '../../App';
import { useDispatch, useSelector } from 'react-redux';
import { clearUserDetails, getUserData } from '../../redux/slices/userDataSlice';
import useLogout from '../../network/hooks/useLogout';
import { getSearchQueryData, setSearchQueryData } from '../../redux/slices/searchQueryDataSlice';
import { setLoadDetailsData } from '../../redux/slices/loadDetailsDataSlice';

const Header = () => {
  const { request: logout, isLoading: logoutLoading } = useLogout();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const getModalData = useContext(ModalContext);
  const userData = useSelector(getUserData);
  const searchQueryData = useSelector(getSearchQueryData);

  const getSidebarModalData = useContext(SidebarModalContext);
  const { showExpand, setShowExpand } = getSidebarModalData;

  const handleLogout = async () => {
    const { data, error } = await logout(userData);
    if (data && data.success) {
      dispatch(clearUserDetails());
      dispatch(setSearchQueryData({
        ...searchQueryData,
        type: "ocean",
        origin_search: "",
        origin: {
          id: null,
        },
        destination_search: "",
        destination: {
          id: null,
        },
        container_type: "lcl",
        counter: 0,
      }));
      dispatch(setLoadDetailsData([
        {
          id: Math.floor(Math.floor(Math.random() * 9000000000) + 1),
          package_type: {
            id: 1,
            name: "Boxes",
            is_active: 1,
          },
          dimensions: {
            length: "",
            width: "",
            height: "",
            unit: {
              id: 1,
              name: "IN",
            },
          },
          weight_per_unit: "",
          weight_unit: {
            id: 1,
            name: "KG",
          },
          total_units: "",
        }
      ]));
      navigate("/")
    }
    if (error) {
      window.alert(error?.response?.data?.message);
    }
  };

  return (
    <header>
      <div className="flex items-center justify-between 2xl:px-24 md:px-9 px-5 py-5">
        <Link to="/">
          <img src={Logo} alt="CargoXB Logo" />
        </Link>

        <ul className="hidden lg:flex items-center justify-evenly w-1/2">
          <li><Link to="/blogs" className="block body-xs text-T02 hover:text-M01 transition-all duration-200 hover:font-semibold hover:-translate-y-[2px]">Blogs</Link></li>
          <li><Link to="/FAQs" className="block body-xs text-T02 hover:text-M01 transition-all duration-200 hover:font-semibold hover:-translate-y-[2px]">FAQ’s</Link></li>
          <li><Link to="/our-partners" className="block body-xs text-T02  hover:text-M01 transition-all duration-200 hover:font-semibold hover:-translate-y-[2px]">Our Partners</Link></li>
          <li><Link to="/contact-us" className="block body-xs text-T02 hover:text-M01 transition-all duration-200 hover:font-semibold hover:-translate-y-[2px]">Contact us</Link></li>
          <li><Link to="/about-us" className="block body-xs text-T02 hover:text-M01 transition-all duration-200 hover:font-semibold hover:-translate-y-[2px]">About us</Link></li>
        </ul>

        {!userData ?
          <div className='hidden lg:block'>
            <ButtonLogin
              text="Register / Login"
              onClick={() => {
                getModalData?.setModalType("LOGIN");
                getModalData?.setModalOpen(true);
              }}
            />
          </div>
          :
          <div className='hidden lg:flex items-center justify-center gap-2'>
            {/* credits UI */}
            <div className='hidden lg:flex items-center justify-center gap-1 bg-T05 rounded-4xl p-3'>
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M3.90856 3.54416H18.4514C18.6212 3.54408 18.791 3.55486 18.9595 3.57643C18.9023 3.17549 18.7646 2.79027 18.5546 2.44399C18.3446 2.09772 18.0666 1.79756 17.7375 1.56161C17.4083 1.32567 17.0348 1.15883 16.6394 1.07114C16.244 0.983461 15.835 0.976755 15.4369 1.05143L3.47682 3.09333H3.46319C2.71245 3.2369 2.04484 3.66165 1.59671 4.28084C2.27186 3.80062 3.08005 3.54309 3.90856 3.54416ZM18.4514 4.63487H3.90856C3.13742 4.63571 2.39811 4.94242 1.85283 5.4877C1.30755 6.03298 1.00084 6.77229 1 7.54343V16.2691C1.00084 17.0403 1.30755 17.7796 1.85283 18.3249C2.39811 18.8701 3.13742 19.1768 3.90856 19.1777H18.4514C19.2225 19.1768 19.9618 18.8701 20.5071 18.3249C21.0524 17.7796 21.3591 17.0403 21.3599 16.2691V7.54343C21.3591 6.77229 21.0524 6.03298 20.5071 5.4877C19.9618 4.94242 19.2225 4.63571 18.4514 4.63487ZM16.2927 13.3606C16.005 13.3606 15.7239 13.2753 15.4847 13.1155C15.2456 12.9557 15.0592 12.7285 14.9491 12.4628C14.839 12.1971 14.8102 11.9047 14.8663 11.6226C14.9224 11.3405 15.061 11.0813 15.2643 10.8779C15.4677 10.6746 15.7269 10.536 16.009 10.4799C16.2911 10.4238 16.5835 10.4526 16.8492 10.5627C17.1149 10.6728 17.3421 10.8592 17.5019 11.0983C17.6617 11.3375 17.747 11.6186 17.747 11.9063C17.747 12.292 17.5937 12.6619 17.321 12.9346C17.0483 13.2073 16.6784 13.3606 16.2927 13.3606Z" fill="white" />
                  <path d="M1.02344 10.6117V6.08978C1.02344 5.10496 1.56879 3.45389 3.46163 3.09623C5.06816 2.79492 6.65878 2.79492 6.65878 2.79492C6.65878 2.79492 7.70404 3.52206 6.84056 3.52206C5.97708 3.52206 5.9998 4.6355 6.84056 4.6355C7.68132 4.6355 6.84056 5.70348 6.84056 5.70348L3.45481 9.54369L1.02344 10.6117Z" fill="white" />
                </svg>
              </div>
              <div className='body-xs text-white'>
                {userData?.credits} Credits
              </div>
            </div>

            {/* logout button */}
            <div className='hidden lg:block'>
              <ButtonLogin
                text="Logout"
                onClick={() => handleLogout()}
                loading={logoutLoading}
              />
            </div>
          </div>
        }

        <button
          className={"relative z-50 h-10 w-10 text-white focus:outline-none block lg:hidden "}
          onClick={() => setShowExpand(!showExpand)}
        >
          <div className="absolute left-1/2 top-1/2 flex w-7 -translate-x-1/2 -translate-y-1/2 transform gap-5">
            <div
              className={` absolute h-0.5 w-7 transform transition duration-300 ease-in-out rounded-[2px] ${showExpand ? "rotate-45 delay-200 bg-white" : "-translate-y-2 bg-T01"
                }`}
            ></div>
            <div
              className={` absolute h-0.5 transform transition-all duration-200 ease-in-out ${showExpand ? "w-0 opacity-50 bg-white" : "w-7 opacity-100 delay-200 bg-T01"
                }`}
            ></div>
            <div
              className={`absolute h-0.5 w-7 transform transition duration-300 ease-in-out ${showExpand ? "-rotate-45 delay-200 bg-white" : "translate-y-2 bg-T01"
                }`}
            ></div>
          </div>
        </button>
      </div>
    </header>
  )
}

export default Header;
import { useCallback, useState } from "react";
import { getBlogs } from "../api";

const useGetBlogs = () => {
  const [isLoading, setLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const request = useCallback(async (page_number) => {
    setLoading(true);
    const { data, error } = await getBlogs(page_number);
    setLoading(false);

    return { data, error };
  });

  return { isLoading, request };
};

export default useGetBlogs;

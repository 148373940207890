import React from 'react'
import Modal from '../reusable-components/modal/Modal';
import Chart from "../../assets/images/Login chart.png"
import InputBasic from '../reusable-components/inputfields/InputBasic';
import Checkbox from '../reusable-components/checkbox/Checkbox';
import ButtonPrimary from '../reusable-components/buttons/ButtonPrimary';


const RegisterPopup = ({ open, setOpen, callback }) => {
  return (
    // <ModalBasic id="basic-modal" open={open} setOpen={setOpen} title="Basic Modal" maxWidth="max-w-[800px]">
    <Modal open={open} onClose={() => setOpen(false)} maxWidth="max-w-[800px]">
      <div className='flex items-center bg-white rounded-3xl'>
        {/* left InfoBox */}
        <div className='flex-1 hidden lg:flex flex-col items-center p-7'>
          <h3 className='body-md text-center mb-6'>Lets Grow Together!</h3>

          <div className='mb-14'><img src={Chart} alt="" /></div>

          {/* pagination */}
          <div className='flex items-center justify-center gap-2'>
            <div className={`w-3 h-3 rounded-full bg-M01`}></div>
          </div>
        </div>

        {/* right Login form */}
        <div className='flex-1 pt-14 px-7 pb-7 border-l border-S02' >
          <h3 className='label-sm mb-6'>Lets Grow Together!</h3>
          {/* name */}
          <div className='mb-3'>
            <label htmlFor='name' className='body-xs mb-2'>Name</label>
            <InputBasic
              id={"name"}
              onChange={(e) => {
              }}
            />
            {/* <div className='text-xs text-red-500'>{getOTPError}</div> */}
          </div>

          {/* email address */}
          <div className='mb-3'>
            <label htmlFor='email' className='body-xs mb-2'>Email Address</label>
            <InputBasic
              id={"email"}
              onChange={(e) => {
              }}
            />
            {/* <div className='text-xs text-red-500'>{getOTPError}</div> */}
          </div>

          {/* company name */}
          <div className='mb-3'>
            <label htmlFor='company_name' className='body-xs mb-2'>Company Name</label>
            <InputBasic
              id={"company_name"}
              onChange={(e) => {
              }}
            />
            {/* <div className='text-xs text-red-500'>{getOTPError}</div> */}
          </div>

          {/* IEC code */}
          <div className='mb-3'>
            <label htmlFor='iec_code' className='body-xs mb-2'>IEC Code</label>
            <InputBasic
              id={"iec_code"}
              onChange={(e) => {
              }}
            />
            {/* <div className='text-xs text-red-500'>{getOTPError}</div> */}
          </div>

          {/* company's pan card */}
          <div className='mb-3'>
            <label htmlFor='pan' className='body-xs mb-2'>Company’s Pan Card</label>
            <InputBasic
              id={"pan"}
              onChange={(e) => {
              }}
            />
            {/* <div className='text-xs text-red-500'>{getOTPError}</div> */}
          </div>

          {/* company's GST no. */}
          <div className='mb-8'>
            <label htmlFor='gst' className='body-xs mb-2'>Company’s GST No.</label>
            <InputBasic
              id={"gst"}
              onChange={(e) => {
              }}
            />
            {/* <div className='text-xs text-red-500'>{getOTPError}</div> */}
          </div>

          <div className='flex items-center gap-1 mb-5'>
            <Checkbox
              // value={TnCs}
              onChange={() => {
                // setTnCs(!TnCs)
              }}
              // checked={TnCs === true}
              name="Tncs"
            />
            <div className='body-xxs cursor-pointer'
            // onClick={() => { setTnCs(!TnCs) }}
            >
              I agree to CargoXB’s Terms of Use, and Privacy Policy
            </div>
          </div>

          <ButtonPrimary
            text="Get OTP"
            additionalClasses="w-full"
          // disabled={handleGetOTPDisabled()}
          // loading={getOTPLoading}
          // onClick={() => handleGetOTP()}
          />
        </div>
      </div>
    </Modal>
    // </ModalBasic>
  )
}

export default RegisterPopup
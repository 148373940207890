import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const BlogCard = ({ blogsContentData, image, published_at, title, summary, author_name }) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-col rounded-t-3xl max-w-[400px] w-full mx-auto cursor-pointer"
      onClick={() => {
        navigate(`/blogs/${blogsContentData?.slug}`)
      }}
    >
      <div className="rounded-3xl overflow-hidden mb-8 border border-S01">
        <img className='max-w-[400px] w-full h-[200px] sm:h-[250px] object-cover' src={image} alt="Blog" />
      </div>
      <div className="grow flex flex-col justify-between">
        <div className='flex flex-col'>
          <div className="body-xxs text-T08 mb-4">{moment(published_at).format("DD-MMM-YYYY")}</div>
          <h3 className="label-sm text-T09 mb-4">{title}</h3>
        </div>
        <div className='flex flex-col'>
          <div className="body-xxs text-T08 pb-4 mb-4 border-b border-S02">{summary?.substring(0, 100)}{summary && "..."}</div>
          <div className="body-xxs text-T08">By: {author_name}</div>
        </div>
      </div>
    </div >
  )
}

export default BlogCard
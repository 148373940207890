import React, { useContext } from 'react';
import ButtonPrimary from '../../components/reusable-components/buttons/ButtonPrimary';
import ButtonSecondary from '../../components/reusable-components/buttons/ButtonSecondary';
import WhatsAppIcon from "../../assets/icons/whatsapp icon.svg";
import { ModalContext } from '../../App';

const PriceBreakdown = ({ data }) => {
  const getModalData = useContext(ModalContext);

  const getTotalAmount = () => {
    let total_common_charges = 0;
    let total_origin_charges = 0;
    let total_destination_charges = 0;
    let total_other_charges = 0;

    data?.charges?.common_charges?.map((item) => (total_common_charges += item?.price));
    data?.charges?.origin_charges?.map((item) => (total_origin_charges += item?.price));
    data?.charges?.destination_charges?.map((item) => (total_destination_charges += item?.price));
    data?.charges?.other_charges?.map((item) => (total_other_charges += item?.price));

    let total_amount = total_common_charges + total_origin_charges + total_destination_charges + total_other_charges;
    total_amount = total_amount.toLocaleString("en-US");
    return total_amount;
  }

  return (
    <div className="lg:max-w-[29.75rem] w-full">
      <h3 className="body-lg text-T01 mb-3">Price Breakdown</h3>
      <div className="py-8 sm:px-6 px-4.5 bg-M04 rounded-3xl">
        {data?.charges?.common_charges?.map((item) => {
          return (
            <div key={Math.random()} className="pb-4.5 mb-3.5 border-b border-S01 flex items-center justify-between gap-2">
              <span className="label-xxs text-T01">{item?.name}</span>
              <span className="label-xxs text-T01">{item?.currency === "USD" ? "$" : "₹"}{item?.price}</span>
            </div>
          )
        })}
        <div className="pb-4.5 mb-3.5 border-b border-S01">
          <h4 className="label-xxs text-T01 mb-3.5">Origin Charges</h4>
          {data?.charges?.origin_charges?.map((item) => {
            return (
              <div key={Math.random()} className="mb-3.5 last:mb-0 flex items-center justify-between gap-2">
                <span className="body-xs text-T03">{item?.name}</span>
                <span className="label-xxs text-T01">{item?.currency === "USD" ? "$" : "₹"}{item?.price}</span>
              </div>
            )
          })}
        </div>
        <div className="pb-4.5 mb-3.5 border-b border-S01">
          <h4 className="label-xxs text-T01 mb-3.5">Destination Charges</h4>
          {data?.charges?.destination_charges?.map((item) => {
            return (
              <div key={Math.random()} className="mb-3.5 last:mb-0 flex items-center justify-between gap-2">
                <span className="body-xs text-T03">{item?.name}</span>
                <span className="label-xxs text-T01">{item?.currency === "USD" ? "$" : "₹"}{item?.price}</span>
              </div>
            )
          })}
        </div>
        <div className="">
          <h4 className="label-xxs text-T01 mb-3.5">Other Charges</h4>
          {data?.charges?.other_charges?.map((item) => {
            return (
              <div key={Math.random()} className="mb-3.5 last:mb-0 flex items-center justify-between gap-2">
                <span className="body-xs text-T03">{item?.name}</span>
                <span className="label-xxs text-T01">{item?.currency === "USD" ? "$" : "₹"}{item?.price}</span>
              </div>
            )
          })}
        </div>
        <div className="pt-4.5 mt-3.5 border-t border-S01">
          <div className="flex items-center justify-between mb-6">
            <h3 className="label-lg text-T01">Total </h3>
            <span className="label-lg text-T01">{data?.charges?.common_charges[0]?.currency === "USD" ? "$" : "₹"}{getTotalAmount()}</span>
          </div>
          <div className="flex items-center gap-3">
            {/* Book Now */}
            <div className='grow'>
              <ButtonPrimary
                text="Book now"
                additionalClasses="w-full"
                onClick={() => {
                  getModalData.setModalType("BOOKNOW");
                  getModalData.setModalOpen(true);
                }}
              />
            </div>

            {/* WhatsApp */}
            <ButtonSecondary
              icon={WhatsAppIcon}
              borderColor="border-S09"
              size="large"
              onClick={() => {
                getModalData.setModalType("WHATSAPPNOW");
                getModalData.setModalOpen(true);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PriceBreakdown;
import React from 'react';
import ButtonPrimary from '../components/reusable-components/buttons/ButtonPrimary';
import OnLineCargoLogo from "../assets/images/on line cargo logo.svg";
import EshnamLogo from "../assets/images/esham logo.svg";
import SciensLogo from "../assets/images/Sciens.svg";
import WideairLogo from "../assets/images/Wideair.svg";
import UnifreightLogo from "../assets/images/Unifreight.svg";

import OurPartnersCard from '../partials/ourpartners-page/OurPartnersCard';
import { useNavigate } from 'react-router-dom';


const OurPartnersList = [
  {
    logo: OnLineCargoLogo,
    name: "OLC Shipping Line",
    description: "OLC, an established NVOCC with global reach, handles 10,000 containers annually with its 30 staff and 500 affiliates. Led by a shipping veteran, OLC's management offers extensive industry expertise.",
    website_link: "https://www.onlinecargo.org/",
  },
  {
    logo: EshnamLogo,
    name: "Eshnam Cargo Logistics",
    description: "Eshnam Cargo Logistics is a premier international freight forwarder, delivering comprehensive air, sea, road, rail, and express solutions by a team of experienced professionals.",
    website_link: "https://eshnamcargologistics.com/",
  },
  {
    logo: WideairLogo,
    name: "Wideair Cargo",
    description: "Established in 2017, Wideair Cargo excels in global cargo services, ensuring timely and safe delivery with a robust network across major gateways like the USA, UK, Europe, and Asia, focusing on customer satisfaction and efficient logistics solutions.",
    website_link: "https://www.wideairgroup.com/",
  },
  {
    logo: UnifreightLogo,
    name: "Unifreight Mover",
    description: "Established in 2001, Unifreight Movers Pvt. Ltd. in New Delhi excels in cargo services, focusing on forwarding and brokerage, emphasizing efficient, innovative, and cost-effective solutions for global and domestic clientele.",
    website_link: "https://www.unifreightmovers.com/",
  },
  {
    logo: SciensLogo,
    name: "Sciens Logistics",
    description: "Science Logistics: Your trusted partner for specialized scientific logistics. Expertise in temperature control, secure packaging, regulatory compliance, and more. Let us support your research breakthroughs.",
    website_link: "https://www.scienslogistics.com/",
  },
]

const OurPartners = () => {
  const navigate = useNavigate();

  return (
    <div className='contact-us-wrapper min-h-[calc(100vh-650px)]'>
      {/* Banner */}
      <div className='bg-M02 pt-20 pb-9'>
        <div className="container flex flex-col justify-center items-center">
          <h2 className='heading-h3 mb-7 text-center'>Our Partners</h2>
          <p className='body-sm text-center max-w-3xl w-full mx-auto mb-8 text-T06'>Let's reshape shipping together! Join us in making it effortless and accessible for all.</p>
          <ButtonPrimary
            text={"Partner with CargoXB"}
            onClick={() => {
              navigate("/partner-with-us")
            }} />
        </div>
      </div>

      {/* main section */}
      <div className='container grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-x-20 gap-y-14 pt-16 sm:pb-52 pb-32'>
        {OurPartnersList?.map((item) => {
          return (
            <OurPartnersCard
              key={Math.random()}
              logo={item?.logo}
              name={item?.name}
              description={item?.description}
              website_link={item?.website_link}
            />
          )
        })}
      </div>
    </div>
  )
}

export default OurPartners;
import React from 'react'

const ButtonLogin = ({ text, onClick, disabled, aditionalClasses, loading }) => {
  return (
    <button
      className={` ${aditionalClasses} ${disabled && "opacity-70 cursor-not-allowed"} ${!disabled && "hover:bg-M07_dark"} flex gap-2 rounded-4xl bg-M07 px-9.5 py-4 transition-all duration-300 label-xs text-M01`}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {loading &&
        <div>
          <svg className="animate-spin -ml-1 h-5 w-5 text-M01" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      }
      <div>
        {text}
      </div>
    </button>
  )
}

export default ButtonLogin;
import React from 'react';

function Pagination({ currentPage, lastPage, totalRecords, handleGoTo }) {
  return (
    <div className="flex justify-center items-center">
      <nav className="mb-4 sm:mb-0 sm:order-1" role="navigation" aria-label="Navigation">
        <ul className="flex justify-center items-center gap-5">
          <li className="ml-3 first:ml-0">
            <button
              className={`btn bg-white border px-4 py-2 rounded-4xl border-S01 ${currentPage === 1 || totalRecords === 0 ? "cursor-not-allowed text-slate-300" : "text-indigo-500 hover:border-slate-300"}`}
              disabled={currentPage === 1 || totalRecords === 0}
              onClick={() => handleGoTo(currentPage - 1)}
            >
              Previous
            </button>
          </li>

          {totalRecords === 0 ?
            <div>Page <span className="font-medium text-slate-600">0</span> of <span className="font-medium text-slate-600">0</span></div>
            :
            <div>Page <span className="font-medium text-slate-600">{currentPage}</span> of <span className="font-medium text-slate-600">{lastPage}</span></div>
          }

          <li className="ml-3 first:ml-0">
            <button
              className={`btn bg-white border px-4 py-2 rounded-4xl border-S01 ${currentPage === lastPage || totalRecords === 0 ? "cursor-not-allowed text-slate-300" : "text-indigo-500 hover:border-slate-300"}`}
              disabled={currentPage === lastPage || totalRecords === 0}
              onClick={() => handleGoTo(currentPage + 1)}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default Pagination;

import React from 'react';
import ContactUsForm from '../partials/contact-us-page/ContactUsForm';

const mapStyles = {
  border: "2px solid rgba(230, 226, 240, 0.40)",
  borderRadius: "24px",
  width: "100%",
  height: "100%",
  minHeight: "400px",
};

const ContactUs = () => {
  return (
    <div className='contact-us-wrapper min-h-[calc(100vh-650px)]'>
      {/* Banner */}
      <div className='bg-M02 pt-20 pb-9'>
        <div className="container">
          <h2 className='heading-h3 mb-7 text-center'>Have questions, feedback, or suggestions?</h2>
          <p className='body-sm text-center text-T06 max-w-3xl w-full mx-auto'>Contact us through the form below - we're eager to hear on how we can better solve for your problems.</p>
        </div>
      </div>

      {/* main section */}
      <div className='container pt-16 sm:pb-52 pb-32'>
        <div className='flex flex-col lg:flex-row  gap-20'>
          {/* left */}
          <div className='grow'>
            {/* address section */}
            {/* <div className='flex flex-col gap-5 mb-5'>
              <div className='flex items-center gap-2'>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 18 21" fill="none">
                  <path d="M9 0C11.3869 0 13.6761 0.948211 15.364 2.63604C17.0518 4.32387 18 6.61305 18 9C18 12.074 16.324 14.59 14.558 16.395C13.6755 17.2869 12.7128 18.0956 11.682 18.811L11.256 19.101L11.056 19.234L10.679 19.474L10.343 19.679L9.927 19.921C9.64451 20.0818 9.32505 20.1663 9 20.1663C8.67495 20.1663 8.35549 20.0818 8.073 19.921L7.657 19.679L7.137 19.359L6.945 19.234L6.535 18.961C5.42298 18.2083 4.38707 17.3489 3.442 16.395C1.676 14.588 0 12.074 0 9C0 6.61305 0.948211 4.32387 2.63604 2.63604C4.32387 0.948211 6.61305 0 9 0ZM9 6C8.60603 6 8.21593 6.0776 7.85195 6.22836C7.48797 6.37913 7.15726 6.6001 6.87868 6.87868C6.6001 7.15726 6.37913 7.48797 6.22836 7.85195C6.0776 8.21593 6 8.60603 6 9C6 9.39397 6.0776 9.78407 6.22836 10.1481C6.37913 10.512 6.6001 10.8427 6.87868 11.1213C7.15726 11.3999 7.48797 11.6209 7.85195 11.7716C8.21593 11.9224 8.60603 12 9 12C9.79565 12 10.5587 11.6839 11.1213 11.1213C11.6839 10.5587 12 9.79565 12 9C12 8.20435 11.6839 7.44129 11.1213 6.87868C10.5587 6.31607 9.79565 6 9 6Z" fill="#7D11C8" />
                </svg>
                <span className='block label-xs'>HEIGHTS-1, Netaji Subhash Place, Pitampura, Delhi, 110034</span>
              </div>

              <div className='flex items-center flex-wrap gap-5'>
                <div className='flex items-center justify-center gap-2'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20" fill="none">
                    <path d="M4.38394 0.479124C4.90904 0.320805 5.47252 0.347107 5.98059 0.553653C6.48865 0.760198 6.91065 1.13453 7.17634 1.61432L7.26514 1.79312L8.05954 3.55832C8.30063 4.09411 8.37889 4.68894 8.28458 5.26885C8.19028 5.84876 7.92757 6.38814 7.52914 6.81992L7.36954 6.97832L6.11794 8.14592C5.89234 8.35952 6.06154 9.18633 6.87754 10.5999C7.61194 11.8719 8.20954 12.4659 8.50354 12.4983H8.55514L8.61874 12.4863L11.0787 11.7339C11.4094 11.6325 11.7622 11.6285 12.095 11.7224C12.4278 11.8162 12.7266 12.004 12.9555 12.2631L13.0647 12.4011L14.6931 14.6571C15.0123 15.0993 15.1713 15.637 15.144 16.1817C15.1168 16.7263 14.9048 17.2454 14.5431 17.6535L14.3967 17.8047L13.7463 18.4215C13.1623 18.9746 12.4317 19.3484 11.6414 19.4984C10.8511 19.6483 10.0344 19.5682 9.28834 19.2675C6.96634 18.3315 4.85674 16.1931 2.94034 12.8739C1.02034 9.54633 0.222337 6.64592 0.578737 4.16192C0.686586 3.41104 0.995786 2.70339 1.4735 2.11412C1.95121 1.52485 2.5796 1.07596 3.29194 0.815124L3.52354 0.738324L4.38394 0.479124Z" fill="#7D11C8" />
                  </svg>
                  <span className='block label-xs'>+91 22 1234 5678</span>
                </div>

                <div className='flex items-center justify-center gap-2'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M19 4H5C4.20435 4 3.44129 4.31607 2.87868 4.87868C2.31607 5.44129 2 6.20435 2 7V17C2 17.7956 2.31607 18.5587 2.87868 19.1213C3.44129 19.6839 4.20435 20 5 20H19C19.7956 20 20.5587 19.6839 21.1213 19.1213C21.6839 18.5587 22 17.7956 22 17V7C22 6.20435 21.6839 5.44129 21.1213 4.87868C20.5587 4.31607 19.7956 4 19 4ZM19 6L12.5 10.47C12.348 10.5578 12.1755 10.604 12 10.604C11.8245 10.604 11.652 10.5578 11.5 10.47L5 6H19Z" fill="#7D11C8" />
                  </svg>
                  <a href='mailTo:info@cargoxb.com' className='block label-xs'>info@cargoxb.com</a>
                </div>
              </div>
            </div> */}

            {/* map box */}
            <div>
              <iframe
                title="map location"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3499.909840801895!2d77.14904177439841!3d28.692343481364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d02349da55555%3A0x85c85e2046bc4583!2sCelerity%20Consulting%20Pvt.%20Ltd!5e0!3m2!1sen!2sin!4v1691994454595!5m2!1sen!2sin"
                style={mapStyles}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade">
              </iframe>
            </div>
          </div>

          {/* right */}
          <div className='w-full xl:max-w-lg lg:max-w-md max-w-full mx-auto'>
            <ContactUsForm />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactUs;
import React from 'react';

const Accordion = ({ toggleAccordion, title, isOpen, data }) => {
  return (
    <>
      <div className='py-8 px-1'>
        {/* Question */}
        <div className="flex justify-between items-center cursor-pointer" onClick={toggleAccordion}>
          <div className='body-lg'>{title}</div>
          <svg className="shrink-0 ml-4" width="16" height="16" xmlns="http://www.w3.org/2000/svg" fill='#7D11C8'>
            <rect y="7" width="16" height="2" rx="1" className={`ttransform origin-center transition duration-200 ease-out ${isOpen && '!rotate-180'}`} />
            <rect y="7" width="16" height="2" rx="1" className={`transform origin-center rotate-90 transition duration-200 ease-out ${isOpen && '!rotate-180'}`} />
          </svg>
        </div>

        {/* answer */}
        <div className={`grid overflow-hidden transition-all duration-300 ease-in-out ${isOpen ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'}`}>
          <div className='overflow-hidden'>
            <div className={`pt-8 body-sm text-T06`}>
              {data}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Accordion;
import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const currentDate = moment().format("YYYY-MM-DD");

const initialState = {
  searchQueryData: {
    type: "ocean",
    origin_search: "",
    origin: {
      id: null,
      site_name: "",
      un_lo_code: "",
      city_name: "",
      type: "",
      country_name: "",
      country_code: "",
      region_name: "",
      region_code: "",
      latitude: null,
      longitude: null,
      created_at: null,
      updated_at: null,
    },
    destination_search: "",
    destination: {
      id: null,
      site_name: "",
      un_lo_code: "",
      city_name: "",
      type: "",
      country_name: "",
      country_code: "",
      region_name: "",
      region_code: "",
      latitude: null,
      longitude: null,
      created_at: null,
      updated_at: null,
    },
    shipment_date: currentDate,
    container_type: "lcl",
    counter: 0,
  },
};

const searchQueryDataSlice = createSlice({
  name: "searchQueryData",
  initialState,
  reducers: {
    setSearchQueryData(state, action) {
      state.searchQueryData = action.payload;
    },
  },
});

export const { setSearchQueryData } = searchQueryDataSlice.actions;
export const getSearchQueryData = (state) => state.searchQuery.searchQueryData;

export default searchQueryDataSlice.reducer;

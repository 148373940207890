import React, { useContext } from 'react'
import InfoIcon from "../../assets/icons/Info.svg"
import IconParkLeft from "../../assets/images/icon-park-outline_to-top.svg";
import IconParkRight from "../../assets/images/icon-park-outline_to-top (1).svg";
import ShipDesktop from "../../assets/icons/Ship-Desktop.svg";
import ShipMobile from "../../assets/icons/Ship-Mobile.svg";
import AirplaneDesktop from "../../assets/icons/Airplane-Desktop.svg"
import AirplaneMobile from "../../assets/icons/Airplane-Mobile.svg"
import Time from "../../assets/images/Vector (1).svg";
import ButtonPrimary from '../../components/reusable-components/buttons/ButtonPrimary';
import ButtonSecondary from '../../components/reusable-components/buttons/ButtonSecondary';
import TelephoneIcon from "../../assets/icons/telephone icon.svg"
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getLoadDetailsData } from '../../redux/slices/loadDetailsDataSlice';
import { getSearchQueryData } from '../../redux/slices/searchQueryDataSlice';
import useInitiateCall from '../../network/hooks/useInitiateCall';
import { getUserData } from '../../redux/slices/userDataSlice';
import { ModalContext } from '../../App';
import Tooltip from '../../components/reusable-components/tooltip/Tooltip';
// import PlaceHolderImg from "../../assets/icons/PlaceHolder.svg";
import PlaceHolderImg from "../../assets/icons/favicon.png";

const handleURLData = (data, searchQueryData, loadDetailsData) => {
  return {
    data,
    searchQueryData,
    loadDetailsData,
  };
}

const ListingCard = ({ data }) => {
  const navigate = useNavigate();

  const { request: initiateCall, isLoading: initiateCallLoading } = useInitiateCall();

  const userData = useSelector(getUserData);
  const getModalData = useContext(ModalContext);

  const searchQueryData = useSelector(getSearchQueryData);
  const loadDetailsData = useSelector(getLoadDetailsData);

  const origin = data?.ports?.filter((item) => item?.type === "origin")[0];
  const destination = data?.ports?.filter((item) => item?.type === "destination")[0];


  const handleInitiateCall = async () => {
    const { data, error } = await initiateCall(userData);

    if (data && data.success) {
      getModalData.setModalType("CALLSUCCESS");
      getModalData.setModalOpen(true);
    }

    if (error) {
      window.alert(error?.response?.data?.message);
    }
  };

  const onImageError = (e) => {
    e.target.src = PlaceHolderImg
  }

  return (
    <div className='flex flex-col lg:flex-row gap-5 justify-between items-center border border-S02 shadow-3xl rounded-3xl py-6 px-5 sm:px-8 hover:border-M01 bg-M04'>
      {/* left */}
      <div className='w-full'>
        {/* logo + freight forwarder name */}
        <div className='flex flex-col sm:flex-row gap-2 sm:gap-7 items-start sm:items-center'>
          <div className='flex gap-3.5'>
            <div className='flex'>
              <div>
                <img
                  className='rounded-full border border-S02'
                  width="28px"
                  height="28px"
                  src={data?.carrier?.logo_url ? data?.carrier?.logo_url : PlaceHolderImg}
                  alt=""
                  onError={onImageError}
                />
              </div>
              {/* <div className='-ml-2'>
                <img className='shadow-4xl rounded-full border border-S02' width="28px" height="28px" src="https://www.searates.com/design/images/freight/sealine_cort/7.jpg" alt="" />
              </div> */}
            </div>
            <div className='body-xs text-T01 flex items-center'>{data?.carrier && data?.carrier?.name}</div>
          </div>
          {/* estimated time */}
          <div className='flex lg:hidden items-center'>
            <div>
              <img alt="" src={Time} className='pr-2.5' />
            </div>
            <div className='body-xxs text-T03 pr-1'>Est time</div>
            <div className='label-xxs text-T01 font-medium'>10-12 days</div>
          </div>
        </div>

        {/* origin and destination name */}
        <div className='flex items-center justify-between max-w-[850px] w-full gap-x-5 pt-6'>
          {/* origin */}
          <div>
            <div className='flex items-center'>
              <img alt="" src={IconParkLeft} className='pr-2.5' />
              <span className='body-xxs text-T03'>Origin</span>
            </div>
            <Tooltip message={`${origin?.name}`}>
              <div className='label-xs text-T01 pt-1.5'>
                {origin?.code}
              </div>
            </Tooltip>
          </div>

          {data?.shipping_details?.mode_type === "ocean" ?
            // ship icon 
            < div className='flex gap-x-4 items-center mt-2.5'>
              <img src={ShipDesktop} className="hidden sm:block" alt="" />
              <img src={ShipMobile} className='block sm:hidden' alt="" />
            </div>
            :
            // airplane icon 
            <div className='flex gap-x-4 items-center mt-2.5'>
              <img src={AirplaneDesktop} className="hidden sm:block" alt="" />
              <img src={AirplaneMobile} className='block sm:hidden' alt="" />
            </div>
          }

          {/* destination */}
          <div>
            <div className='flex items-center'>
              <img alt="" src={IconParkRight} className='pr-2.5' />
              <div className='body-xxs text-T03'>Destination</div>
            </div>
            <Tooltip message={`${destination?.name}`}>
              <div className='label-xs text-T01 pt-1.5'>
                {destination?.code}
              </div>
            </Tooltip>
          </div>
        </div>

        {/* estimated time */}
        <div className='hidden lg:flex pt-7 items-center'>
          <div>
            <img alt="" src={Time} className='pr-2.5' />
          </div>
          <div className='body-xxs text-T03 pr-1'>Est time</div>
          <div className='label-xxs text-T01 font-medium'>{data?.shipping_details?.transit_dates?.transit_time} days</div>
        </div>
      </div>

      {/* right for desktop */}
      <div className='hidden sm:flex flex-row lg:flex-col gap-2 justify-between w-full lg:max-w-max'>
        {/* price */}
        <div className='flex order-3 lg:order-1 items-center justify-center gap-1.5 min-w-max'>
          <div className='heading-h4'>
            {data?.charges?.common_charges[0]?.currency === "USD" ? "$" : "₹"}{data?.shipping_details?.net_price}
          </div>
          {/* info */}
          <Tooltip message={`Prices are subject to change.`} left={"left-[-85px]"}>
            <div className=' hidden sm:block'>
              <img src={InfoIcon} alt="" />
            </div>
          </Tooltip>
        </div>

        <ButtonPrimary
          text="View Details"
          size='small'
          additionalClasses="w-full order-1 lg:order-2"
          // onClick={() => { navigate(`/details-page?inquiry_id=${id}?quote_id=${data?.id}`) }}
          onClick={() => { navigate(`/details-page?data=${btoa(JSON.stringify(handleURLData(data, searchQueryData, loadDetailsData)))}`) }}
        />

        <ButtonSecondary
          text="Call to Book"
          aditionalClasses="w-full hidden sm:block order-2"
          onClick={() => handleInitiateCall()}
          loading={initiateCallLoading}
        />
        <ButtonSecondary
          icon={TelephoneIcon}
          aditionalClasses="min-w-max block sm:hidden order-2"
          onClick={() => handleInitiateCall()}
          loading={initiateCallLoading}
        />
      </div>

      {/* right for mobile */}
      <div className='sm:hidden w-full lg:max-w-max'>
        <div className='flex gap-4 mb-3'>
          {/* call to book button */}
          <ButtonSecondary
            text="Call to Book"
            aditionalClasses="w-full"
            onClick={() => handleInitiateCall()}
            loading={initiateCallLoading}
          />

          {/* price */}
          <div className='min-w-max'>
            <div className='heading-h4'>
              {data?.charges?.common_charges[0]?.currency === "USD" ? "$" : "₹"}{data?.shipping_details?.net_price}
            </div>
          </div>
        </div>

        {/* View details button */}
        <ButtonPrimary
          text="View Details"
          size='small'
          additionalClasses="w-full"
          // onClick={() => { navigate(`/details-page?inquiry_id=${id}?quote_id=${data?.id}`) }}
          onClick={() => { navigate(`/details-page?data=${btoa(JSON.stringify(handleURLData(data, searchQueryData, loadDetailsData)))}`) }}
        />
      </div>
    </div >
  )
};

export default ListingCard;
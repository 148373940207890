import CargoXb from "../assets/images/326311-P9ZSKF-298 1.svg"
import Export from "../assets/images/export29 3.svg"
import EfficientCargoXB from "../assets/images/g8.svg"
import AboutUsBanner from "../partials/aboutus-page/AboutUsBanner";
import AboutUsCard from "../partials/aboutus-page/AboutUsCard";
import WhyChooseUs from "../partials/aboutus-page/why-choose-cargoxb/WhyChoseUs";

const AboutUsCardsConstantData = {
    card01: {
        title: "CargoXB: Revolutionizing India’s Export Industry with a Digital Cargo Marketplace",
        paragraph01: "At CargoXB, we are dedicated to empowering both experienced and first-time exporters. Our innovative digital platform provides a seamless connection with a network of trusted freight forwarders, revolutionizing the export process with transparency and accessibility. Our extensive pan-India operations and global partnerships are designed to open up international trading opportunities for businesses of all sizes, allowing them to reach new markets with ease and confidence.",
    },
    card02: {
        title: "CargoXB: Unlocking the Full Potential of Your Business Cash Flows",
        paragraph01: "CargoXB is transforming financial management for exporters, offering timely financing solutions that are essential for business expansion. Leveraging our network of esteemed lending partners, we provide options to discount export and logistics invoices, enabling exporters to expand their reach into new markets without financial limitations constraints.",
    },
    card03: {
        title: "CargoXB: Your Key to Efficient and Affordable Shipping",
        paragraph01: "CargoXB's vision is to empower exporters with the ability to make informed shipping decisions. This includes the flexibility to choose cargo, establish a shipping budget, and set a preferred shipping timeline. On CargoXB, exporters can plan their logistics and effortlessly connect with a compatible freight forwarder who can cater to their specific requirements, ensuring a seamless shipping experience",
    }
}

const AboutUs = () => {
    return (
        <>
            {/* Banner */}
            <AboutUsBanner />

            {/* About Us Cards */}
            <div className="flex flex-col mt-16 gap-16">
                <AboutUsCard
                    image={CargoXb}
                    title={AboutUsCardsConstantData?.card01?.title}
                    paragraph01={AboutUsCardsConstantData?.card01?.paragraph01}
                    paragraph02={AboutUsCardsConstantData?.card01?.paragraph02}
                />

                <AboutUsCard
                    image={Export}
                    title={AboutUsCardsConstantData?.card02?.title}
                    paragraph01={AboutUsCardsConstantData?.card02?.paragraph01}
                    imageOrder="order-1 lg:order-2"
                    textOrder="order-2 lg:order-1"
                />

                <AboutUsCard
                    image={EfficientCargoXB}
                    title={AboutUsCardsConstantData?.card03?.title}
                    paragraph01={AboutUsCardsConstantData?.card03?.paragraph01}
                />
            </div>

            {/* why choose CargoXB */}
            <WhyChooseUs />
        </>
    )
}
export default AboutUs
import React from 'react';
// import EshnamLogo from "../../assets/images/ESHNAM.png";
// import MaerskLogo from "../../assets/images/MAERSK.png";
// import ShipIcon from "../../assets/icons/ship icon.svg";
// import AirplaneDesktop from "../../assets/icons/Airplane-Desktop.svg"
import ShipDesktop from "../../assets/icons/Ship-Desktop.svg";
import ShipMobile from "../../assets/icons/Ship-Mobile.svg";
import AirplaneDesktop from "../../assets/icons/Airplane-Desktop.svg"
import AirplaneMobile from "../../assets/icons/Airplane-Mobile.svg"
import moment from 'moment';
import Tooltip from '../../components/reusable-components/tooltip/Tooltip';
// import PlaceholderImg from "../../assets/icons/Placeholder rect.svg";
import PlaceholderImg from "../../assets/images/Logo.svg"


const BookingSummary = ({ data, loadDetailsData, searchQueryData }) => {
  const origin = data?.ports?.filter((item) => item?.type === "origin")[0];
  const destination = data?.ports?.filter((item) => item?.type === "destination")[0];

  const onImageError = (e) => {
    e.target.src = PlaceholderImg
  }

  return (
    <div className="lg:mb-7">
      <h3 className="body-lg text-T01 mb-3">Booking Summary</h3>
      <div className="sm:px-8 sm:py-8 px-4 py-6 bg-M04 rounded-3xl">
        {/* Shipping Line & Freight Forwarder Logo */}
        <div className="flex justify-evenly sm:justify-center items-center gap-5 sm:mb-16 mb-8">
          {/* <div className="max-w-[10rem] w-full flex justify-center items-center">
            <img
              src={EshnamLogo}
              alt="Logo"
              className="max-w-[10rem] max-h-[4.375rem] mx-auto"
            />
          </div> */}
          <div className="max-w-[10rem] w-full flex justify-center items-center">
            <img
              src={data?.carrier?.logo_url ?? PlaceholderImg}
              alt="Shipping Line Logo"
              className="max-w-[10rem] max-h-[4.375rem] mx-auto"
              onError={onImageError}
            />
          </div>
        </div>

        {/* Origin & Destination */}
        <div className="from-to flex justify-between items-center pb-5 border-b border-S01 gap-2.5">
          <Tooltip message={`${origin?.name}`}>
            <div className="flex text-start">
              <h2 className="label-xs text-T01">{origin?.code}</h2>
            </div>
          </Tooltip>


          <div className="flex flex-col items-center">
            {data?.shipping_details?.mode_type === "ocean" ?
              // ship icon 
              < div className='flex items-center'>
                <img src={ShipDesktop} className="hidden sm:block" alt="" />
                <img src={ShipMobile} className='block sm:hidden' alt="" />
              </div>
              :
              // airplane icon 
              <div className='flex items-center'>
                <img src={AirplaneDesktop} className="hidden sm:block" alt="" />
                <img src={AirplaneMobile} className='block sm:hidden' alt="" />
              </div>
            }
          </div>

          <Tooltip message={`${destination?.name}`}>
            <div className="flex text-end">
              <h2 className="label-xs text-T01">{destination?.code}</h2>
            </div>
          </Tooltip>
        </div>

        {/* Frieght Details */}
        <div className="flex justify-between gap-5">
          <div className="flex flex-col justify-start sm:gap-8 gap-4.5 sm:mt-7 mt-5 text-start">
            {/* <div className="flex flex-col">
              <span className="body-xxs mb-1 text-T03">Freight - Forwarder</span>
              <span className="label-xs text-T01">Eshnam Logistics Pvt Ltd</span>
            </div> */}

            <div className="flex flex-col">
              {searchQueryData?.container_type === "fcl" ?
                <>
                  <span className="body-xxs mb-1 text-T03">Container Type</span>
                  <span className="label-xs text-T01">FCL | {loadDetailsData?.container?.name}</span>
                </>
                :
                <>
                  <span className="body-xxs mb-1 text-T03">Load Details</span>
                  {loadDetailsData?.map((item) => {
                    return (
                      <p key={Math.random()} className="label-xs text-T01">
                        {item?.dimensions?.length} X{" "}
                        {item?.dimensions?.width} X{" "}
                        {item?.dimensions?.height}{" "}
                        {item?.dimensions?.unit?.name}{" "}
                        <span className="text-gray-500">|</span>{" "}
                        {item?.total_units} units
                      </p>
                    )
                  })}
                </>
              }
            </div>

            <div className="flex flex-col">
              <span className="body-xxs mb-1 text-T03">Goods Ready By</span>
              <span className="label-xs text-T01">{moment(data?.shipping_details?.transit_dates?.goods_ready_by).format("DD MMMM,YYYY")}</span>
            </div>
          </div>

          <div className="flex flex-col justify-start sm:gap-8 gap-4.5 sm:mt-7 mt-5 text-start">
            <div className="flex flex-col text-end">
              <span className="body-xxs mb-1 text-T03">Shipping Line</span>
              <span className="label-xs text-T01">{data?.carrier?.name}</span>
            </div>

            <div className="flex flex-col text-end">
              <span className="body-xxs mb-1 text-T03">Est transit time from Port to Port</span>
              <span className="label-xs text-T01">{data?.shipping_details?.transit_dates?.transit_time} days</span>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default BookingSummary;
import React, { useEffect } from 'react';
import Chart from "../../assets/images/Login chart.png"
import InputBasic from '../reusable-components/inputfields/InputBasic';
import { useState } from 'react';
import Checkbox from '../reusable-components/checkbox/Checkbox';
import ButtonPrimary from '../reusable-components/buttons/ButtonPrimary';
import OTPField from '../reusable-components/otp-field/OTPField';
import axios from 'axios';
import useSendOTP from '../../network/hooks/useSendOTP';
import useVerifyOTP from '../../network/hooks/useVerifyOTP';
import Modal from '../reusable-components/modal/Modal';
import { useDispatch } from 'react-redux';
import { saveUserDetails } from '../../redux/slices/userDataSlice';
import { useContext } from 'react';
import { ModalContext } from '../../App';
import { Link } from 'react-router-dom';

const LoginPopup = ({ open, setOpen, callback }) => {
  const { request: getOTP, isLoading: getOTPLoading } = useSendOTP();
  const { request: verifyOTP, isLoading: verifyOTPLoading } = useVerifyOTP();
  const dispatch = useDispatch();
  const getModalData = useContext(ModalContext);

  const [country, setCountry] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [TnCs, setTnCs] = useState(false);
  const [getOTPError, setGetOTPError] = useState("");
  // const [keepLoggedIn, setKeepLoggedIn] = useState(false);
  const [page, setPage] = useState(1);
  const [otp, setOTP] = useState();
  const [loginError, setLoginError] = useState("");

  const handleGetOTP = async () => {
    const { data, error } = await getOTP({
      emailOrPhone: country === "India" ? mobile : email,
      country_code: countryCode,
    });

    if (data && data?.success) {
      setPage(2);
    }
    if (error) {
      setGetOTPError(error?.response?.data?.message);
    }
  };

  const handleOTP = (value) => {
    if (value) {
      setOTP(value);
      setLoginError("");
    }
  };

  const handleLogin = async () => {
    const { data, error } = await verifyOTP({
      emailOrPhone: country === "India" ? mobile : email,
      country_code: countryCode,
      otp: otp,
    });

    if (data && data?.success) {
      dispatch(saveUserDetails(data?.data));
      getModalData?.setModalOpen(false);
      getModalData?.setModalType("");

      callback && callback(data?.data);
    }
    if (error) {
      setLoginError(error?.response?.data?.message);
    }
  };

  const handleGetOTPDisabled = () => {
    if (country === "India") {
      if (mobile?.length < 10 || TnCs === false) {
        return true;
      }
    } else {
      if (!email || TnCs === false) {
        return true;
      }
    }
    return false;
  };

  const handleLoginDisabled = () => {
    if (otp?.length < 4) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    axios.get('https://ipapi.co/json/')
      .then(response => {
        setCountry(response?.data?.country_name);
        setCountryCode(response?.data?.country_code);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  return (
    // <ModalBasic id="basic-modal" open={open} setOpen={setOpen} title="Basic Modal" maxWidth="max-w-[800px]">
    <Modal open={open} onClose={() => setOpen(false)} maxWidth="max-w-[800px]">
      <div className='flex items-center bg-white rounded-3xl'>
        {/* left InfoBox */}
        {page === 1 ?
          <div className='flex-1 hidden lg:flex flex-col items-center pt-14 px-7 pb-7 border-r border-S02'>
            <h3 className='body-md text-center mb-6'>Cargoxb is for exporters and freight forwarders to grow together.</h3>

            <div className='mb-14'><img src={Chart} alt="" /></div>

            {/* pagination */}
            <div className='flex items-center justify-center gap-2'>
              <div className={`w-3 h-3 rounded-full ${page === 1 ? "bg-M01" : "border-2 border-S02"}`}></div>
              <div className={`w-3 h-3 rounded-full ${page === 2 ? "bg-M01" : "border-2 border-S02"}`}></div>
            </div>
          </div>
          :
          <div className='flex-1 hidden lg:flex flex-col items-center pt-14 px-7 pb-7 border-r border-S02'>
            <h3 className='body-sm text-center mb-8'>Get the Best Instant Freight Rates in just 4 Easy Steps with cargoxb.</h3>

            {/* cards */}
            <div className='grid grid-cols-2 gap-x-6 gap-y-4 mb-10'>
              <div>
                <div className='flex items-center justify-center w-6 h-6 rounded-full bg-M01 body-xxs font-medium text-white mb-3'>1</div>
                <div className='body-xxs font-normal border border-S01 rounded-xl px-5 py-7'>Enter your Shipment details</div>
              </div>
              <div>
                <div className='flex items-center justify-center w-6 h-6 rounded-full bg-M01 body-xxs font-medium text-white mb-3'>2</div>
                <div className='body-xxs font-normal border border-S01 rounded-xl px-5 py-7'>Login Using Mobile Number</div>
              </div>
              <div>
                <div className='flex items-center justify-center w-6 h-6 rounded-full bg-M01 body-xxs font-medium text-white mb-3'>3</div>
                <div className='body-xxs font-normal border border-S01 rounded-xl px-5 py-7'>Get Best and instant rates</div>
              </div>
              <div>
                <div className='flex items-center justify-center w-6 h-6 rounded-full bg-M01 body-xxs font-medium text-white mb-3'>4</div>
                <div className='body-xxs font-normal border border-S01 rounded-xl px-5 py-7'>connect & book your shipment </div>
              </div>
            </div>

            {/* pagination */}
            <div className='flex items-center justify-center gap-2'>
              <div className={`w-3 h-3 rounded-full ${page === 1 ? "bg-M01" : "border-2 border-S02"}`}></div>
              <div className={`w-3 h-3 rounded-full ${page === 2 ? "bg-M01" : "border-2 border-S02"}`}></div>
            </div>
          </div>
        }

        {/* right Login form */}
        {page === 1 ?
          // page 1 content 
          <div className='flex-1 pt-14 px-7 pb-7' >
            {country === "India" ?
              <h4 className='label-sm mb-10'>Enter your Mobile Number</h4>
              :
              <h4 className='label-sm mb-10'>Enter your Email Address</h4>
            }

            {country === "India" ?
              // input field for mobile number
              <div className='mb-36'>
                <span className='block body-xs mb-2'>Mobile Number</span>
                <InputBasic
                  placeholder="Enter Mobile Number"
                  value={mobile}
                  maxLength={10}
                  onChange={(e) => {
                    const reg = new RegExp("^[0-9]+$");
                    if (!e.target.value || reg.test(e.target.value)) {
                      setMobile(e.target.value);
                    }
                  }}
                />
                <div className='text-xs text-red-500'>{getOTPError}</div>
              </div>
              :
              // input field for email address
              <div className='mb-36'>
                <span className='block body-xs mb-2'>Email Address</span>
                <InputBasic
                  placeholder="Enter Email Address"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
                <div className='text-xs text-red-700'>{getOTPError}</div>
              </div>
            }

            <div className='flex items-center gap-1 mb-5'>
              <Checkbox
                value={TnCs}
                onChange={() => {
                  setTnCs(!TnCs)
                }}
                checked={TnCs === true}
                name="Tncs"
              />
              <div className='flex items-center gap-1'>
                <div className='body-xxs cursor-pointer' onClick={() => { setTnCs(!TnCs) }}>I agree to CargoXB’s</div>
                <Link to={"/terms-condition"} onClick={() => { setOpen(false) }} className='body-xxs text-blue-700 font-medium'>Terms of Use,</Link>
                <span className='body-xxs'>and</span>
                <Link to={"/privacy-policy"} onClick={() => { setOpen(false) }} className='body-xxs text-blue-700 font-medium'>Privacy Policy</Link>
              </div>
            </div>

            <ButtonPrimary
              text="Get OTP"
              additionalClasses="w-full"
              disabled={handleGetOTPDisabled()}
              loading={getOTPLoading}
              onClick={() => handleGetOTP()}
            />
          </div>
          :
          // page 2 content
          <div className='flex-1 pt-14 px-7 pb-7'>
            {/* change number button */}
            <div
              className='flex items-center gap-2 cursor-pointer mb-8'
              onClick={(e) => { e.stopPropagation(); setPage(1); setMobile(""); setEmail(""); setGetOTPError("") }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
                <path d="M2.26102 3.57148L7.33268 3.57148L7.33268 4.4286L2.26102 4.4286L4.49602 6.72739L3.90685 7.33337L0.666015 4.00004L3.90685 0.666708L4.49602 1.27269L2.26102 3.57148Z" fill="#510D81" />
              </svg>
              <span className='block body-xxs text-M01'>{country === "India" ? "Change Mobile Number" : "Change Email Address"}</span>
            </div>

            <h4 className='body-xs mb-2'>{country === "India" ? `Enter OTP Sent to the Mobile ${mobile}` : `Enter OTP Sent to the Email ${email}`}</h4>

            {/* otp input field */}
            <OTPField otp={otp} setOTP={handleOTP} />

            {/* resend otp */}
            <div className='flex items-center gap-1 mt-4 mb-24'>
              <span className='block body-xxs font-normal'>Haven’t received OTP?</span>
              <button className='body-xxs font-bold text-M01'>Resend</button>
            </div>

            {/* <div className='flex items-center gap-1 mb-5'>
              <Checkbox
                value={keepLoggedIn}
                onChange={() => {
                  setKeepLoggedIn(!keepLoggedIn)
                }}
                checked={keepLoggedIn === true}
              />
              <div className='body-xxs cursor-pointer' onClick={() => { setKeepLoggedIn(!keepLoggedIn) }}>Keep me Logged in</div>
            </div> */}

            <div className='text-xs text-red-500'>{loginError}</div>
            <ButtonPrimary
              text="Login"
              additionalClasses="w-full"
              disabled={handleLoginDisabled()}
              loading={verifyOTPLoading}
              onClick={() => handleLogin()}
            />
          </div>
        }
      </div>
    </Modal>
    // </ModalBasic>
  )
}

export default LoginPopup;
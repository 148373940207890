import React from 'react'

const OurPartnersCard = ({ logo, name, description, website_link }) => {
  return (
    <div className='flex flex-col justify-between items-center max-w-[365px] sm:max-w-max mx-auto'>
      <div className='flex flex-col justify-center items-center'>
        {/* logo */}
        <div className='mb-8 min-h-[70px] flex justify-center items-center'>
          <img src={logo} alt="" />
        </div>

        {/* name */}
        <h4 className='label-xl text-T01 mb-4 text-center'>{name}</h4>

        {/* description */}
        <p className='body-sm text-T06 mb-8 text-center'>{description}</p>
      </div>

      {/* website link */}
      <a href={website_link} target='blank' className='flex items-center justify-center gap-2 px-6 py-2 border border-S01 rounded-4xl hover:bg-M07 transition-all duration-300'>
        <span className='block body-xs text-M01'>Visit Website</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
          <path d="M8.896 3.75537L13.6641 8.52344L8.896 13.2915M13.0018 8.52344L3.33325 8.52344" stroke="#7D11C8" strokeWidth="0.70638" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </a>
    </div>
  )
}

export default OurPartnersCard
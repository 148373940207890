import React from 'react'

const WhyChooseUsCard = ({ image, title, content }) => {
  return (
    <div className="flex flex-col justify-start items-center sm:items-start">
      <div><img src={image} className="w-12 h-14 mb-5" alt="" /></div>
      <div className="label-md  mb-3 text-center sm:text-left">{title}</div>
      <div className="body-sm text-T06 text-center sm:text-left">{content}</div>
    </div>
  )
}

export default WhyChooseUsCard
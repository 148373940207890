import React, { useContext, useEffect, useRef, useState } from 'react';
import ListingCard from '../partials/listings-page/ListingCard';
import DropdownIcon from "../assets/images/Vector Down.svg";
import Close from "../assets/images/ion_close-outline.svg";
import Right from "../assets/images/Vector.svg";
import { useParams } from 'react-router-dom';
import useGetListings from '../network/hooks/useGetListings';
import { useDispatch, useSelector } from 'react-redux';
import { getUserData, updateUserCredits } from '../redux/slices/userDataSlice';
import SearchQuery from '../partials/home-page/search-query/SearchQuery';
import NoQuotesFound from "../assets/images/No quotes Found.png";
import useGetUser from '../network/hooks/useGetUser';
import { ModalContext } from '../App';

const recommendedDropDown = [
  "Recommended",
  "Low to High",
  "High to Low",
];
const ListingPage = () => {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { request: getListing, isLoading: getListingLoading } = useGetListings();
  const { request: getUser } = useGetUser();

  const userData = useSelector(getUserData);
  const getModalData = useContext(ModalContext);

  const [openDropDown, setOpenDropDown] = useState(false);
  const [sortedBy, setSortedBy] = useState("Recommended");
  const [impMsgShow, setImpMsgShow] = useState(true);
  const [listingsData, setListingsData] = useState();
  const [quotesData, setQuotesData] = useState();

  const handleUserCredits = async () => {
    const { data, error } = await getUser(userData);
    if (data && data.success) {
      dispatch(updateUserCredits(data?.data?.credits));
    }
    if (error) {
      window.alert(error?.response?.data?.message);
    }
  };

  const handleGetListings = async () => {
    if (userData?.credits < 1) {
      getModalData.setModalType("CREDITSEXHAUSTED");
      getModalData.setModalOpen(true);
      return;
    }

    const { data, error } = await getListing(id, userData);

    if (data && data.success) {
      handleUserCredits();
      setListingsData(data?.data);
    }
    if (error) {
      window.alert(error?.response?.data?.message);
    }
  }

  useEffect(() => {
    handleGetListings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (sortedBy === "Recommended") {
      setQuotesData(listingsData?.quotes);
    } else if (sortedBy === "Low to High") {
      const data = [...listingsData?.quotes];
      data.sort((a, b) => {
        return a.shipping_details?.net_price - b.shipping_details?.net_price;
      });
      setQuotesData(data);
    } else {
      const data = [...listingsData?.quotes];
      data.sort((a, b) => {
        return b.shipping_details?.net_price - a.shipping_details?.net_price;
      });
      setQuotesData(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingsData, sortedBy]);

  const trigger1 = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!openDropDown || dropdown.current.contains(target) || trigger1.current.contains(target)) return;
      setOpenDropDown(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!openDropDown || keyCode !== 27) return;
      setOpenDropDown(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });


  return (
    <div className='bg-M03'>
      <div>
        <SearchQuery identifier={"from_listings_page"} />
      </div>
      <div className='container'>
        <div className='flex flex-col xl:flex-row justify-center gap-6 py-12'>
          {/* left section listing cards */}
          <div className='grow'>
            <div className="flex items-center justify-between mb-6">
              <p className="mb-0 flex items-center gap-1">
                <span className='body-sm text-T05'>Showing</span> <span className='body-sm font-semibold text-T01'>{listingsData?.quotes ? listingsData?.quotes?.length : 0} out of {listingsData?.quotes ? listingsData?.quotes?.length : 0} Queries</span>
              </p>
              <div
                ref={trigger1}
                onClick={() => {
                  setOpenDropDown(!openDropDown);
                }}
                className="body-sm text-T05 mb-0 flex items-center relative text-nowrap gap-1 cursor-pointer"
              >
                Sort by<b className="body-sm text-T01 mr-1"> {sortedBy}</b>{" "}
                <img
                  className={`${openDropDown ? "rotate-180" : ""} block`}
                  src={DropdownIcon}
                  alt="icon"
                />
                {openDropDown && (
                  <div ref={dropdown} className=" z-50 cursor-pointer border border-[#E9E9E9] rounded-xl shadow-lg top-6 right-0 w-52 absolute pb-1 bg-white  overflow-auto">
                    {recommendedDropDown?.map((item) => (
                      <div
                        key={Math.random()}
                        onClick={() => setSortedBy(item)}
                        className={`py-3 body-xs text-S06 mb-0 flex rounded-2xl m-1 hover:bg-M08  ${sortedBy === item && "font-medium bg-M08"
                          }`}
                      >
                        {sortedBy === item ? <img alt="" src={Right} className='px-2.5' /> : <div className='px-3'></div>} <span className="subheadig">{item}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div>
              {/* {listingsData && listingsData?.quotes?.length ? */}
              {getListingLoading ?
                // loading animation
                <div className="flex items-center justify-center my-20">
                  <div className="relative">
                    <div className="h-24 w-24 rounded-full border-t-8 border-b-8 border-gray-200"></div>
                    <div className="absolute top-0 left-0 h-24 w-24 rounded-full border-t-8 border-b-8 border-M01 animate-spin">
                    </div>
                  </div>
                </div>
                : (!listingsData || listingsData?.quotes?.legth < 1) ?
                  // no qoutes found UI
                  <div className='flex flex-col justify-center items-center'>
                    <img src={NoQuotesFound} alt="" />
                    <div className='label-sm text-center mb-3'>No Quotes founds</div>
                    <div className='max-w-[514px] w-full body-xs text-T03 text-center'>Thank you for your inquiry. We're working on tailored logistics quotes for you. Expect an email soon with our best rates.</div>
                  </div>
                  :
                  // lisitngs UI
                  <div className='space-y-6'>
                    {quotesData?.map((data) => {
                      return (
                        <ListingCard
                          key={Math.random()}
                          data={data}
                        />
                      )
                    })
                    }
                  </div>
              }
            </div>
          </div>

          {/* right section imp msg box */}
          <div className={`xl:w-80 border rounded-3xl border-S02 bg-M04 ${impMsgShow ? "block" : "hidden"} max-h-[350px]`}>
            <div className='p-6'>
              <div className='flex justify-between pb-4'>
                <div className='text-T01 label-xs'>Important </div>
                <img className='cursor-pointer' onClick={() => { setImpMsgShow(false) }} alt="" src={Close} />
              </div>
              <div className='gap-y-4 body-xxs text-T05'>
                <p className="pb-2">Due to global market fluctuations, shipping space and equipment availability may be limited, and rate validity could vary based on the estimated time of departure (ETD).</p>
                <p className="pb-2">Be aware that the current tariffs shown do not include potential peak season General Rate Increases (GRI), Equipment Imbalance Surcharges (EIS/CIC), or other unforeseen surcharges that carriers may impose post-ETD.</p>
                <p>Additionally, for heavy 20'ST containers (&gt;18t), carriers may apply an overweight surcharge, which is not included in standard rates and will be confirmed upon booking.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListingPage;
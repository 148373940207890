import React from 'react';
import EshnamLogo from "../../assets/images/Eshnam grey.svg";
import SciensLogo from "../../assets/images/Sciens grey.svg";
import OnLineCargoLogo from "../../assets/images/On Line Cargo grey.svg";
import WideairLogo from "../../assets/images/Wideair grey.svg";
import UnifreightLogo from "../../assets/images/Unifreight grey.svg";

const companiesList = [
  {
    name: "",
    logo: EshnamLogo,
  },
  {
    name: "",
    logo: SciensLogo,
  },
  {
    name: "",
    logo: OnLineCargoLogo,
  },
  {
    name: "",
    logo: WideairLogo,
  },
  {
    name: "",
    logo: UnifreightLogo,
  },
];

const CompaniesWeWorkWith = () => {
  return (
    <div className="py-14 md:py-24 xl:py-28">
      <div className='container'>
        <div className='flex items-center justify-center flex-col'>
          <h2 className='heading-h1 text-T01 pb-14 md:pb-24'>Companies we work with</h2>
          <div className='flex items-center justify-center flex-wrap gap-8'>
            {companiesList?.map((item) => {
              return (
                <div key={Math.random()} className='flex items-center justify-center rounded-3xl max-w-[284px] w-full h-[120px] bg-[#FAF3FF]'>
                  <img key={Math.random()} alt={item?.name} src={item?.logo} />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompaniesWeWorkWith;
import React from 'react'

const ButtonSecondary = ({
  text,
  onClick,
  disabled,
  aditionalClasses,
  borderColor = "border-S02",
  icon,
  size,
  loading,
}) => {
  return (
    <button
      className={`flex items-center justify-center gap-1 ${aditionalClasses} ${size === "large" ? "px-6 py-3.5" : "px-8 py-2"} ${disabled && "opacity-80 cursor-not-allowed"} ${!disabled && "hover:bg-M06"} flex items-center justify-center gap-2 rounded-4xl bg-M04 border ${borderColor} transition-all duration-300 label-xs text-T01`}
      onClick={() => onClick()}
      disabled={disabled}
    >
      {loading ?
        <div className='flex items-center justify-center'>
          <svg className="animate-spin h-5 w-5 text-M01" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
        :
        <>
          {icon && <div><img src={icon} alt="" /></div>}
          {text && <div>{text}</div>}
        </>
      }
    </button>
  )
}

export default ButtonSecondary
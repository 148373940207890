import React from 'react';
import DropdownClassic from '../../../../../components/reusable-components/drop-downs/DropdownClassic';
import InputBasic from '../../../../../components/reusable-components/inputfields/InputBasic';
import { useDispatch, useSelector } from 'react-redux';
import { getLoadDetailsData, setLoadDetailsData } from '../../../../../redux/slices/loadDetailsDataSlice';
import ButtonPrimary from '../../../../../components/reusable-components/buttons/ButtonPrimary';
import { getSearchQueryData } from '../../../../../redux/slices/searchQueryDataSlice';

const SeaFCLLoadDetails = ({ configData, loadDetailsValid, setLoadDetailsValid, dropdownOpen, setDropdownOpen }) => {
  // const [loadDetails, setLoadDetails] = useState({
  //   container: {
  //     id: 1,
  //     name: "20GP",
  //     is_active: 1,
  //   },
  //   total_units: "",
  // });
  const loadDetailsData = useSelector(getLoadDetailsData);
  const searchQueryData = useSelector(getSearchQueryData);
  // console.log("loadDetails FCL", loadDetailsData)
  const dispatch = useDispatch();

  const handleLoadDetailsValid = () => {
    // check for ocean & lcl
    if ((searchQueryData?.type === "ocean" && searchQueryData?.container_type === "lcl") || searchQueryData?.type === "air") {
      loadDetailsData?.forEach((item) => {
        if (item?.dimensions?.length === "") {
          setLoadDetailsValid(false);
          return;
        } else setLoadDetailsValid(true);

        if (item?.dimensions?.width === "") {
          setLoadDetailsValid(false);
          return;
        } else setLoadDetailsValid(true);

        if (item?.dimensions?.height === "") {
          setLoadDetailsValid(false);
          return;
        } else setLoadDetailsValid(true);

        if (item?.weight_per_unit === "") {
          setLoadDetailsValid(false);
          return;
        } else setLoadDetailsValid(true);

        if (item?.total_units === "") {
          setLoadDetailsValid(false);
          return;
        } else setLoadDetailsValid(true);
      })
    }

    // check for ocean & fcl
    if (searchQueryData?.type === "ocean" && searchQueryData?.container_type === "fcl") {
      if (loadDetailsData?.total_units === "") {
        setLoadDetailsValid(false);
        return;
      } else setLoadDetailsValid(true);
    }

  };

  const handleDisableConfirm = () => {
    let isError = false;

    if (loadDetailsData?.total_units === "") {
      isError = true;
      return isError;
    }
    return isError
  };

  return (
    <div>
      <div className="flex items-center gap-4 my-4.5 px-5">
        <div className="flex-1">
          <span className="body-xs text-T07">Container Type</span>
          <DropdownClassic
            options={configData?.containers}
            onClick={(option) => {
              dispatch(setLoadDetailsData({
                ...loadDetailsData,
                container: option,
              }))
              // setLoadDetails({
              //   ...loadDetailsData,
              //   container: option,
              // })
            }}
            selected={loadDetailsData?.container}
            dropdownPosition="top"
            icon="briefcase"
          />
        </div>
        <div className="flex-1">
          <span className="body-xs text-T07">No. of Units</span>
          <InputBasic
            // placeholder=""
            value={loadDetailsData?.total_units}
            onChange={(e) => {
              const reg = new RegExp("^[0-9]+$");
              if (!e.target.value || reg.test(e.target.value)) {
                dispatch(setLoadDetailsData({
                  ...loadDetailsData,
                  total_units: e.target.value,
                }))
              }
            }}
          />
        </div>
      </div>
      <div className="pt-3 flex items-center justify-end gap-4 border-t border-S02 px-5">
        <ButtonPrimary
          text="Confirm"
          size="small"
          disabled={handleDisableConfirm()}
          onClick={() => {
            setDropdownOpen(false);
            handleLoadDetailsValid();
          }}
        />
      </div>
    </div>
  )
}

export default SeaFCLLoadDetails;
import React from 'react';
// import ModalBasic from '../reusable-components/modal/ModalBasic';
import TelephoneIcon from "../../assets/icons/telephone rounded.svg";
import Modal from '../reusable-components/modal/Modal';
// import Modal2 from '../reusable-components/modal/Modal2';

const CallSuccessPopup = ({ open, setOpen, callBack }) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)} maxWidth="max-w-[465px]">
      <div className='bg-white rounded-3xl p-10'>
        <div className='mb-6'>
          <img className='max-w-[56px] w-full' src={TelephoneIcon} alt="" />
        </div>
        <h5 className='label-sm mb-2'>Call Initiated Successfully!</h5>
        <p className='body-xs'>Thank you for showing interest, we have received your query, we will call you soon. Please ready with your documents and shipment details</p>
      </div>
    </Modal>
  )
}

export default CallSuccessPopup;
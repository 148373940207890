import React from 'react'

const InputBasic = ({ id, name, value, onChange, placeholder, required, maxLength, error }) => {
  return (
    <input
      id={id}
      name={name}
      type="text"
      required={required}
      className={`border ${error ? "border-red-500" : "border-S01 focus:border-M01"}   transition-all duration-500 outline-none rounded-5xl px-3.5 py-3 label-xxs text-black w-full`}
      value={value}
      onChange={(e) => onChange(e)}
      placeholder={placeholder}
      maxLength={maxLength}
    />
  )
}

export default InputBasic;
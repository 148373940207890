import React from 'react'

const Tooltip = ({ message, children, position, left }) => {
  return (
    <div className="group relative flex">
      {children}
      <span className={`absolute whitespace-nowrap ${position === "left" ? "right-0" : "left-0"} ${left} bottom-7 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100`}>{message}</span>
    </div>
  )
}

export default Tooltip;
import React from 'react';
import Sea from "../../../assets/images/sea.svg";
import Warehousing from "../../../assets/images/Warehousing.svg";
import Air from "../../../assets/images/Air.svg";
import Finance from "../../../assets/images/Finance.svg";
import Local from "../../../assets/images/Local.svg";
import Custom from "../../../assets/images/Customer.svg";
import WhyChooseUsCard from './partials/WhyChooseUsCard';

const whyChooseUsCardsList = [
  {
    image: Sea,
    title: "Sea Transport Services",
    content: "Customizable maritime logistics for all cargo types, featuring a user-friendly, adaptable booking system for various container loads.",
  },
  {
    image: Air,
    title: "Air Freight Services",
    content: "Comprehensive air freight platform combining live and contract rates from over 40 airlines, enabling efficient door-to-door and airport-to-airport delivery planning.",
  },
  {
    image: Local,
    title: "Domestic Logistics",
    content: "Efficient domestic distribution via truck and rail, offering GPS-tracked full and partial truck load options for flexible, reliable transport.",
  },
  {
    image: Finance,
    title: "Financing Services",
    content: "Quick, collateral-free export financing, providing up to 90% of invoice value funding within 48 hours through lending partners.",
  },
  {
    image: Custom,
    title: "Custom Clearance",
    content: "Expert assistance for swift and compliant customs clearance, handling all documentation and procedures efficiently.",
  },
  {
    image: Warehousing,
    title: "Warehousing Services",
    content: "Secure, integrated warehousing solutions tailored to enhance shipping operations and facilitate smooth cargo transition.",
  },
];

const WhyChooseUs = () => {
  return (
    <div className='bg-S08'>
      <div className='container'>
        <div className="md:pt-28 pt-14 md:pb-24 pb-14">
          <div className="heading-h3 w-full flex justify-center items-center">Why Choose CargoXB</div>
          <div className="mt-14">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-y-12 gap-x-32">
              {
                whyChooseUsCardsList?.map((item) => {
                  return (
                    <WhyChooseUsCard
                      key={Math.random()}
                      image={item?.image}
                      title={item?.title}
                      content={item?.content}
                    />
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WhyChooseUs;
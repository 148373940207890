import localClient from "./client";

// send OTP
async function sendOTP(body) {
  const headers = {
    Accept: "application/json",
  };

  let data = null;
  let error = null;

  try {
    const response = await localClient.post(`/v1/send-otp`, body, {
      headers,
    });
    data = response.data;
  } catch (e) {
    error = e;
  }

  return { data, error };
};

// verify OTP
async function verifyOTP(body) {
  const headers = {
    Accept: "application/json",
  };

  let data = null;
  let error = null;

  try {
    const response = await localClient.post(`/v1/verify-otp`, body, {
      headers,
    });
    data = response.data;
  } catch (e) {
    error = e;
  }

  return { data, error };
};

// logout
async function logout(userData) {
  const headers = {
    Accept: "application/json",
    Authorization: "Bearer " + userData?.token,
  };

  let data = null;
  let error = null;

  try {
    const response = await localClient.post(`/v1/logout`, {}, {
      headers,
    });
    data = response.data;
  } catch (e) {
    error = e;
  }

  return { data, error };
};


// Get Airport/Seaport
async function getPort(searchQuery) {
  const { location, portType } = searchQuery;

  let data = null;
  let error = null;

  try {
    const response = await localClient.get(`/v1/search?query=${location}&type=${portType}`);
    data = response.data;
  } catch (e) {
    error = e;
  }

  return { data, error };
}

// Get Configuration
async function getConfig() {

  let data = null;
  let error = null;

  try {
    const response = await localClient.get(`/v1/config`);
    data = response.data;
  } catch (e) {
    error = e;
  }

  return { data, error };
}

// create Inquiry
async function createInquiry(body, userData) {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + userData?.token,
  };

  let data = null;
  let error = null;

  try {
    const response = await localClient.post(`/v1/users/${userData?.id}/inquiries`, body, {
      headers,
    });
    data = response.data;
  } catch (e) {
    error = e;
  }

  return { data, error };
};

// get listings
async function getListings(id, userData) {
  const headers = {
    Accept: "application/json",
    Authorization: "Bearer " + userData?.token,
  };

  let data = null;
  let error = null;

  try {
    const response = await localClient.get(`/v1/inquiries/${id}/listings`, {
      headers,
    });
    data = response.data;
  } catch (e) {
    error = e;
  }

  return { data, error };
};

// get user detials
async function getUser(userData) {
  const headers = {
    Accept: "application/json",
    Authorization: "Bearer " + userData?.token,
  };

  let data = null;
  let error = null;

  try {
    const response = await localClient.get(`/v1/users/${userData?.id}`, {
      headers,
    });
    data = response.data;
  } catch (e) {
    error = e;
  }

  return { data, error };
};

// get blogs
async function getBlogs(page_number) {
  // const headers = {
  //   Accept: "application/json",
  // };

  let data = null;
  let error = null;

  try {
    const response = await localClient.get(`/v1/posts?page=${page_number}`);
    data = response.data;
  } catch (e) {
    error = e;
  }

  return { data, error };
};

// initiate call
async function initiateCall(userData) {
  const headers = {
    Accept: "application/json",
    Authorization: "Bearer " + userData?.token,
  };

  let data = null;
  let error = null;

  try {
    const response = await localClient.get(`/v1/initiate-call`, {
      headers,
    });
    data = response.data;
  } catch (e) {
    error = e;
  }

  return { data, error };
}

// create contact message
async function createContactMessage(body, userData) {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + userData?.token,
  };

  let data = null;
  let error = null;

  try {
    const response = await localClient.post(`/v1/contact`, body, {
      headers,
    });
    data = response.data;
  } catch (e) {
    error = e;
  }

  return { data, error };
};

// get individual blog
async function getIndividualBlog(slug) {

  let data = null;
  let error = null;

  try {
    const response = await localClient.get(`/v1/posts/${slug}`);
    data = response.data;
  } catch (e) {
    error = e;
  }

  return { data, error };
};

export {
  sendOTP,
  verifyOTP,
  logout,
  getPort,
  getConfig,
  createInquiry,
  getListings,
  getUser,
  getBlogs,
  initiateCall,
  createContactMessage,
  getIndividualBlog,
};

import React, { useEffect, useRef, useState } from 'react';
import Transition from '../../../utils/Transition';


const DropdownBasic = ({ options, selected, value, onClick, placeholder, dropdownPosition }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const trigger1 = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (!dropdownOpen || dropdown.current.contains(target) || trigger1.current.contains(target)) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative">
      <div
        className="flex border border-S01 focus-within:border-M01 focus:border-M01 rounded-5xl transition-all duration-500"
      >
        <button
          className="flex justify-between items-center gap-1 px-5 py-3 w-full bg-M04 outline-none border-none rounded-5xl text-left label-xxs text-black"
          onClick={(e) => {
            e.preventDefault();
            setDropdownOpen(!dropdownOpen);
          }}
          ref={trigger1}
        >
          <span>{selected?.name}</span>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7" fill="none">
            <path d="M1 0.5L6 5.5L11 0.5" stroke="#202020" strokeWidth="1.2" />
          </svg>
        </button>
      </div>
      {options?.length !== 0 &&
        <Transition
          show={dropdownOpen}
          tag="div"
          className={`z-[11] absolute ${dropdownPosition === "top" ? "bottom-[calc(100%+2px)]" : "top-full mt-1"} left-0 w-full bg-white border border-S01 rounded-3xl p-1 shadow-lg overflow-x-hidden no-scrollbar overflow-y-scroll max-h-[150px]`}
          enter="transition ease-out duration-100 transform"
          enterStart="opacity-0 -translate-y-2"
          enterEnd="opacity-100 translate-y-0"
          leave="transition ease-out duration-100"
          leaveStart="opacity-100"
          leaveEnd="opacity-0"
        >
          <div
            ref={dropdown}
            onFocus={() => setDropdownOpen(true)}
            onBlur={() => setDropdownOpen(false)}
          >
            {
              options?.map(option => {
                if (option?.is_active === 1) {
                  return (
                    <button
                      key={option?.id}
                      tabIndex="0"
                      className={`flex items-center w-full truncate text-left hover:bg-slate-50 py-2 px-3 rounded-3xl cursor-pointer ${option?.id === selected?.id && 'bg-M08'}`}
                      onClick={(e) => {
                        e.preventDefault();
                        onClick(option);
                        setDropdownOpen(false);
                      }}
                    >
                      <svg className={`mr-2 ${option?.id !== selected?.id && 'invisible'}`} xmlns="http://www.w3.org/2000/svg" width="12" height="9" viewBox="0 0 12 9" fill="none">
                        <path d="M11.3332 1L3.99984 8.33333L0.666504 5" stroke="#334155" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                      <p className={`${option?.id === selected?.id ? 'body-xs font-medium' : 'body-xs'}`}>{option?.name}</p>
                    </button>
                  )
                }
                else return "";
              })
            }
          </div>
        </Transition>}
    </div>
  );
}

export default DropdownBasic;
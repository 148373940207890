import { useCallback, useState } from "react";
import { verifyOTP } from "../api";

const useVerifyOTP = () => {
  const [isLoading, setLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const request = useCallback(async (body) => {
    setLoading(true);
    const { data, error } = await verifyOTP(body);
    setLoading(false);

    return { data, error };
  });

  return { isLoading, request };
};

export default useVerifyOTP;

import React from 'react';
import Flatpickr from 'react-flatpickr';
import { useSelector } from 'react-redux';
import { getSearchQueryData } from '../../../redux/slices/searchQueryDataSlice';
import moment from 'moment';

function Datepicker({
  align,
  onChange,
  searchQueryDataFromURL,
}) {
  const searchQueryData = useSelector(getSearchQueryData);

  const options = {
    mode: 'single',
    static: true,
    monthSelectorType: 'static',
    // dateFormat: 'M j, Y',
    // dateFormat: 'd-m-Y',
    dateFormat: 'Y-m-d',
    defaultDate: searchQueryDataFromURL ? searchQueryDataFromURL?.shipment_date : searchQueryData?.shipment_date,
    minDate: moment(new Date()).format("YYYY-MM-DD"),
    prevArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
    nextArrow: '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    disableMobile: "true",
    onReady: (selectedDates, dateStr, instance) => {
      instance.element.value = dateStr.replace('to', '-');
      const customClass = align ? align : '';
      instance.calendarContainer.classList.add(`flatpickr-${customClass}`);
    },
    onChange: onChange,
  }

  return (
    <div className="relative flex">
      <Flatpickr className="py-3 px-5 w-full cursor-pointer border border-S01 rounded-3xl outline-none label-xxs focus:border-M01" options={options} />
    </div>
  );
}

export default Datepicker;


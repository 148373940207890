import React, { useContext, useEffect, useState } from 'react';
import ShipPurple from "../../../assets/images/ship-purple.svg";
import ShipGrey from "../../../assets/images/ship-grey.svg";
import AirPlaneGrey from "../../../assets/images/airplane-grey.svg";
import AirPlanePurple from "../../../assets/images/airplane-purple.svg";
import Datepicker from '../../../components/reusable-components/calendar/Datepicker';
import LoadDetails from './load-details/LoadDetails';
import ButtonPrimary from '../../../components/reusable-components/buttons/ButtonPrimary';
import { options } from '../../../mockdata/options';
import Origin from './partials/Origin';
import Destination from './partials/Destination';
import useGetConfig from '../../../network/hooks/useGetConfig';
import moment from 'moment/moment';
import { useDispatch, useSelector } from 'react-redux';
import { getSearchQueryData, setSearchQueryData } from '../../../redux/slices/searchQueryDataSlice';
import { getLoadDetailsData, setLoadDetailsData } from '../../../redux/slices/loadDetailsDataSlice';
import { getUserData } from '../../../redux/slices/userDataSlice';
import useCreateInquiry from '../../../network/hooks/useCreateInquiry';
import { setPayloadData } from '../../../redux/slices/payLoadDataSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { ModalContext } from '../../../App';

const handleURLData = (searchQueryData, loadDetailsData) => {
  return {
    searchQueryData,
    loadDetailsData,
  };
}

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const SearchQuery = ({ identifier }) => {
  const { request: getConfig } = useGetConfig();
  const { request: createInquiry } = useCreateInquiry();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(getUserData);
  const searchQueryData = useSelector(getSearchQueryData);
  const loadDetailsData = useSelector(getLoadDetailsData);
  const getModalData = useContext(ModalContext);
  const [configData, setConfigData] = useState();
  const [loadDetailsValid, setLoadDetailsValid] = useState(true);


  // to get the searchQuery data and load details data from URL if provided
  const query = useQuery();
  let URLdata = query.get("data");
  if (URLdata) URLdata = JSON.parse(atob(URLdata));
  let searchQueryDataFromURL = URLdata?.searchQueryData;
  let loadDetailsDataFromURL = URLdata?.loadDetailsData;

  useEffect(() => {
    if (searchQueryDataFromURL && loadDetailsDataFromURL) {
      dispatch(setSearchQueryData(searchQueryDataFromURL));
      dispatch(setLoadDetailsData(loadDetailsDataFromURL));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleGetConfig = async () => {
    const { data, error } = await getConfig();

    if (data && data.success) {
      const containersArray = [];
      data?.data?.containers?.map((item) => (
        containersArray.push({
          id: item?.id,
          name: item?.code,
          is_active: 1,
        })
      ))
      setConfigData({
        ...data?.data,
        containers: containersArray
      });
    };
    if (error) {
      console.log(error);
    }
  };

  const handlePayload = () => {
    const searchQueryDetails = {
      type: searchQueryData?.type,
      origin_id: searchQueryData?.origin?.id,
      origin_type: searchQueryData?.type === "ocean" ? "seaport" : "airport",
      destination_id: searchQueryData?.destination?.id,
      destination_type: searchQueryData?.type === "ocean" ? "seaport" : "airport",
      shipment_date: searchQueryData?.shipment_date,
      container_type: searchQueryData?.container_type,
    }

    let finalPayLoad = {};

    // payload for ocean & lcl
    if (searchQueryData?.type === "ocean" && searchQueryData?.container_type === "lcl") {
      const loadsArray = loadDetailsData?.map((item) => {
        return {
          weight_per_unit: Number(item?.weight_per_unit),
          weight_unit: item?.weight_unit?.name,
          package_type_id: item?.package_type?.id,
          total_units: Number(item?.total_units),
          dimensions: {
            length: Number(item?.dimensions?.length),
            width: Number(item?.dimensions?.width),
            height: Number(item?.dimensions?.height),
            unit: item?.dimensions?.unit?.name,
          }
        }
      })

      finalPayLoad = {
        ...searchQueryDetails,
        load_details: loadsArray,
      }
      dispatch(setPayloadData(finalPayLoad));
      return finalPayLoad;
    }

    // payload for ocean and fcl
    if (searchQueryData?.type === "ocean" && searchQueryData?.container_type === "fcl") {
      finalPayLoad = {
        ...searchQueryDetails,
        load_details: {
          container_code: loadDetailsData?.container?.name,
          total_units: Number(loadDetailsData?.total_units),
        }

      }
      dispatch(setPayloadData(finalPayLoad));
      return finalPayLoad;
    }

    //payload for air
    if (searchQueryData?.type === "air") {
      const loadsArray = loadDetailsData?.map((item) => {
        return {
          weight_per_unit: Number(item?.weight_per_unit),
          weight_unit: item?.weight_unit?.name,
          package_type_id: item?.package_type?.id,
          total_units: Number(item?.total_units),
          dimensions: {
            length: Number(item?.dimensions?.length),
            width: Number(item?.dimensions?.width),
            height: Number(item?.dimensions?.height),
            unit: item?.dimensions?.unit?.name,
          }
        }
      })

      finalPayLoad = {
        ...searchQueryDetails,
        load_details: loadsArray,
      }
      dispatch(setPayloadData(finalPayLoad));
      return finalPayLoad;
    }
  };


  const handleCreateInquiry = () => {
    const creatInquiryAPIFunction = async (userD) => {
      if (userD?.credits < 1) {
        getModalData.setModalType("CREDITSEXHAUSTED");
        getModalData.setModalOpen(true);
        return;
      }

      const payload = handlePayload();

      const { data, error } = await createInquiry(payload, userD);
      if (data && data.success) {
        navigate(`/listings-page/${data?.data?.id}?data=${btoa(JSON.stringify(handleURLData(searchQueryData, loadDetailsData)))}`);
      }
      if (error) {
        window.alert(error?.reponse?.data?.message);
      }
    };

    if (!userData) {
      getModalData.setCallBack(() => {
        return creatInquiryAPIFunction;
      });
      getModalData.setModalType("LOGIN");
      getModalData.setModalOpen(true);
      return;
    }

    creatInquiryAPIFunction(userData);
  };

  const handleOnChangeDatePicker = (selectedDates, dateStr, instance) => {
    dispatch(setSearchQueryData({
      ...searchQueryData,
      shipment_date: moment(dateStr).format("YYYY-MM-DD"),
    }));
  };

  const handleDisableShowPrices = () => {
    // check for origin
    if (searchQueryData?.origin?.id === null || searchQueryData?.origin_search === "") {
      return true;
    }

    // check for destination
    if (searchQueryData?.destination?.id === null || searchQueryData?.destination_search === "") {
      return true;
    }

    // check for Sea-lcl and air
    if ((searchQueryData?.type === "ocean" && searchQueryData?.container_type === "lcl") || searchQueryData?.type === "air") {
      let isError = false;

      loadDetailsData?.forEach((item) => {
        if (item?.dimensions?.length === "") {
          isError = true;
          return isError;
        }
        if (item?.dimensions?.width === "") {
          isError = true;
          return isError;
        }
        if (item?.dimensions?.height === "") {
          isError = true;
          return isError;
        }
        if (item?.weight_per_unit === "") {
          isError = true;
          return isError;
        }
        if (item?.total_units === "") {
          isError = true;
          return isError;
        }
        return "";
      })

      return isError;
    }

    // check for ocean & fcl
    if (searchQueryData?.type === "ocean" && searchQueryData?.container_type === "fcl") {
      if (loadDetailsData?.total_units === "") {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    handleGetConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="search-query-wrapper">
      <div className="container">
        <div className={`${identifier === "from_listings_page" ? "pt-12" : "2xl:min-h-max min-h-[calc(100vh-94px)] pb-28"} flex flex-col justify-center`}>
          {/* heading */}
          <div className={`${identifier === "from_listings_page" ? "hidden" : "block"} display text-center pt-10 2xl:pt-36 mb-10`}>
            Fast Track Your Freight
          </div>

          <div>
            <div className={`${identifier === "from_listings_page" ? "hidden" : "block"} relative z-0 flex items-center bg-[#FAF3FF] rounded-[127px] px-4 py-2 mb-5 w-max`}>
              <div className={`${searchQueryData?.type === "air" && " translate-x-[120px]"} transition-all duration-300 left-[16px] top-[8px] z-10 w-[120px] h-[42px] absolute border border-[#EBEBEB] bg-white shadow-toggle rounded-[127px]`}></div>

              {/* by sea toggle */}
              {/* <div className={`cursor-pointer flex items-center justify-center px-4 py-2 ${route === "SEA" ? "bg-white rounded-[127px]" : ""} transition-all duration-300 ease-in-out`} */}
              <div className={`min-w-[120px] z-20 cursor-pointer flex items-center justify-center px-4 py-2 transition-all duration-300 ease-in-out`}
                onClick={() => {
                  dispatch(setSearchQueryData({
                    ...searchQueryData,
                    type: "ocean",
                    origin_search: "",
                    origin: {
                      id: null,
                    },
                    destination_search: "",
                    destination: {
                      id: null,
                    },
                    container_type: "lcl",
                    counter: 0,
                  }));
                  dispatch(setLoadDetailsData([
                    {
                      id: Math.floor(Math.floor(Math.random() * 9000000000) + 1),
                      package_type: {
                        id: 1,
                        name: "Boxes",
                        is_active: 1,
                      },
                      dimensions: {
                        length: "",
                        width: "",
                        height: "",
                        unit: {
                          id: 1,
                          name: "IN",
                        },
                      },
                      weight_per_unit: "",
                      weight_unit: {
                        id: 1,
                        name: "KG",
                      },
                      total_units: "",
                    }
                  ]));
                  setLoadDetailsValid(true);
                }}
              >
                <img className={`${searchQueryData?.type === "ocean" && "animate-ping-once"}`} src={searchQueryData?.type === "ocean" ? ShipPurple : ShipGrey} alt="Sea" />
                <span className={`label-xs flex items-center pl-2 ${searchQueryData?.type === "ocean" ? "text-M01" : "text-T06"}`}>
                  By Sea
                </span>
              </div>

              {/* by air toggle */}
              {/* <div className={`cursor-pointer flex items-center justify-center px-4 py-2 ${route === "AIR" ? "bg-white rounded-[127px]" : ""} transition-all duration-300 ease-in-out`} */}
              <div className={`min-w-[120px] z-20 cursor-pointer flex items-center justify-center px-4 py-2 transition-all duration-300 ease-in-out`}
                onClick={() => {
                  dispatch(setSearchQueryData({
                    ...searchQueryData,
                    type: "air",
                    origin_search: "",
                    origin: {
                      id: null,
                    },
                    destination_search: "",
                    destination: {
                      id: null,
                    },
                    container_type: "lcl",
                    counter: 0,
                  }));
                  dispatch(setLoadDetailsData([
                    {
                      id: Math.floor(Math.floor(Math.random() * 9000000000) + 1),
                      package_type: {
                        id: 1,
                        name: "Boxes",
                        is_active: 1,
                      },
                      dimensions: {
                        length: "",
                        width: "",
                        height: "",
                        unit: {
                          id: 1,
                          name: "IN",
                        },
                      },
                      weight_per_unit: "",
                      weight_unit: {
                        id: 1,
                        name: "KG",
                      },
                      total_units: "",
                      stackable: false,
                      tiltable: false,
                      overheadLoadable: false,
                    }
                  ]));
                  setLoadDetailsValid(true);
                }}
              >
                <img className={`${searchQueryData?.type === "air" && "animate-ping-once"}`} src={searchQueryData?.type === "air" ? AirPlanePurple : AirPlaneGrey} alt="Air" />
                <span className={`label-xs flex items-center pl-2 ${searchQueryData?.type === "air" ? "text-M01" : "text-T06"}`}>
                  By Air
                </span>
              </div>
            </div>

            <div className="grid lg:grid-cols-5 sm:grid-cols-3 gap-3.5">
              {/* select origin */}
              <Origin />

              {/* select destination */}
              <Destination />

              {/* select date */}
              <div className="">
                <span className="body-xs text-T05">Date</span>
                <Datepicker
                  align="left"
                  onChange={handleOnChangeDatePicker}
                  searchQueryDataFromURL={searchQueryDataFromURL}
                />
              </div>

              {/* select load details */}
              <div className="xl:col-span-2 sm:col-span-2">
                <span className="body-xs text-T05">Load Details</span>
                <LoadDetails
                  options={options}
                  placeholder="Enter Load Details"
                  configData={configData}
                  loadDetailsValid={loadDetailsValid}
                  setLoadDetailsValid={setLoadDetailsValid}
                />
              </div>

              {/* search price */}
              <div className="block lg:hidden ml-auto self-end w-full">
                <ButtonPrimary
                  text="Search Prices"
                  onClick={() => handleCreateInquiry()}
                  additionalClasses={"w-full"}
                  disabled={handleDisableShowPrices()}
                />
              </div>
            </div>

            {/* search price */}
            <div className="hidden lg:block max-w-max ml-auto mt-6">
              <ButtonPrimary
                text="Search Prices"
                onClick={() => handleCreateInquiry()}
                additionalClasses={""}
                disabled={handleDisableShowPrices()}
              />
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default SearchQuery;
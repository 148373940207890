import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loadDetailsData: [
    {
      id: Math.floor(Math.floor(Math.random() * 9000000000) + 1),
      package_type: {
        id: 1,
        name: "Boxes",
        is_active: 1,
      },
      dimensions: {
        length: "",
        width: "",
        height: "",
        unit: {
          id: 1,
          name: "IN",
        },
      },
      weight_per_unit: "",
      weight_unit: {
        id: 1,
        name: "KG",
      },
      total_units: "",
    }
  ],
};

const loadDetailsDataSlice = createSlice({
  name: "loadDetailsData",
  initialState,
  reducers: {
    setLoadDetailsData(state, action) {
      state.loadDetailsData = action.payload;
    },
  },
});

export const { setLoadDetailsData } = loadDetailsDataSlice.actions;
export const getLoadDetailsData = (state) => state.loadDetails.loadDetailsData;

export default loadDetailsDataSlice.reducer;

import React from 'react'
import Modal from '../reusable-components/modal/Modal'

const CreditsExhaustedPopup = ({ open, setOpen, callback }) => {
  return (
    <Modal open={open} onClose={() => setOpen(false)} maxWidth="max-w-[465px]">
      <div className='bg-white rounded-3xl p-10'>
        <div className='mb-6'>
          <svg xmlns="http://www.w3.org/2000/svg" width="34" height="30" viewBox="0 0 34 30" fill="none">
            <path d="M19.1643 1.2467L33.5543 26.17C33.7737 26.5501 33.8892 26.9812 33.8893 27.42C33.8893 27.8589 33.7737 28.29 33.5543 28.67C33.3349 29.0501 33.0193 29.3656 32.6393 29.5851C32.2593 29.8045 31.8282 29.92 31.3893 29.92H2.60931C2.17048 29.92 1.73937 29.8045 1.35933 29.5851C0.979295 29.3656 0.663709 29.0501 0.444296 28.67C0.224882 28.29 0.109372 27.8589 0.109375 27.42C0.109378 26.9812 0.224893 26.5501 0.444311 26.17L14.8343 1.2467C15.796 -0.419963 18.201 -0.419963 19.1643 1.2467ZM16.9993 4.16337L4.05264 26.5867H29.946L16.9993 4.16337ZM16.9993 21C17.4413 21 17.8653 21.1756 18.1778 21.4882C18.4904 21.8008 18.666 22.2247 18.666 22.6667C18.666 23.1087 18.4904 23.5327 18.1778 23.8452C17.8653 24.1578 17.4413 24.3334 16.9993 24.3334C16.5573 24.3334 16.1334 24.1578 15.8208 23.8452C15.5082 23.5327 15.3326 23.1087 15.3326 22.6667C15.3326 22.2247 15.5082 21.8008 15.8208 21.4882C16.1334 21.1756 16.5573 21 16.9993 21ZM16.9993 9.33337C17.4413 9.33337 17.8653 9.50897 18.1778 9.82153C18.4904 10.1341 18.666 10.558 18.666 11V17.6667C18.666 18.1087 18.4904 18.5327 18.1778 18.8452C17.8653 19.1578 17.4413 19.3334 16.9993 19.3334C16.5573 19.3334 16.1334 19.1578 15.8208 18.8452C15.5082 18.5327 15.3326 18.1087 15.3326 17.6667V11C15.3326 10.558 15.5082 10.1341 15.8208 9.82153C16.1334 9.50897 16.5573 9.33337 16.9993 9.33337Z" fill="#E03C31" />
          </svg>
        </div>
        <h5 className='label-sm mb-2'>Attention: Credit Balance Exhausted</h5>
        <p className='body-xs'>Uh-oh! It appears your credit balance is depleted. No worries! To continue benefiting from our exceptional logistics services, please reach out to our dedicated support team. We're here to assist you. Contact us now to recharge your account.</p>
      </div>
    </Modal>
  )
}

export default CreditsExhaustedPopup
import React from 'react';

const RadioGroup = ({ label1, value1, label2, value2, checked1, checked2, onChange1, onChange2 }) => {
  return (
    <div className="flex items-center justify-center gap-3">
      <div className="flex items-center cursor-pointer">
        <input
          id="default-radio-1"
          type="radio"
          value={`${value1}`}
          name="default-radio"
          checked={checked1}
          onChange={() => onChange1()}
          className="w-4 h-4 cursor-pointer checked:accent-M01"
        />
        <label htmlFor="default-radio-1" className="ml-1 font-onest body-xs text-T07 cursor-pointer">{label1}</label>
      </div>
      <div className="flex items-center cursor-pointer">
        <input
          id="default-radio-2"
          type="radio"
          value={`${value2}`}
          name="default-radio"
          checked={checked2}
          onChange={() => onChange2()}
          className="w-4 h-4 cursor-pointer checked:accent-M01"
        />
        <label htmlFor="default-radio-2" className="ml-1 font-onest body-xs text-T07 cursor-pointer">{label2}</label>
      </div>
    </div>
  )
}

export default RadioGroup
import React, { useEffect, useRef, useState } from 'react'

const OTPField = ({ otp, setOTP, disabled }) => {
  const first = useRef();
  const second = useRef();
  const third = useRef();
  const fourth = useRef();

  const [firstValue, setFirstValue] = useState("");
  const [secondValue, setSecondValue] = useState("");
  const [thirdValue, setThirdValue] = useState("");
  const [fourthValue, setFourthValue] = useState("");

  useEffect(() => {
    setOTP(`${firstValue}${secondValue}${thirdValue}${fourthValue}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstValue, secondValue, thirdValue, fourthValue]);

  useEffect(() => {
    const target = otp?.split('');

    setFirstValue((target && target[0]) || '');
    setSecondValue((target && target[1]) || '');
    setThirdValue((target && target[2]) || '');
    setFourthValue((target && target[3]) || '');
  }, [otp])

  const handleOnchangeFirstValue = (e) => {
    const reg = new RegExp("^[0-9]+$");
    if (!e.target.value || reg.test(e.target.value)) {
      setFirstValue(e.target.value);
      if (e.target.value !== "") {
        second.current.focus();
      };
    }
  };

  const handleOnchangeSecondValue = (e) => {
    const reg = new RegExp("^[0-9]+$");
    if (!e.target.value || reg.test(e.target.value)) {
      setSecondValue(e.target.value);
      if (e.target.value !== "") {
        third.current.focus();
      };
    }
  };

  const handleOnchangeThirdValue = (e) => {
    const reg = new RegExp("^[0-9]+$");
    if (!e.target.value || reg.test(e.target.value)) {
      setThirdValue(e.target.value);
      if (e.target.value !== "") {
        fourth.current.focus();
      }
    }
  };

  const handleOnchangeFourthValue = (e) => {
    const reg = new RegExp("^[0-9]+$");
    if (!e.target.value || reg.test(e.target.value)) {
      setFourthValue(e.target.value);
    }
  };

  const handleKeyDownSecondInput = (e) => {
    if (e.code === "Backspace" && secondValue === "") {
      first.current.focus();
    }
  };

  const handleKeyDownThirdInput = (e) => {
    if (e.code === "Backspace" && thirdValue === "") {
      second.current.focus();
    }
  };

  const handleKeyDownFourthInput = (e) => {
    if (e.code === "Backspace" && fourthValue === "") {
      third.current.focus();
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center justify-between mx-auto w-full">
        <div className="w-[58px] h-[60px]">
          <input
            disabled={disabled}
            ref={first}
            value={firstValue}
            onChange={handleOnchangeFirstValue}
            className={`${disabled && "cursor-not-allowed"} w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-[20px] border border-S01 focus:border-M01 transition-all duration-300 label-sm bg-white`} maxLength={1} type="text" name="" id="" />
        </div>

        <div className="w-[58px] h-[60px]">
          <input
            disabled={disabled}
            ref={second}
            value={secondValue}
            onChange={handleOnchangeSecondValue}
            onKeyDown={handleKeyDownSecondInput}
            className={`${disabled && "cursor-not-allowed"} w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-[20px] border border-S01 focus:border-M01 transition-all duration-300 label-sm bg-white`} maxLength={1} type="text" name="" id="" />
        </div>

        <div className="w-[58px] h-[60px]">
          <input
            disabled={disabled}
            ref={third}
            value={thirdValue}
            onChange={handleOnchangeThirdValue}
            onKeyDown={handleKeyDownThirdInput}
            className={`${disabled && "cursor-not-allowed"} w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-[20px] border border-S01 focus:border-M01 transition-all duration-300 label-sm bg-white`} maxLength={1} type="text" name="" id="" />
        </div>

        <div className="w-[58px] h-[60px]">
          <input
            disabled={disabled}
            ref={fourth}
            value={fourthValue}
            onChange={handleOnchangeFourthValue}
            onKeyDown={handleKeyDownFourthInput}
            className={`${disabled && "cursor-not-allowed"} w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-[20px] border border-S01 focus:border-M01 transition-all duration-300 label-sm bg-white`} maxLength={1} type="text" name="" id="" />
        </div>
      </div>
    </div>
  )
}

export default OTPField;
import { useCallback, useState } from "react";
import { sendOTP } from "../api";

const useSendOTP = () => {
  const [isLoading, setLoading] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const request = useCallback(async (body) => {
    setLoading(true);
    const { data, error } = await sendOTP(body);
    setLoading(false);

    return { data, error };
  });

  return { isLoading, request };
};

export default useSendOTP;

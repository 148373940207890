import React, { createContext, useContext, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ListingPage from './pages/ListingPage';
import DetailsPage from './pages/DetailsPage';
import ComponentsPage from './pages/ComponentsPage';
import Header from './components/common/Header';
import SideBar from './components/common/SideBar';
import AboutUs from './pages/AboutUs';
import BlogsPage from './pages/BlogsPage';
import Footer from './components/common/Footer';
import FAQs from './pages/FAQs';
import ContactUs from './pages/ContactUs';
import OurPartners from './pages/OurPartners';
import LoginPopup from './components/popups/LoginPopup';
import BookNowPopup from './components/popups/BookNowPopup';
import CallSuccessPopup from './components/popups/CallSuccessPopup';
import WhatsAppNowPopup from './components/popups/WhatsAppNowPopup';
import RegisterPopup from './components/popups/RegisterPopup';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndCondition';
import ScrollToHashElement from './utils/scrollToHashElements';
import CreditsExhaustedPopup from './components/popups/CreditsExhaustedPopup';
import PartnerWithUs from './pages/PartnerWithUs';
import BlogContentPage from './partials/blogs-page/BlogContentPage';
import TagManager from 'react-gtm-module';

export const SidebarModalContext = createContext();
export const ModalContext = createContext();

const ModalWrapper = ({ children }) => {
  const getModalData = useContext(ModalContext);

  return (
    <>
      <>{children}</>
      <div>
        {getModalData?.modalType === "LOGIN" ?
          <LoginPopup
            open={getModalData?.modalOpen}
            setOpen={getModalData?.setModalOpen}
            callback={getModalData?.callback}
          />
          : getModalData?.modalType === "REGISTER" ?
            <RegisterPopup
              open={getModalData?.modalOpen}
              setOpen={getModalData?.setModalOpen}
              callback={getModalData?.callback}
            />
            : getModalData?.modalType === "BOOKNOW" ?
              <BookNowPopup
                open={getModalData?.modalOpen}
                setOpen={getModalData?.setModalOpen}
                callback={getModalData?.callback}
              />
              : getModalData?.modalType === "CALLSUCCESS" ?
                <CallSuccessPopup
                  open={getModalData?.modalOpen}
                  setOpen={getModalData?.setModalOpen}
                  callback={getModalData?.callback}
                />
                : getModalData?.modalType === "WHATSAPPNOW" ?
                  <WhatsAppNowPopup
                    open={getModalData?.modalOpen}
                    setOpen={getModalData?.setModalOpen}
                    callback={getModalData?.callback}
                  />
                  : getModalData?.modalType === "CREDITSEXHAUSTED" ?
                    <CreditsExhaustedPopup
                      open={getModalData?.modalOpen}
                      setOpen={getModalData?.setModalOpen}
                      callback={getModalData?.callback}
                    />
                    : <></>
        }
      </div>
    </>
  );
};

function App() {
  const [showExpand, setShowExpand] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [callback, setCallBack] = useState();

  // to disable scroll when sidebar is open
  (showExpand || modalOpen)
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "auto");

  const location = useLocation();

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [location.pathname]); // triggered on route change

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'G-9ECXS7K4SM',
      dataLayer: {
        event: 'pageview',
        // You can add more data layer variables here if needed but leave it for now. I will tell you later.
      },
    };

    TagManager.initialize(tagManagerArgs);
  }, [])

  return (
    <ModalContext.Provider
      value={{
        modalOpen,
        setModalOpen,
        modalType,
        setModalType,
        callback,
        setCallBack,
      }}
    >
      <SidebarModalContext.Provider value={{ showExpand, setShowExpand }}>
        <ModalWrapper>
          <Header />
          <SideBar />
          <ScrollToHashElement />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/listings-page/:id" element={<ListingPage />} />
            <Route path="/details-page" element={<DetailsPage />} />
            <Route path="/blogs" element={<BlogsPage />} />
            <Route path="/blogs/:slug" element={<BlogContentPage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/FAQs" element={<FAQs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/our-partners" element={<OurPartners />} />
            <Route path="/terms-condition" element={<TermsAndConditions />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/partner-with-us" element={<PartnerWithUs />} />
            <Route path="/components" element={<ComponentsPage />} />
          </Routes>
          <Footer />
        </ModalWrapper>
      </SidebarModalContext.Provider>
    </ModalContext.Provider>
  );
}

export default App;

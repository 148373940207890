import React from 'react';
import MoveArrow from "../../../assets/images/Vector (12).svg";
import { useState } from 'react';
import FAQAccordion from '../../../components/reusable-components/accordion/Accordion';
import { Link, useNavigate } from 'react-router-dom';

const FAQSection = () => {
  const navigate = useNavigate();

  const [accordions, setAccordion] = useState([
    {
      key: 1,
      title: 'What are the benefits of using CargoXB?',
      data: `CargoXB streamlines your freight forwarding process, giving you instant prices for freight transportation directly from more than 40 shipping lines and a long list of freight forwarders. Our platform ensures complete pricing transparency, including clear insights into vessel costs.`,
      isOpen: false,
    },
    {
      key: 2,
      title: 'What is CargoXB’s fair price guarantee?',
      data: `Our partner freight forwarders provide you with the best quotes for your consignments allowing you to pick and choose the best service basis your requirements. You can use our platform to chat privately with your chosen freight forwarders and negotiate better terms.`,
      isOpen: false,
    },
    {
      key: 3,
      title: 'What are the core features of CargoXB?',
      data: `CargoXB has integrated APIs with 18+ sea freight carriers and 40+ airlines to provide you with instant prices for freight transportation, ensuring fair value with no incremental rates from shipping companies. CargoXB digitizes the manual process of obtaining price quotes from sea liners and airlines, helping streamline the logistics processes for exporters.`,
      isOpen: false,
    },
  ]);

  const toggleAccordion = (accordionkey) => {
    const updatedAccordions = accordions.map((item) => {
      if (item.key === accordionkey) {
        return { ...item, isOpen: !item?.isOpen };
      } else {
        return { ...item, isOpen: false };
      }
    });

    setAccordion(updatedAccordions);
  };

  return (
    <div className='container'>
      <div className='py-12 sm:py-24 xl:py-28 flex flex-col lg:flex-row justify-between lg:gap-20 gap-14'>
        <div className='grow max-w-[640px] w-full'>
          <div className="border-y border-slate-200 divide-y divide-slate-200">
            {accordions?.map((accordion) => (
              <FAQAccordion
                key={accordion.key}
                title={accordion.title}
                data={accordion.data}
                isOpen={accordion.isOpen}
                toggleAccordion={() => toggleAccordion(accordion.key)}
              />
            ))}
          </div>
        </div>
        <div className='max-w-[464px] w-full'>
          <h2 className='heading-h2 text-T01'>
            How We Can Help You?
          </h2>
          <p className='body-lg font-normal text-T06 pt-4'>Seamless and transparent shipping journey with custom solutions to elevate your logistics experience.</p>
          <div className='pt-8 flex  items-center gap-x-2'>
            <input placeholder='Enter your Email' className='label-xs font-normal bg-M03 w-3/4 py-3 sm:py-5 px-4 outline-none rounded-3xl' />
            <div>
              <button
                className='bg-M01 label-xs py-3 sm:py-5 px-9 rounded-full text-M04 w-max'
                onClick={() => {
                  navigate("/partner-with-us")
                }}
              >
                Lets Talk
              </button>
            </div>
          </div>
          <div className='flex mt-10'>
            <div> <Link to={"/FAQs"} className='label-sm text-M01 pr-1'>More FAQ</Link></div>
            <img alt="" src={MoveArrow} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FAQSection;
import React from 'react';

const AccordionSmall = ({ toggleAccordion, title, isOpen, data }) => {
  return (
    <div>
      {/* Question */}
      <div className="flex justify-between items-start cursor-pointer gap-5" onClick={toggleAccordion}>
        <div className='body-sm'>{title}</div>
        <svg className="shrink-0 ml-4 w-3 md:w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill='#7D11C8'>
          <rect y="7" rx="1" className={`w-3 md:w-4 h-0.5 transform origin-center transition duration-200 ease-out ${isOpen && '!rotate-180'}`} />
          <rect y="7" rx="1" className={`w-3 md:w-4 h-0.5 transform origin-center rotate-90 transition duration-200 ease-out ${isOpen && '!rotate-180'}`} />
        </svg>
      </div>

      {/* answer */}
      <div className={`grid overflow-hidden transition-all duration-300 ease-in-out ${isOpen ? 'grid-rows-[1fr] opacity-100' : 'grid-rows-[0fr] opacity-0'}`}>
        <div className='overflow-hidden'>
          <div className={`body-sm text-T06`}>
            {data}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccordionSmall;
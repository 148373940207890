import React from 'react'
import Email from "../../assets/images/Email.svg";
import LinkedIn from "../../assets/images/LinkedIn.svg";
import CargoLogo from "../../assets/images/Logo (2).svg";
import { Link } from 'react-router-dom';


const Footer = () => {

	return (
		<div className='px-5 sm:px-6 lg:px-8 w-full border-t border-S02'>
			<div className='flex flex-col lg:flex-row gap-10 sm:gap-20 lg:gap-10 xl:gap-20 py-12 md:py-20'>
				{/* logo and social links */}
				<div className='max-w-[310px] lg:max-w-[210px] w-full'>
					<Link to={"/"}><img src={CargoLogo} alt="" /></Link>
					<p className='body-md text-S05 pt-6'>Elevating your logistics journey with a seamless and transparent shipping process.</p>
					<div className='pt-6 flex gap-x-6'>
						{/* <img src={Facebook} alt="" /> */}
						{/* <img src={Twitter} alt="" /> */}
						<a href="https://www.linkedin.com/company/cargoxb/" target='blank'><img src={LinkedIn} alt="" /></a>
					</div>
				</div>

				{/* Navlinks */}
				<div className=' w-full flex justify-between'>
					<ul className='pr-2'>
						<li className='text-[18px] font-bold text-M09 mb-6 sm:mb-10'>Product</li>
						<li className='body-md font-normal text-S05 mb-5 hover:text-M01 hover:translate-x-1 transition-all duration-200'><Link to={"/#key-features"}>Features</Link></li>
						<li className='body-md font-normal text-S05 mb-5 hover:text-M01 hover:translate-x-1 transition-all duration-200'><Link to={"/terms-condition"}>Terms</Link></li>
						<li className='body-md font-normal text-S05 mb-5 hover:text-M01 hover:translate-x-1 transition-all duration-200'><Link to={"/#reviews"}>Reviews</Link></li>
					</ul>

					<ul className='pr-2'>
						<li className='text-[18px] font-bold text-M09 mb-6 sm:mb-10'>Company</li>
						<li className='body-md font-normal text-S05 mb-5 hover:text-M01 hover:translate-x-1 transition-all duration-200'><Link to={"/about-us"}>About</Link></li>
						<li className='body-md font-normal text-S05 mb-5 hover:text-M01 hover:translate-x-1 transition-all duration-200'><Link to={"/privacy-policy"}>Privacy</Link></li>
						<li className='body-md font-normal text-S05 mb-5 hover:text-M01 hover:translate-x-1 transition-all duration-200'><Link to={"/#how-it-works"}>How it Works</Link></li>
						<li className='body-md font-normal text-S05 mb-5 hover:text-M01 hover:translate-x-1 transition-all duration-200'><Link to={"/our-partners"}>Our Partners</Link></li>
						<li className='body-md font-normal text-S05 hover:text-M01 hover:translate-x-1 transition-all duration-200'><Link to={"/blogs"}>Blog</Link></li>
					</ul>

					<ul className='pr-2'>
						<li className='text-[18px] font-bold text-M09 mb-6 sm:mb-10'>Support</li>
						<li className='body-md font-normal text-S05 mb-5 hover:text-M01 hover:translate-x-1 transition-all duration-200'><Link to={"/contact-us"}>Contact Us</Link></li>
						<li className='body-md font-normal text-S05 mb-5 hover:text-M01 hover:translate-x-1 transition-all duration-200'><Link to={"/FAQs"}>FAQs</Link></li>
					</ul>

					<div className='hidden sm:block'>
						<div className='text-[18px] font-bold text-M09 mb-6 sm:mb-10'>Contacts us</div>
						<a href='mailTo:Info@cargoxb.com' className='flex body-md font-normal text-S05 hover:text-M01 mb-5'><img alt="" src={Email} className='mr-1' />Info@cargoxb.com</a>
					</div>
				</div>
				<div className='sm:hidden block'>
					<div className='text-[18px] font-bold text-M09 mb- sm:mb-10'>Contacts us</div>
					<a href='mailTo:Info@cargoxb.com' className='flex body-md font-normal text-S05 hover:text-M01 mb-5'><img alt="" src={Email} className='mr-1' />Info@cargoxb.com</a>
					{/* <a href='tel:+91 4146875892' className='flex body-md font-normal text-S05 mb-5'><img alt="" src={Phone} className='mr-1' />(414) 687 - 5892</a> */}
					{/* <div className='flex body-md font-normal text-S05 mb-5 items-start'><img alt="" src={Location} className='mr-1' />Sector 11, something Delhi, India</div> */}
				</div>
			</div>

			{/* Copyright Text */}
			<div className='flex flex-col sm:flex-row justify-between items-center gap-5 py-8 border-t'>
				<div className='body-xs text-S05 text-center sm:text-left'>Copyright © 2023 Cargoxb.com</div>
				<div>
					<div className='body-xs text-S05 text-center sm:text-right'>All Rights Reserved | <Link to={"terms-condition"} className='text-M01'>Terms and Conditions</Link> | <Link to={"privacy-policy"} className='text-M01'>Privacy Policy</Link></div>
				</div>
			</div>
		</div>
	)
}

export default Footer;
import { useCallback, useContext, useState } from "react";
import { getUser } from "../api";
import { useDispatch } from "react-redux";
import { clearUserDetails } from "../../redux/slices/userDataSlice";
import { ModalContext } from "../../App";

const useGetUser = () => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const getModalData = useContext(ModalContext);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const request = useCallback(async (userData) => {
    setLoading(true);
    const { data, error } = await getUser(userData);
    setLoading(false);

    if (error && error?.response?.status === 401) {
      dispatch(clearUserDetails());
      getModalData.setModalType("LOGIN");
      getModalData.setModalOpen(true);
    }

    return { data, error };
  });

  return { isLoading, request };
};

export default useGetUser;

import React from 'react'

const ButtonPrimary = ({
  text,
  size = "normal",
  onClick,
  disabled,
  additionalClasses,
  type,
  loading,
}) => {
  return (
    <button
      className={`${size === "small" && "px-8 py-2"} ${size === "normal" && "px-9.5 py-3.5"} ${size === "medium" && "px-9.5 py-5"} ${additionalClasses && additionalClasses} ${disabled && "opacity-40 cursor-not-allowed hover:bg-M01"} ${!disabled && "hover:bg-M01_dark"} rounded-4xl bg-M01 transition-all duration-300 label-xs text-M04 flex justify-center items-center gap-2`}
      onClick={(e) => { e.preventDefault(); e.stopPropagation(); onClick() }}
      disabled={disabled}
      type={type}
    >
      {loading &&
        <div>
          <svg className="animate-spin -ml-1 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        </div>
      }
      <div>
        {text}
      </div>
    </button>
  )
}

export default ButtonPrimary;
import React from 'react';
import HowItWorks01 from "../../../assets/images/how it works 1.png"
import HowItWorks02 from "../../../assets/images/how it works 2.png"
import HowItWorks03 from "../../../assets/images/how it works 3.png"
import HowItWorks04 from "../../../assets/images/how it works 4.png"
import PointerCard from "./partials/PointerCard";

const pointerCardsList = [
  {
    title: "Enter Shipment Details",
    content: "Enter mode of transport, origin, destination, date of shipment and load details such as LCL,FCL.",
    image: HowItWorks01,
  },
  {
    title: "Login Using Mobile Number",
    content: "After entering all shipment details, verify your mobile number using OTP.",
    image: HowItWorks02,
  },
  {
    title: "Get Instant Spot Rates",
    content: "Get best spot rates from top freight forwarders and shipping company.",
    image: HowItWorks03,
  },
  {
    title: "Book Your shipment",
    content: "Book shipment with freight forwarder offering the best rate directly.",
    image: HowItWorks04,
  },
];

const HowItWorks = () => {
  return (
    <div id='how-it-works' className=' bg-how-it-works bg-cover bg-center bg-no-repeat'>
      <div className='container'>
        <div className="py-14 md:py-24 xl:py-28 bg-Map bg-cover bg-no-repeat bg-top">
          {/* heading */}
          <div className='flex items-center justify-center'>
            <div className='flex items-center flex-col'>
              <h2 className='heading-h1 text-center'>Streamlining logistics experience</h2>
              <p className='w-3/4 md:w-full body-md text-center text-T06 max-w-4xl mt-4'>Our seamless cargo booking process, from placing your order to the final delivery, is designed for efficiency and transparency.</p>
            </div>
          </div>

          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4  pt-24 gap-9'>
            {pointerCardsList?.map((item, index) => {
              return (
                <PointerCard
                  key={Math.random()}
                  step={`0${index + 1}`}
                  image={item?.image}
                  title={item?.title}
                  content={item?.content}
                />
              )
            })}
          </div>
        </div>
      </div >
    </div >
  )
}

export default HowItWorks;
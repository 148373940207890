import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userData: null,
};

const userDataSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {
    saveUserDetails: (state, action) => {
      state.userData = action.payload; //TODO
    },
    clearUserDetails: (state, action) => {
      state.userData = null;
    },
    updateUserCredits: (state, action) => {
      state.userData = {
        ...state.userData,
        credits: action.payload,
      }
    },
  },
});

export const { saveUserDetails, clearUserDetails, updateUserCredits } = userDataSlice.actions;
export const getUserData = (state) => state.user.userData;

export default userDataSlice.reducer;
